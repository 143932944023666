import React, { useEffect, useState } from 'react'
import './HeaderBar.css';
import DescriptionIcon from '@material-ui/icons/Description';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddIcon from '@material-ui/icons/Add';
import ViewHeadlineRoundedIcon from '@material-ui/icons/ViewHeadlineRounded'
import ADMCreationModal from './ADMCreationModal/ADMCreationModal';
import ADMGrantModal from './ADMGrantModal/ADMGrantModal';
import ADMStepsDropdown from './ADMStepsDropdown/ADMStepsDropdown';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from 'react-bootstrap';
import { Refresh } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub';
import CloseIcon from '@material-ui/icons/Close';
import { CTooltip, getNotyfObject } from '../../../shared/utility';
import DocDuplicateModal from '../../Template/TableBody/DocDuplicateModal/DocDuplicateModal';

function HeaderBar({headerBarSearchInput, setHeaderBarSearchInput, setActiveHeaderBarSearchInput, activeHeaderBarSearchInput, setStepsInitialStatus, admStepsDefaults, stepFilter, refresh_adms_list, role, handleSearch, handleAgencyFilterChange, handleSubAgencyFilterChange, handleYearFilterChange, handleAdvertiserFilterChange, handleDepartmentFilterChange, agencyFilter,
                     subAgencyFilter, advertiserFilter, yearFilter, departmentFilter, admSteps, setAdmSteps }) {
    const {t} = useTranslation();
    const history = useHistory();
    const notyf = getNotyfObject()
    const [showADMModal, setShowAdmModal] = useState(false)
    const [showADMTemplateModal, setShowADMTemplateModal] = useState(false)
    const [advertiserType, setAdvertiserType] = useState("advertiser")
    const [selectedAdvertiser, setSelectedAdvertiser] = useState() 
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null)
    const [advertisers, setAdvertisers] = useState()
    const currentDate = new Date();
    const defaultStartDate = new Date((currentDate.getMonth()>=8 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()), 0, 1);
    const defaultEndDate = new Date(defaultStartDate.getFullYear(), 12, 0);
    const [selectedStartDateDuplicate, setSelectedStartDateDuplicate] = useState(defaultStartDate)
    const [selectedEndDateDuplicate, setSelectedEndDateDuplicate] = useState(defaultEndDate)   
    const [selectedTemplate, setSelectedTemplate] = useState(null)   
    const [showGrantAdmModal, setShowGrantAdmModal] = useState(false)
    const [showADMSteps, setShowAdmSteps] = useState(false)
    const [filterOptions, setFilterOptions] = useState([])

    useState(()=> {
        if("RG, RC".includes(role)) {
            let params = {filterAgency: agencyFilter,filterAdvertiser: advertiserFilter, filterYear: yearFilter};
            Axios.get(`/entity//filterInfoForPublishers`, {'params' : params }).then(response => {
                setFilterOptions(response.data);
            })
        }
    }, [])

    useEffect(() => {        
        let steps = stepFilter ? stepFilter.map(f => f.stepKey).join(",") : "";
        if(!"AG, AC".includes(role)){
            let params = {filterAgency: agencyFilter, filterSubAgency: subAgencyFilter, filterAdvertiser: advertiserFilter, filterYear: yearFilter, filterDepartment: departmentFilter,steps};
                Axios.get(`/entity//filterInfoForPublishers`, {'params' : params }).then(response => {
                setFilterOptions(response.data);
            })
        }
       
    }, [agencyFilter, subAgencyFilter, advertiserFilter, yearFilter,stepFilter])

    // checkIfSelectedStep(){

    // }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') { 
            // effectuer la recherche  
            handleSearch(headerBarSearchInput) 
            setActiveHeaderBarSearchInput(true) 
        }
        // console.log("lkjuhgikujhgipu")
      };

    useEffect(() => {
        let filter = {
            category: "annonceur",
            status: "ACTIVE",
            searchQuery: searchQuery
        }
        Axios.get('/entity', {
            params: filter
        }).then(res => {
            setAdvertisers(res.data.entities)
        }).catch(err => notyf.error(t("mandat:generatedErrorMessage")))           
    }, [searchQuery])

    const createFromTemplate = () => {
        if(selectedGroup){
            Axios.post('/document/'+selectedTemplate+'/duplicate', {
                advertiserGroupRef: selectedGroup, 
                startPeriod: selectedStartDateDuplicate.getTime(),
                endPeriod: selectedEndDateDuplicate.getTime()
            }).then(response => {
                if(response.data.Success){
                    notyf.success(response.data.Success)  
                    history.push('/mandat/' + response.data?.uidDocument)
                }
                else 
                    notyf.error(response.data.Failed)
            })
        }else{
            Axios.post('/document/'+selectedTemplate+'/duplicate', {
                advertiserRef: selectedAdvertiser,
                startPeriod: selectedStartDateDuplicate.getTime(),
                endPeriod: selectedEndDateDuplicate.getTime()
            }).then(response => {
                if(response.data.Success){
                    notyf.success(response.data.Success)  
                    history.push('/mandat/' + response.data?.uidDocument)
                }
                else 
                    notyf.error(response.data.Failed)
            })
        }
        setShowADMTemplateModal(false)
    }

    const handleShowADMTemplateModalTrue = () => { 
        setSelectedAdvertiser(null)
        setSelectedGroup(null)
        setSelectedTemplate(null)
        setAdvertiserType("advertiser")
        setShowADMTemplateModal(true)
    }
    
    return (
        <div className="headerBar__container">
            <div className="headerBarHome__left">
                <div className="left__icon">
                    <DescriptionIcon className="headerBar__icon" style={{width: "40px", height: "95%"}} />
                </div>
                
                <div className="left__elements">
                    <h1 className="leftElement_title">{t('default:adm')}</h1>
                    <div className="leftElement_actions">
                        {/* <ArrowDropDownIcon 
                        className="leftAction__icon" 
                        onClick={() => setShowAdmSteps(!showADMSteps)}
                        style={{fontSize: "2rem",size:"large",color : showADMSteps ? "blue" : "#706E6B"}}
                         /> */}
                         <i className="fas fa-caret-down " 
                            onClick={() => setShowAdmSteps(!showADMSteps)} 
                            style={{ cursor:"pointer",fontSize: "xx-large", color : showADMSteps ? "rgb(83, 152, 211)" : "#706E6B"}}/>

                            
                       <h2 className="filterText__title">{t('default:filtre', 'Filtre')} </h2>
                       <div className="headerBar__search">
                            <div className="headerBarSearch__container">
                                <input 
                                    type="text" 
                                    style={{paddingLeft:"10px"}}
                                    className="headerBarSearch__input" 
                                    placeholder={t('home:searchPlaceholder', "Chercher un mandat" )} 
                                    onChange={e => setHeaderBarSearchInput(e.target.value)} 
                                    value={headerBarSearchInput}
                                    onKeyDown={handleKeyPress} 
                                />
                                {
                                    activeHeaderBarSearchInput && 
                                        <CloseIcon style={{cursor: 'pointer'}} onClick={e => {setActiveHeaderBarSearchInput(false);setHeaderBarSearchInput("")}}/>
                                }
                                <SearchIcon 
                                    style={{cursor: 'pointer'}} 
                                    className="headerBarSearch__icon" 
                                    onClick={e => {handleSearch(headerBarSearchInput); setActiveHeaderBarSearchInput(true)}} 
                                       
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{position: "relative"}}>
                                <ADMStepsDropdown
                                    admStepsDefaults={admStepsDefaults}
                                    adm_steps={admSteps}
                                    setStepsInitialStatus={setStepsInitialStatus}
                                    set_admSteps={setAdmSteps}
                                    zIndex="200"
                                    showSteps={showADMSteps}
                                    setShowSteps={setShowAdmSteps}
                                    refresh_admsList={refresh_adms_list}
                                /> 
                    </div>

                    <div style={{position: "relative"}}>
                        {
                            "RG, RC".includes(role) ? (
                                <Button className="btn-sm grant_mandat" onClick={() => setShowGrantAdmModal(true)}>
                                    <AddIcon style={{color: "#1589EE", marginBottom: "2px"}} />
                                    &nbsp;&nbsp; {t('home:grantAdmBtn', "Demander l'accès à une ADM")} 
                                </Button>
                            ) : ""
                        }
                    </div>  
                    

                </div>

                {
                "RG, RC, MG, MR, MC, AE".includes(role) ? (
                    <div className="headerBarFilter">
                        <CTooltip title={filterOptions?.agencies?.find(a => a.uid == agencyFilter)?.representative ? "Référent MyMandat : " + filterOptions?.agencies?.find(a => a.uid == agencyFilter)?.representative : ""}>
                            <select 
                                value={(agencyFilter!= null)? agencyFilter : ""}
                                onChange={e => handleAgencyFilterChange(e.target.value)}
                                className="headerBarFilter__select headerBarFilter__selectEntity" name="selectAgence">
                                <option key='all' value="">{t('home:allAgencies')}</option>
                                {
                                    filterOptions?.agencies?.sort((a, b) => a.name.localeCompare(b.name)).map((a, i)  => (
                                    <option key={i} value={a.uid}>{a.name}</option>
                                    ))
                                }
                            </select>
                        </CTooltip> 
                        {  ("MG, MR, MC, AE".includes(role) && filterOptions?.departments?.length >= 1) &&
                            <select 
                                value={(departmentFilter!= null)? departmentFilter : ""}
                                onChange={e => handleDepartmentFilterChange(e.target.value)}
                                className="headerBarFilter__select headerBarFilter__selectEntity" name="selectDepartment">
                                <option key='all' value="">{t('home:allDepartments')}</option>   
                                <option key='forcenone' value="forcenone">{t('home:noDepartment')}</option>
                                {
                                    filterOptions?.departments?.sort((a, b) => a.label.localeCompare(b.label)).map((a, i)  => (
                                        <option key={i} value={a.uid}>{a.label}</option>
                                        ))
                                    }
                            </select> 
                        }
                        {  ("MG, MR, MC, AE".includes(role)) &&
                            <select 
                                value={(subAgencyFilter!= null)? subAgencyFilter : ""}
                                onChange={e => handleSubAgencyFilterChange(e.target.value)}
                                className="headerBarFilter__select headerBarFilter__selectEntity" name="selectSubAgence">
                                <option key='all' value="">{t('home:allSubAgencies')}</option>
                                {
                                    filterOptions?.subAgencies?.sort((a, b) => a.name.localeCompare(b.name)).map((a, i)  => (
                                    <option key={i} value={a.uid}>{a.name}</option>
                                    ))
                                }
                            </select> 
                        }
                        <select 
                            value={(yearFilter!= null)? yearFilter : ""}
                            onChange={e => handleYearFilterChange(e.target.value)}
                            className="headerBarFilter__select" name="selectYear">
                            <option key='all'  value="">{t('home:allYears')}</option>
                            {
                                filterOptions?.years?.map((a, i) => (
                                <option  key={i} value={a}>{a}</option>
                                ))
                            }                        </select> 
                        <select 
                            value={(advertiserFilter!= null)? advertiserFilter : ""}
                            onChange={e => handleAdvertiserFilterChange(e.target.value)}
                            className="headerBarFilter__select headerBarFilter__selectEntity" name="selectAdvertiser">
                            <option key='all' value="">{t('home:allAdvertisers')}</option>
                            {
                                filterOptions?.advertisers?.sort((a, b) => a.name.localeCompare(b.name)).map((a, i) => (
                                <option  key={i} value={a.uid}>{a.name}</option>
                                ))
                            }
                        </select>                      
                    </div>
                    ) : null
                }
                      
                {
                    showGrantAdmModal ? (
                        <ADMGrantModal
                            handleClose = {() => setShowGrantAdmModal(false)}
                            show = {showGrantAdmModal}
                        /> 
                    ) : null
                }  
            </div>

            <div className="headerBar__right">
                {/* <div className="right__element">
                    <div className="rightElement__title">
                        Créer une ADM
                    </div>
                </div> */}
                <Link to={{pathname: "/newHome"}} className="btn-sm">
                    <ViewHeadlineRoundedIcon />
                </Link>
                {
                    "MG, MR".includes(role) ? (
                        <>
                            <Button className="btn-sm new_mandat" onClick={() => setShowAdmModal(true)}>
                                <AddIcon style={{color: "#1589EE", marginBottom: "2px"}} />
                                &nbsp;&nbsp; {t('home:createAdmBtn', 'Créer une ADM')} 
                            </Button>
                            <Button className="btn-sm new_mandat" onClick={() => handleShowADMTemplateModalTrue()}>
                                <AddIcon style={{color: "#1589EE", marginBottom: "2px"}} />
                                &nbsp;&nbsp; {t('home:createAdmTemplateBtn', 'Créer une ADM depuis un modèle')} 
                            </Button>
                        </>
                    ) : ""
                }
                
                {
                    showADMModal ? (
                        <ADMCreationModal
                            handleClose = {() => setShowAdmModal(false)}
                            show = {showADMModal}
                        /> 
                    ) : null
                }
                {
                    showADMTemplateModal ? (
                        <DocDuplicateModal
                            handleClose={() => setShowADMTemplateModal(false)}
                            show={showADMTemplateModal}
                            selectedAdvertiser={selectedAdvertiser}
                            setSelectedAdvertiser={setSelectedAdvertiser}
                            selectedGroup={selectedGroup}
                            setSelectedGroup={setSelectedGroup} 
                            advertiserType={advertiserType} 
                            setAdvertiserType={setAdvertiserType}                             
                            setSearchQuery={setSearchQuery}
                            advertisers={advertisers}
                            selectedStartDate={selectedStartDateDuplicate}
                            selectedEndDate={selectedEndDateDuplicate}
                            setSelectedStartDate={setSelectedStartDateDuplicate}
                            setSelectedEndDate={setSelectedEndDateDuplicate}
                            duplicateDocument={createFromTemplate}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                        /> 
                    ) : null
                }
                              
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    role: state.auth.role,
    isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar)
