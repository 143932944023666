import React, { useState } from 'react'
import './SecretResignReasonModal.css'
import { Notyf } from 'notyf';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function SecretResignReasonModal({handleClose, show, secretResignSignature}) {
    const[reasonMessage, setReasonMessage] = useState(null)
    const[user, setUser] = useState(null)
    const[fieldsStats, setFieldsStats] = useState(true)
    const[showConfirme,setShowConfirme]=useState(false)
    const {t} = useTranslation();

    const inputUserChangeHandler = (event) => {
        console.log(user)
        let name = event.target.name;
        let value = event.target.value;
        setUser({...user, [name]: value});
        setFieldsStats(true)

    }

    const resignClickHandler = () => {
        if( (user?.first_name && user?.first_name!="")  &&  (user?.last_name && user?.last_name!="") ){
            secretResignSignature(user, reasonMessage)
            handleClose();
        }else{
            setFieldsStats(false)
        }
    }


    const Message=()=>{
        if(reasonMessage?.length > 0 && (user?.hasOwnProperty('first_name')&& user?.first_name?.length > 0) && (user?.hasOwnProperty('last_name')&& user?.last_name?.length > 0) && (user?.hasOwnProperty('function')&& user?.function?.length > 0) ){
            return true
        } else{
            return false

        }
            
        
        
    }
    return (
        <>
            <Modal 
                show={show} 
                onHide={handleClose} 
                //contentClassName="modal_w_mc" 
            >
                <Modal.Header closeButton>
                <Modal.Title>{t('mandat:resignSignModal', "Refus de la signature du mandat")} </Modal.Title>
                </Modal.Header>
                <Modal.Body className="ResignReasonModal__body" >
                    <div className="mb-2">
                        {
                            !fieldsStats 
                                ? <div style={{color:"red", marginBottom:"20px"}}>{t('default:refusalReasonFields', "Les champs nom et prénom sont obligatoires")}</div>
                                : null
                        }
                        <label htmlFor="first_name">{t('default:firstName', "Prénom")} </label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="first_name" 
                            name="first_name" 
                            value={user?.first_name || ""}
                            onChange={(e) => inputUserChangeHandler(e)}
                            required
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="last_name">{t('default:name', "Nom")} </label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="last_name" 
                            name="last_name" 
                            value={user?.last_name || ""}
                            onChange={(e) => inputUserChangeHandler(e)}
                            required
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="fonction">{t('default:function', "Fonction")}</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            id="fonction" 
                            name="function" 
                            value={user?.function || ""}
                            onChange={(e) => inputUserChangeHandler(e)}
                        />
                    </div> 
                    <div className="mb-2">
                        <label htmlFor="reason">{t('default:reason', "Motif")} </label>
                        <textarea 
                            class="form-control" 
                            id="reason"
                            name="reason"
                            rows="1"
                            value={reasonMessage || ''}
                            onChange={(e) => setReasonMessage(e.target.value)}
                        >
                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")} 
                </Button>
                <Button variant="danger"  disabled={!Message()} onClick={() => resignClickHandler()}>
                    {t('default:deny', "Refuser")}
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SecretResignReasonModal
