import React, { useEffect, useState } from 'react';
import ADMWorkFlow from '../../components/ADMContext/ADMWorkFlow/ADMWorkFlow';
import ADMProgressLine from './../../components/ADMContext/ADMProgressLine/ADMProgressLine';
import ADMTopCard from './../../components/ADMContext/ADMTopCard/ADMTopCard';
import ADMBody from './../../components/ADMContext/ADMBody/ADMBody';
import Axios from '../../axios-edipub';
import './Mandat.css';
import { Notyf } from 'notyf';
import { useParams } from 'react-router-dom';
import {formatDate, getNotyfObject} from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import MandatNotFind from '../../components/Mandat/MandatModal/MandatNotFind'
import { set } from 'date-fns';
import { useHistory } from "react-router-dom";

function Mandat(props) {
    const {
        loading,
        admData,
        error,
        getMandatData,
        updateMandatStatus,
        isCreator,
        role,
        creatorName,
        setTabSideActive,
        resetAdmToInitialState
    } = props
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const {i18n } = useTranslation();
    const userUid = localStorage.getItem('userId');  
       

    let {uid} = useParams();
    const notyf = getNotyfObject();
    const {t} = useTranslation();

    const handleClose=()=>{
        setShowModal(false);
        let path = "/mandat";
        history.push({
            pathname: path,
           
        });

    }
     
    

    useEffect(() => {

        Axios.get('/user/'+ (uid ? uid : '')+'/concernedUsersForDoc', 
        {
            params:{
                status: null,
                searchFilter: null
            }
        }).then(res => {
            res?.data?.mandats.some(row => row.uid === uid) ?
                getMandatData(uid, false, true):setShowModal(true);
            
        })


        // Axios.get('/user//concernedUsersForDoc', 
        // {
        //     params:{
        //         status: null,
        //         searchFilter: null
        //     }
        // }).then(res => {
        //     res?.data?.mandats.some(row => row.uid === uid) ?
        //         getMandatData(uid, false, true):setShowModal(true);
            
        // })

        // Axios.get('/user/' + uid + '/concernedUsersForDoc').then(response => {
        //     // response?.data?.users.some(row => row.uid === uid) ?
        //     // getMandatData(uid): setShowModal(true);

        // }
        // )



       // i18n.changeLanguage("en");POUR CHANGE LA LANGUE PAR RAPPORT A LA LA LANGUE SELECTIONN2 A LA CREATION DE L ADM
       // getMandatData(uid)
        
        return () => {
            resetAdmToInitialState()
        }
    }, [uid])

    const handleSignatureButton = show => {
        setTabSideActive("signature")
        /*if(show) {
            const a = document.createElement("a");
            a.href = "#signatureTab";
            a.click();
        }*/
    }

    
    let mandatJsx = null;

    if(error) { 
        if( error === 'Expired token')
            notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
        else
            notyf.error(t("mandat:generatedErrorMessage"));
    }

    if(loading) mandatJsx = (<Spinner />)
    
    else if (admData)
        mandatJsx = (
            <div className="mandat_container">
                <ADMTopCard
                    name={ (admData?.template === '1' ? 'TM-': 'AM-') + admData?.id}
                    advertiser={admData?.annonceur?.commercial_name}
                    creationDate={formatDate(+admData?.creation_date)}
                    period={formatDate(+admData?.start_period) + "-" + formatDate(+admData?.end_period) }
                />
                <ADMProgressLine setShowSignatureTab={handleSignatureButton}
                 />
                <ADMBody
                    lang={admData.lang}
                    mandat={admData}
                    isCreator={isCreator}
                    //addressClick={addressClickHandler}
                />
                {/* <ADMWorkFlow 
                    mandat = {admData}
                /> */}

            </div>
        )

    return (
        <div>
         {showModal ? (
            <MandatNotFind 
               handleClose = {handleClose}
               show = {showModal}
               />
              ) : mandatJsx}
       
        </div>
    )
}
const mapStateToProps = (state) => ({
    loading: state.mandat.loading,
    admData: state.mandat.admData,
    error: state.mandat.error,
    role: state.auth.role,
    isCreator : state.auth?.userId === state.mandat?.admData?.creatorUid,
    creatorName : state.mandat?.admData?.creatorName
})
const mapDispatchToProps = dispatch => ({
    getMandatData: (mandatUid, generatePdf = false, refreshMasterDataAndPdfIfPossible = false) => dispatch(actions.getMandatData(mandatUid, generatePdf, refreshMasterDataAndPdfIfPossible)),
    setTabSideActive: (tabSideActive) => dispatch(actions.setTabSideActive(tabSideActive)),
    updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status)),
    resetAdmToInitialState : () => dispatch(actions.resetAdmToInitialState())
})
export default connect(mapStateToProps, mapDispatchToProps)(Mandat)
