import React, { useState } from 'react'
import './TableBody.css'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub';
import { getNotyfObject } from '../../../shared/utility';

function TableBody(props) {
    const {t} = useTranslation();
    const {data, formatDate, selectedRows, setSelectedRows, stepsRef} = props;
    const userRole = localStorage.getItem('role');
    const history = useHistory();
    const notyf = getNotyfObject()
    
    const getSelectedRowValue = (docUid) => {
        const checkedDocIndex = selectedRows.findIndex(u => docUid === u);
        return checkedDocIndex >= 0 ;
    }

    const selectRowHandler = (e) => {
        let docUid = e.target.name;
        let isChecked = e.target.checked;
        if(isChecked){
            setSelectedRows(selectedRows => ([...selectedRows, docUid]))
        }else {
            const checkedDocIndex = selectedRows.findIndex(u => docUid === u);
            if(checkedDocIndex >= 0){
                let selectedRowsTemp = [...selectedRows];
                selectedRowsTemp.splice(checkedDocIndex, 1);
                setSelectedRows(selectedRowsTemp);
            }
        }
    }

    const downloadClickHandler = (uid) => {
        Axios.get('/document/' + uid + '/getPDFFile').then(response => {
            let pdfFile = response?.data?.pdfFile
            let pdfFileName = response?.data?.pdfFileName
            if(pdfFile) {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
                    let byteCharacters = atob(pdfFile);
                    let byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    let byteArray = new Uint8Array(byteNumbers);
                    let blob = new Blob([byteArray], {type: 'application/pdf'});
                    window.navigator.msSaveOrOpenBlob(blob, pdfFileName || "mandat.pdf");
                }
                else { // much easier if not IE
                    //________Direct Download_____
                    let blob = new Blob([pdfFile], { type: 'application/pdf' })
                    const downloadUrl = URL.createObjectURL(blob)
                    let a = document.createElement("a"); 
                    a.href = "data:application/pdf;base64," + pdfFile;
                    a.download = pdfFileName || "mandat.pdf";
                    document.body.appendChild(a);
                    a.click();
                }
            }
        }).catch(response => {
            notyf.error("Une erreur s'est produite")
        })
    }

    return (
        <>    
            {
                data?.map(row => (
                    <tr key={row.id} className="tableBody__tr" style={{ cursor: userRole!=='RG' && userRole!=='RC' ? 'pointer' : 'default' }} onClick={() => userRole!=='RG' && userRole!=='RC' ? (history.push('/mandat/' + row.uid)):null}>
                        <td >
                            <input 
                                type="checkbox" 
                                name={row.uid} 
                                onChange={(e) => selectRowHandler(e)} 
                                onClick={e => e.stopPropagation()} 
                                checked={getSelectedRowValue(row.uid)}  
                                className="form-check-input" 
                                style={{margin: 0}} 
                                id="exampleCheck1" />
                        </td>
                        <td className="stepLabel">
                            <label className={row.status} style={{color:"white"}}>
                                {stepsRef[row.status] + (row.published  === "1" ? " / " + t("default:published", "Publiée") : "")}
                            </label>
                        </td>
                        <td>{ row?.id }</td>
                        <td>{ formatDate(+row.creationDate) }</td>
                        <td>{ row.official_name }</td>
                        <td>{ row.idNumber }</td>
                        <td>{ row.mandataire }</td>
                        <td>{ row.sousMandataires }</td>
                        <td>{ formatDate(+row.start_period) }</td>
                        <td>{ formatDate(+row.end_period) }</td>
                        <td>{ row.description }</td>
                        <td>{ row.medias }</td>
                        <td>{ row.products }</td>
                        {
                            userRole==='RG' || userRole==='RC' ? (
                                <td>
                                    <span  onClick={()=>downloadClickHandler(row.uid)} style={{ cursor:'pointer', color: '#2a6da4', fontWeight: 'bold' }}>PDF</span>
                                </td>
                            ) : null
                        }
                    </tr>
                    
                ))
                
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuth : state.auth.token !== null,
    token: state.auth.token,
    role : state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
