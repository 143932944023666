import React, { useEffect, useRef, useState } from 'react'
import './AdmInfoCard.css'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import ClearIcon from '@material-ui/icons/Clear';
import { Link } from 'react-router-dom'
import Axios from '../../../../axios-edipub'
import DocSupModal from '../DocSupModal/DocSupModal'
import DocDuplicateModal from '../DocDuplicateModal/DocDuplicateModal'
import { getNotyfObject } from '../../../../shared/utility'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ConsoleView } from 'react-device-detect'
import { formatDate } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';
import {CTooltip} from '../../../UI/CTooltip/CTooltip'
import { useHistory } from 'react-router'
 
function AdmInfoCard(props) {
    const {t} = useTranslation();
    const divRef = useRef();
    const history = useHistory()

    const {i,adm, advertisers, refreshContent, refreshContentForDupplicate, setSearchQuery, groups, setSearchQueryGroup, highlighted, clicked, role, stepsInitialStatus, setAdm ,notyf} = props
   
    const uid =adm?.uid;
    const admStatus=adm?.status;
    const documentId=adm?.id;
    const company=adm?.official_name;
    const mandataire=adm?.mandataire;
    const period= formatDate(+ adm?.start_period) +" - "+formatDate(+adm?.end_period) ;
    const products=adm?.products;
    const medias=adm?.medias;
    const events=adm?.nbEvents;
    const advertiserUid=adm?.advertiserUid;
    const advertiserGroupUid=adm?.advertiserGroupUid;
    const isCreator=+adm?.isCreator;
    const published=adm?.published
    const hasAction = adm?.hasAction
    const hasChatPending = adm?.hasChatPending
    const hasPostSignChatPending = adm?.hasPostSignChatPending
    const hasSignatureResignPending = adm?.hasSignatureResignPending
    const isPayer = adm?.isPayer
   
   
   
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDupModal, setShowDupModal] = useState(false);
    const [stepRef, setStepRef] = useState()
    const [selectedAdvertiser, setSelectedAdvertiser] = useState(advertiserUid)
    const [selectedGroup, setSelectedGroup] = useState(advertiserGroupUid);
    const [advertiserType, setAdvertiserType] = useState(selectedGroup ? "group" :"advertiser")
    const currentDate = new Date();
    const defaultStartDateDupplicate = new Date((currentDate.getMonth()>=8 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()), 0, 1);
    const defaultEndDateDupplicate = new Date(defaultStartDateDupplicate.getFullYear(), 12, 0);
    const [selectedStartDateDupplicate, setSelectedStartDateDupplicate] = useState(defaultStartDateDupplicate)
    const [selectedEndDateDupplicate, setselectedEndDateDupplicate] = useState(defaultEndDateDupplicate)    

    // //useEffect rajouté car dans le parent, si home refresh avec refreshContent , le adm_list.map n'appele pas constructeur => donc si ajout d une card il décale les composants et pas les variable de state instancié avant le refersh sont conservées dans les composants donc décalées(selectedAdvertiser,selectedStartDateDupplicate... )
    useEffect(() => { 
        setSelectedAdvertiser(advertiserUid)  
        if(advertiserUid) {
            setAdvertiserType( "advertiser")
            setSelectedGroup(null)
        }
    }, [advertiserUid]); 

    useEffect(() => { 
        setSelectedGroup(advertiserGroupUid)
        if(advertiserGroupUid)  {
            setAdvertiserType( "group")
            setSelectedAdvertiser(null)  
        } 
    }, [advertiserGroupUid]);  

    // useEffect(() => { 
     
    //     return () => {
    //         setStepRef(null)
    //         setSelectedAdvertiser(null)
    //         setSelectedStartDateDupplicate(null)
    //         setselectedEndDateDupplicate(null)
            
           
    //     }
    // }, [i]); 

    
  
    let stepsRef = {
        "DRAFT" :"Brouillon",
        "TO_BE_APPROVED" :"Approbation",
        "TO_BE_SIGNED" :"Signature",
        "SIGNED" :"Signée",
        "ABANDONED" :"Abandon",
        "EXPIRED" :"Expirée",
        "CANCELLED" : "Résiliée"
    }

    const clickOutSideHandler = e => {
        if(divRef?.current?.contains(e.target)) {
            // inside the div
            return
        }
        // outside click
        setShow(false)
    }

    const deleteMandat = (uid) => {
        Axios.delete('/document/'+uid+'/drop').
            then(response => {
                if(response.data.Success){
                    setShowModal(false)
                    notyf.success(response.data.Success)
                    refreshContent()
                }
                else
                    notyf.error(response.data.Failed)
        })
    }

    const duplicateMandat = (uid) => {
        if( advertiserType == "group" ){
            Axios.post('/document/'+uid+'/duplicate', {
                advertiserGroupRef: selectedGroup,
                startPeriod: selectedStartDateDupplicate.getTime(),
                endPeriod: selectedEndDateDupplicate.getTime()
            }).then(response => {
                if(response.data.Success){
                    notyf.success(response.data.Success)
                    refreshContentForDupplicate()
                }
            }).catch(err => {
                if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else 
                    notyf.error(err.response.data.Failed)
            })
        }else{
            Axios.post('/document/'+uid+'/duplicate', {               
                advertiserRef: selectedAdvertiser,
                startPeriod: selectedStartDateDupplicate.getTime(),
                endPeriod: selectedEndDateDupplicate.getTime()
            }).then(response => {
                if(response.data.Success){
                    notyf.success(response.data.Success)
                    refreshContentForDupplicate()
                }
            }).catch(err => {
                if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                    notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
                else 
                    notyf.error(err.response.data.Failed)
            })
        }
        setShowDupModal(false)
    }

    const removeChatPending  = (uid) => {
        Axios.put('/document/'+uid+'/chatPending').then(response => {
            if(response.data.Success){
                notyf.success(response.data.Success)
                refreshContent()
            }
        }).catch(err => {
            if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else 
                notyf.error(err.response.data.error)
        }) 
    }

    const removePostSignChatPending = (uid) => {
        Axios.put('/document/'+uid+'/postSignChatPending').then(response => {
            if(response.data.Success){
                notyf.success(response.data.Success)
                refreshContent()
            }
        }).catch(err => {
            if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else 
                notyf.error(err.response.data.error)
        }) 
    }

    const removeSignatureResignPending = (uid) => {
        Axios.put('/document/'+uid+'/signatureResignPending').then(response => {
            if(response.data.Success){
                notyf.success(response.data.Success)
                refreshContent()
            }
        }).catch(err => {
            if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else 
                notyf.error(err.response.data.error)
        }) 
    }

    
    useEffect(() => {
        
        setStepRef(stepsRef[admStatus])
        document.addEventListener("mousedown", clickOutSideHandler);

        return () => {
            document.removeEventListener("mousedown", clickOutSideHandler);
        }
    }, [])
   
    const onClickCard = ()=>{ 
        if( !"RC, RG".includes(role) && stepsInitialStatus){
            history.push("/mandat/"+uid )
        }
    } 

    return (
        <div className={"admInfoCard__container " + (highlighted ? "admInfoCard__containerActive" : "") + (isCreator ? "admInfoCard__creator" : "")} onClick={clicked} style={{position:"relative"}}>
            <div className="admInfoCard__header">
                <div onClick={() => onClickCard()} style={{cursor: "pointer"}} >ADM-{documentId}</div>
                <div style={{display: "flex"}} onClick={() => onClickCard()}>
                    <a href='#' className="admInfoCard__headerTitle" style={{color: "#1589EE"}}>
                        {company}
                    </a>
                </div>
                <div className="admInfoCard__headerDropDown">
                    <label className={admStatus} style={{color:"white"}}>
                        {
                            t(`default:${admStatus}`, stepRef) + (published === "1" ? (" / " + t("default:published", "Publié")) : "")
                        }
                    </label>
                    {!"AC, AG, RC, RG".includes(role) && <><ArrowDropDownIcon 
                        className="admInfoCard__headerDropDownIcon"
                        style={{
                            border: "1px solid gray",
                            borderRadius: "5px",
                            fontSize: "1rem",
                            cursor: "pointer"
                        }} 
                        onClick={() => setShow(!show)}
                    />
                    <div ref={divRef} className="admInfoCard__headerDropDownActions" style={{display: show ? "flex" : "none", zIndex: "1"}}>
                        <div className="admInfoCard__headerDropDownAction cardInfo">
                            <Link to={{
                                    pathname: "/mandat/"+uid
                                }} className="link_spec"> 
                                <p>
                                    {
                                      t('home:seeAdm', "Voir l'ADM")
                                    } 
                                </p>
                            </Link>
                        </div>
                        {
                            admStatus.includes("DRAFT") ? 
                                <div className="admInfoCard__headerDropDownAction cardInfo">
                                    <p 
                                        className="style_options link_spec" 
                                        onClick={ () => setShowModal(!showModal) } 
                                    > 
                                        {
                                            t('default:delete', "Supprimer")
                                        }  
                                    </p> 
                                    {
                                        showModal ? <DocSupModal
                                            show={showModal}
                                            docReference={documentId}
                                            handleClose={() => setShowModal(false)}
                                            deleteDocument={() => deleteMandat(uid)} /> : null
                                    }
                                </div>
                            : null
                        }
                        
                        <div className="admInfoCard__headerDropDownAction cardInfo">
                            <p 
                                className="style_options link_spec" 
                                onClick={  () => setShowDupModal(!showDupModal)  }
                            > 
                                {
                                    t('default:duplicate', "Dupliquer")
                                }  
                            </p>
                            {
                                showDupModal ? <DocDuplicateModal
                                    advertiserType={advertiserType}
                                    selectedAdvertiser={selectedAdvertiser}
                                    setSelectedAdvertiser={setSelectedAdvertiser}
                                    selectedGroup={selectedGroup}
                                    setSelectedGroup={setSelectedGroup}
                                    setSearchQuery={setSearchQuery}
                                    advertisers={advertisers}
                                    groups={groups}   
                                    setSearchQueryGroup={setSearchQueryGroup}
                                    show={showDupModal}
                                    docReference={documentId}
                                    selectedStartDate={selectedStartDateDupplicate}
                                    selectedEndDate={selectedEndDateDupplicate}
                                    setSelectedStartDate={setSelectedStartDateDupplicate}
                                    setSelectedEndDate={setselectedEndDateDupplicate}
                                    handleClose={() => setShowDupModal(false)}
                                    duplicateDocument={() => duplicateMandat(uid)} /> : null
                            }
                        </div>
                        <hr/>
                        {/* <div className="admInfoCard__headerDropDownAction cardInfo options_info" >
                            <p className="style_options" className="link_spec" onClick={ () => optionsMandat(uid) }> Options </p>
                        </div> */}
                    </div></>}
                </div>
            </div>
            <div className="admInfoCard__body" >
                <div onClick={() => onClickCard()} style={{cursor: "pointer"}}>

            
                    <div className="admInfoCard__element">
                        <div className="admInfoCard__elementLabel">{t('default:representative', "Mandataire")} 
                        { ((isPayer && +isPayer) ? (
                            <CTooltip title={t('home:payerAgencyAndOrSubAgency', "Mandataire et/ou Sous-Mandataire payeur")}>
                                <MonetizationOnIcon
                                    className="admInfoCard__headerDropDownIcon"
                                    style={{
                                        fontSize: "1rem",
                                        color: "rgb(21, 137, 238)",
                                        paddingLeft: "2px",
                                        paddingBottom: "3px"
                                    }}
                                />
                            </CTooltip> 
                            ):'')} :
                        </div>
                        <div className="admInfoCard__elementValue">
                            {mandataire ? mandataire : "-"}
                        
                        </div>
                    </div>
                    <div className="admInfoCard__element">
                        <div className="admInfoCard__elementLabel">
                            {
                                t('default:period', "Période")
                            } :
                        </div>
                        <div className="admInfoCard__elementValue">{period} </div>
                    </div>
                    <div className="admInfoCard__element">
                        <div className="admInfoCard__elementLabel">
                            {
                                t('home:admCard_brand', "Prod/Marq")
                            }  :
                        </div>
                        <div className="admInfoCard__elementValue">{products ? products : "-"} </div>
                    </div>
                    <div className="admInfoCard__element">
                        <div className="admInfoCard__elementLabel" >
                            {
                                t('default:medias', "Médias")
                            }  :</div>
                        <div className="admInfoCard__elementValue">{medias ? medias : "-"} </div>
                        {/* { +events > 0  ?
                                <p className="nb_events">{events}</p>
                            : ""
                        } */}
                    </div>
                    
                    { hasAction && +hasAction ?
                        <div className="admInfoCard__element">
                            <p className="has_action">{admStatus === "TO_BE_APPROVED" ? t("mandat:youHaveToApprove", "Vous devez approuver") : (admStatus === "TO_BE_SIGNED" ? t("mandat:youHaveToSign", "Vous devez signer") : "")}</p>
                        </div> : ""
                    } 
                </div>  
                {  hasChatPending && +hasChatPending ?
                    <div className="admInfoCard__element"> 
                        <p className="has_action">  
                            {t("mandat:userChat", "Modification")}  &nbsp;
                            <ClearIcon  style={{fontSize: "17px"}} className="hasChatPending_clearIcon" onClick={() => removeChatPending(uid)} />                            
                       </p>
                    </div> : ""
                }  
                {  hasPostSignChatPending && +hasPostSignChatPending ?
                    <div className="admInfoCard__element">  
                        <p className="signatureResign"> 
                            {"RC, RG".includes(role) ?  t("mandat:postSignChatForRegies",  "Info post-signature") :  t("mandat:postSignChat", "Remarque")} &nbsp;
                            {/* <ClearIcon  style={{fontSize: "17px"}} className="hasChatPending_clearIcon"   onClick={() => removePostSignChatPending(uid)}/>     */}
                        </p>
                    </div> : ""
                }   
                {   hasSignatureResignPending && +hasSignatureResignPending ?
                    <div className="admInfoCard__element">
                        <p className="signatureResign">
                            {t("mandat:signatureResign", "Refus de signature")} &nbsp;
                            <ClearIcon  style={{fontSize: "17px"}} className="hasChatPending_clearIcon" onClick={() => removeSignatureResignPending(uid)} />                          
                        </p>
                    </div> : ""
                }  
            </div>
        </div>
       
    )
}

const mapStateToProps = (state,i) => ({ 
    role: state.auth.role,
    authUserId: state.auth.userId,
})
const mapDispatchToProps = dispatch => ({
    setAdm: (a) => dispatch(actions.setAdm(a)) 
})  
export default connect(mapStateToProps, mapDispatchToProps)(AdmInfoCard)