import React, { useContext, useEffect, useState,useRef } from 'react'
import './OfflineTab.css';
import axios from 'axios'
import Axios from '../../../../../axios-edipub'
import Spinner from 'react-bootstrap/Spinner'
import * as actions from '../../../../../store/actions/index';
import DoneIcon from '@material-ui/icons/Done';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp'; 
import {Link} from 'react-router-dom';
import { checkValidity, getNotyfObject } from '../../../../../shared/utility'; 
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import 'react-phone-input-2/lib/style.css'
import DisclaimerModal from './DisclaimerModal/DisclaimerModal';
import ConfirmOnlineModal from './ConfirmOnlineModal/ConfirmOnlineModal';

function OfflineTab({role,
     user,
     setTabSideActive,
     mandatUid,
     getMandatData,
     generateAdmPdf,
     admData,
     signatureSuccess,
     updateMandatData,
     updateMandatOffline,
     pdfFile,
     pdfFileName}) {
 
    const notyf = getNotyfObject();
    const {t} = useTranslation(); 
    const [confirmUnknownSignatoryIdentity, setconfirmUnknownSignatoryIdentity] = useState(false);
    const [unknownSignatoryUser, setunknownSignatoryUser] = useState(null)
    const [unknownSignatoryUserErrors, setunknownSignatoryUserErrors] = useState([]);
    const [errors, setErrors] = useState(null)
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false)
    const [showOnlineConfirmModal, setShowOnlineConfirmModal] = useState(false)
    const [unknownSignatory, setUnknownSignatory] = useState(false)
    const [selectedFile, setSelectedFile] = useState()
    const [downloaded, setDownloaded] = useState(false)
    const [offlineDone, setOfflineDone] = useState(false)
    const [signADMLoading, setSignADMLoading] = useState(false);

    useEffect(() =>{
        let foundSignatory = false;
        admData?.annonceur && admData.annonceur?.usersDoc && admData.annonceur.usersDoc.forEach(u => {
            if (parseInt(u.is_signataire))
                foundSignatory = true;              
        }) 
        setUnknownSignatory(!foundSignatory);
    }, []) 

    const downloadClickHandler = () => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
            let byteCharacters = atob(pdfFile);
            let byteNumbers = new Array(byteCharacters.length);
            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {type: 'application/pdf'});
            window.navigator.msSaveOrOpenBlob(blob, pdfFileName || "mandat.pdf");
        }
        else { // much easier if not IE
            //________Direct Download_____
            let blob = new Blob([pdfFile], { type: 'application/pdf' })
            const downloadUrl = URL.createObjectURL(blob)
            let a = document.createElement("a"); 
            a.href = "data:application/pdf;base64," + pdfFile;
            a.download = pdfFileName || "mandat.pdf";
            document.body.appendChild(a);
            a.click();

            //__________Visualize In The Browser _____
            // const blob = dataURItoBlob(data);
            // const url = URL.createObjectURL(blob);

            // // to open the PDF in a new window
            // window.open(url, '_blank');
        }
        setDownloaded(true)
    }

    const onFileChange= (event) => {  
        setSignADMLoading(true)
        setSelectedFile(event.target.files[0])
        //
        console.log("uploadFileHandler selectedFile=")
        console.log(selectedFile)          
        console.log("onFileChange event.target.files[0] =" ) ;
        console.log(event.target.files[0]) ;
        console.log("axios avant appel")
      
        const formData = new FormData();  
        // formData.append( "files", selectedFile)
        formData.append( "files", event.target.files[0])
        formData.append( "userUid", user?.userUid)
        formData.append( "description", admData?.description)  
        formData.append( "unknownSignatory", unknownSignatory) 
        if(unknownSignatory) {
            formData.append( "unknownSignatoryUserLastname", unknownSignatoryUser.last_name) 
            formData.append( "unknownSignatoryUserFirstname", unknownSignatoryUser.first_name)
        }

        if(role != "AE")
            Axios.post(`/document/${mandatUid}/signDocumentOffline`,formData, {headers: { 'Content-Type': 'multipart/form-data' }})
            .then(response => {           
                signatureSuccess(response) 
            })
            .catch(err => {
                console.error(err)
                notyf.error(t("mandat:generatedErrorMessage"))
                setSignADMLoading(false)
            })
            .finally(() => {
                console.log("axios retour") 
            })
        else
            Axios.post(`/document/${mandatUid}/replaceDocumentOffline`, formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(response => {           
                signatureSuccess(response) 
            }).catch(err => {
                console.error(err)
                notyf.error(t("mandat:generatedErrorMessage"))
                setSignADMLoading(false)
            })

    }   

    const onlineAdm = () => {
        updateMandatOffline(admData?.uid, false)
        setTabSideActive("timeline")
    } 
     
 
    const checkUserFormValidity = () => {
        let errors = [];
        if(unknownSignatoryUser === null || !unknownSignatoryUser.hasOwnProperty('last_name') || !checkValidity(unknownSignatoryUser?.last_name, {required: true})){
            errors.push(t("masterData:error_userLastName")) ;
        }
        if(unknownSignatoryUser === null || !unknownSignatoryUser.hasOwnProperty('first_name') || !checkValidity(unknownSignatoryUser?.first_name, {required: true})){
            errors.push(t("masterData:error_userFirstName")) ;
        }  
        return errors;
    }

    const checkIdentityClickHandler = (checked) => {
        if(checked){
            setOfflineDone(true)
            let errors = checkUserFormValidity();
            if(errors && errors.length > 0){
                setunknownSignatoryUserErrors(errors)
            }else {
                setconfirmUnknownSignatoryIdentity(true)
                setunknownSignatoryUserErrors([]) 
            }
        }
    } 

    const inputUserChangeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setunknownSignatoryUserErrors([])
        setunknownSignatoryUser({...unknownSignatoryUser, [name]: value});
    } 

    const uploadClickHandler = () => {  
        setDownloaded(true)
        setOfflineDone(true)
        if(unknownSignatory){
            if(!confirmUnknownSignatoryIdentity ){
                setErrors([t("mandat:confirmOfflineIdentityError")]) 
            }else{  
                setErrors(null)
                setShowDisclaimerModal(true)    
            }   
        }else
            setShowDisclaimerModal(true)   
    }
    
    return (
        <>
        { 
                
            <div className="signatureTab__container">

                <div className="signatureTab__header">
                    <h5 className="signatureTab__header__title">
                        {t('mandat:offlineTab', "Téléversement de l’attestation de mandat signée")} 
                    </h5>
                    <div className="signatureTab__header__resignButton__container">
                        
                        <Link to={{pathname: "/Home"}} 
                            className="btn btn-outline-danger btn-sm signatureTab__header__resignButton"> 
                            {t('default:cancel', "Annuler")}
                        </Link>
                        {role !== "AE" && <button 
                            className="btn btn-danger btn-sm signatureTab__header__resignButton"
                            onClick={() => setShowOnlineConfirmModal(true)}
                            >
                            {t('mandat:signOnline', "Revenir à la signature électronique")} 
                        </button>}
                        {
                           showOnlineConfirmModal ? (
                                <ConfirmOnlineModal
                                    show={showOnlineConfirmModal}
                                    handleClose={() => setShowOnlineConfirmModal(false)} 
                                    onlineAdm={() => onlineAdm()}
                                />
                            ) : null
                        }
                    </div>
                    {
                        errors && errors.length > 0 ? (
                            <div class="alert alert-danger mt-2" role="alert">
                                <ul>
                                    {
                                        errors.map(error => (<li>{error}</li>))
                                    }
                                </ul>
                            </div>
                        ) : (
                            <div class="alert alert-light" role="alert">
                                {t('mandat:signatureAlert', "Avant de poursuivre, assurez vous d'avoir bien verifier et respecter toutes les étapes")}
                            </div>
                        )
                    }
                </div>  

                <div className="signatureTab__steps">   

                    {role !== "AE" && <>
                        <div className="signatureTab__step">
                            <div className="singatureTab__step_header">
                                {
                                    downloaded ?
                                        <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                    :
                                        <div className="signatureTab__stepNumber"><span>1</span></div>
                                }
                                <div className="signatureTab__stepCheckbox__container">                                
                                    {
                                        !downloaded && 
                                            <div>    
                                                <button type="button" className="btn btn-success signatureTab__signButton" onClick={downloadClickHandler}><GetAppIcon /></button>
                                            </div>  
                                    }
                                    <label className="step__checkbox__label">{t('mandat:downloadDocument', "Je télécharge le document")}</label>
                                </div> 
                            </div>
                        </div>


                        <div className="signatureTab__step">
                            <div className="singatureTab__step_header">
                                {
                                    offlineDone ? 
                                        <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                    : 
                                        <div className="signatureTab__stepNumber">
                                            <span>2</span>
                                        </div> 
                                }
                                <div className="step__header__title">
                                    {t('mandat:pdfOfflineStep', "Envoyez-la à l’Annonceur et demandez-lui de l’imprimer, de la signer, de la scanner puis de vous la renvoyer")}
                                </div>
                            </div>
                        </div>
                    
                    {
                        unknownSignatory && 
                        <div className="signatureTab__step">
                            <div className="singatureTab__step_header">
                                {
                                    confirmUnknownSignatoryIdentity ? 
                                        <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                    : 
                                            
                                            <div className="signatureTab__stepNumber">
                                                <span>3</span>
                                            </div>
                                            
                                }
                                <div className="signatureTab__stepCheckbox__container">
                                    <input 
                                        type="checkbox" 
                                        className="step__checkbox__input" 
                                        id="identityConfirmCheckbox"
                                        checked={confirmUnknownSignatoryIdentity}
                                        disabled={confirmUnknownSignatoryIdentity}
                                        onChange={(e) => checkIdentityClickHandler(e.target.checked)}
                                        />
                                    <label 
                                        className="step__checkbox__label" 
                                        for="identityConfirmCheckbox">
                                            {t('mandat:confirmOfflineIdentity', "Je confirme l'identité du signataire 'offline'")} 
                                    </label>
                                </div>
                                
                            </div>
                            <div className="signatureTab__stepContent">
                                <div className="stepContent__identityContent">
                                    <fieldset>
                                        <input 
                                            type="text"  
                                            className="form-control form-control-sm mb-2" 
                                            placeholder="NOM"
                                            name="last_name"
                                            value={unknownSignatoryUser?.last_name || '' } 
                                            onChange={(e) => inputUserChangeHandler(e)}
                                            disabled={confirmUnknownSignatoryIdentity}
                                        />
                                        <input 
                                            type="text"  
                                            className="form-control form-control-sm mb-2" 
                                            placeholder="Prénom"
                                            name="first_name"
                                            value={unknownSignatoryUser?.first_name || '' } 
                                            onChange={(e) => inputUserChangeHandler(e)}
                                            disabled={confirmUnknownSignatoryIdentity}
                                        />                                          
                                    </fieldset>
                                        {
                                            unknownSignatoryUserErrors && unknownSignatoryUserErrors?.length > 0 ?
                                                <ul className="mt-3">
                                                    {
                                                        unknownSignatoryUserErrors.map(error => (
                                                            <li>
                                                                <span style={{color: "red"}}>{error}</span>
                                                            </li>
                                                        ))                                                    
                                                    }
                                                </ul>
                                            : null
                                        }
                                </div>
                            </div>
                        </div>
                    } 
                    </>} 


                    <div className="signatureTab__step">
                        <div className="singatureTab__step_header"> 
                            {role !== "AE" ?
                                (signADMLoading ? 
                                    <div className="signatureTab__stepDoneIcon"><DoneIcon /> </div>
                                : 
                                    <div className="signatureTab__stepNumber">
                                        <span>{ unknownSignatory ? "4" : "3" }</span>
                                    </div> ) 
                                : null
                            }

                            <div className="signatureTab__stepCheckbox__container">  
                                { 
                                        <div>
                                            <input type="file" style={{fontSize: "14px", display: "none"}} name="signedFile" id="signedFile" onChange={e => onFileChange(e)} />                                            
                                            {
                                                 signADMLoading ?
                                                    <Spinner animation="border" variant="success" />
                                                :
                                                    <button type="button" disabled ={/*!downloaded || */(unknownSignatory && !confirmUnknownSignatoryIdentity)} className="btn btn-success signatureTab__signButton" onClick={uploadClickHandler}><PublishIcon /></button>
                                            }
                                        </div>  
                                }
                                <label 
                                    className="step__checkbox__label">
                                        {t('mandat:uploadSignedDocument', "Je téléverse le document signé")} 
                                        { selectedFile != null  && (' : ' + selectedFile.name) } 
                                </label>
                            </div> 
                        </div>
                    </div> 
                </div>  
            </div>   
        } 

        {
            showDisclaimerModal ? (
                <DisclaimerModal
                    show={showDisclaimerModal}  
                    handleClose={() => setShowDisclaimerModal(false)}
                    uploadFile={() => document.getElementById('signedFile').click()}
                    // uploadFile={uploadFileHandler}
                />
            ) : null
        }
        </>
    )
}



   
const mapStateToProps = (state) => ({
    pdfFile: state.mandat.admData?.pdfFile || null,
    pdfFileName: state.mandat?.admData?.pdfFileName || "mandat.pdf"
     
})
const mapDispatchToProps = dispatch => ({
    updateMandatData: (admNewData) => dispatch(actions.updateAdmData(admNewData)),
    updateMandatOffline: (mandatUid, offline) => dispatch(actions.updateMandatOffline(mandatUid, offline))

})
export default connect(mapStateToProps, mapDispatchToProps)(OfflineTab)