import React, { useCallback, useEffect, useState } from 'react'
import './FAQ.css';
import Axios from '../../axios-edipub';
import {getNotyfObject} from '../../shared/utility';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageChangeModal from '../MasterData/PageChangeModal/PageChangeModal';
import AddCategoryModal from './AddCategoryModal/AddCategoryModal';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import Spinner from 'react-bootstrap/Spinner'
import CustomConfirmModal from '../../components/ADMContext/ADMProgressLine/CustomConfirmModal/CustomConfirmModal';
import SunEditor, {buttonList} from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

function FAQ({role}) {
    const {t, i18n} = useTranslation();
    let notyf = getNotyfObject();
    const [searchQuery, setSearchQuery] = useState("");
    const [categoriesList, setCategoriesList] = useState([]);
    const [sortOrder, setSortOrder] = useState(false);
    const [category, setCategory] = useState(null);
    const [formChanged, setFormChanged] = useState(false);
    const [pageChangeModal, setPageChangeModal] = useState(null);
    const [getLoading, setGetLoading] = useState(false);
    const [putLoading, setPutLoading] = useState(false);
    const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);

    useEffect(() => {
        getQuestionCategories();
    }, [])

    const handleSortOrder = () => {
        const newSortOrder = !sortOrder;
        setSortOrder(newSortOrder);
        getQuestionCategories(searchQuery, newSortOrder);
    }

    const getTranslatedField = (object, field) => {
        const suffix = role === "AE" || i18n.language === "fr" ? "Fr" : "En";
        return object ? object[field+suffix] : "";
    }

    const uploadManualHandler = event => {
        const formData = new FormData();
        formData.append(event.target.name, event.target.files[0]);
        Axios.post("questioncategory//manual", formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(response => {
            notyf.success("Succès du téléversement du fichier");
            event.target.value = null;
        }).catch(response => {
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }

    const getManualFile = type => {
        Axios.get("questioncategory/" + type + "/manual").then(response => {
            const file = response.data;
            if (file.fileName) {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
                    let byteCharacters = atob(file.fileContent);
                    let byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    let byteArray = new Uint8Array(byteNumbers);
                    let blob = new Blob([byteArray], {type: 'application/octet-stream'});
                    window.navigator.msSaveOrOpenBlob(blob, file.fileName);
                }
                else { // much easier if not IE
                    let a = document.createElement("a"); 
                    a.href = "data:application/octet-stream;base64," + file.fileContent;
                    a.download = file.fileName;
                    document.body.appendChild(a);
                    a.click();
                }
            } else
                notyf.open({
                    type: 'warning',
                    message: t("faq:fileDoesNotExist"),
                    duration: 5000,
                    dismissible: true
                });
        }).catch(error => {
            notyf.error(t("default:errorMessage"));
        })
    }

    const getQuestionCategories = useCallback((searchQ = searchQuery, order = false) => {
        setGetLoading(true)
        Axios.get("questioncategory", {
            params : {
                searchQuery : searchQ,
                sortOrder: (order) ? "DESC" : "ASC"
            }
        }).then(response => {
            setGetLoading(false)
            setCategoriesList(response?.data);
        }).catch(response => {
            setGetLoading(false)
            notyf.error(t("mandat:generatedErrorMessage"));
        })
    }, [categoriesList, searchQuery])

	const getQuestions = (category, saved = false) => {
        const callback = () => {
            Axios.get("questioncategory/"+category.uid+"/object").then(response => {
                setCategory(response?.data);
            }).catch(response => {
                notyf.error(t("default:errorMessage"));
            })
        }
        if (saved)
            callback();
        else
            handlePageChangeModal(() => callback())
    }

    const searchHandler = (e) => {
        setSearchQuery(e.target.value);
        getQuestionCategories(e.target.value)
    }

    const handleAddCategory = category => {
        Axios.post("questioncategory", category).then(response => {
            setCategoriesList([...categoriesList, response.data])
        }).catch(error => {

        })
    }

    const deleteQuestionHandler = index => {
        const newCategory = {...category};
        newCategory.questions.splice(index, 1);
        setCategory(newCategory);
    }

    const deleteCategoryHandler = cat => {
        return new Promise((resolve, reject) => {
            Axios.delete("questioncategory/"+cat.uid+"/category").then(response => {
                if (response.data.success) {
                    const newCategories = [...categoriesList];
                    const index = newCategories.findIndex(c => c.uid === cat.uid);
                    if (index > -1)
                        newCategories.splice(index, 1);
                    setCategoriesList(newCategories);
                    if (category.uid === cat.uid)
                        setCategory(null)
                    resolve(true);
                    notyf.success("Succès de la suppression");
                } else {
                    reject(false);
                    notyf.error("Erreur lors de la suppression");
                }
            }).catch(error => {
                reject(false);
                notyf.error(error.data.error);
            })
        })
    }
    
    const handleAddQuestion = () => {
        const newCategory = {...category};
        newCategory.questions.push({questionFr: "", questionEn: "", answerFr: "", answerEn: "", category: category.id});
        setCategory(newCategory);
    }

    const saveCategory = () => {
        let errQ = false
        let errA = false
        category.questions.forEach(element => {
            console.log("element")
            console.log(element)
            if (!element.hasOwnProperty('questionFr') || element.questionFr.length == 0){
                errQ = true
                return
            }   
            if (!element.hasOwnProperty('answerFr') || element.answerFr.length == 0 || element.answerFr ==  "<p><br></p>"){
                errA = true
                return
            }   
        });
        if(errQ){
            notyf.open({
                type: 'warning',
                message: t("faq:emptyQuestionFr"),
                duration: 5000,
                dismissible: true
            });
            return
        }
        if(errA){
            notyf.open({
                type: 'warning',
                message: t("faq:emptyAnswerFr"),
                duration: 5000,
                dismissible: true
            });
            return
        }
        Axios.put("questioncategory", category).then(response => {
            getQuestions(category);
            notyf.success("Succès de la sauvegarde")
        }).catch(error => {
            notyf.error("Erreur lors de la sauvegarde")
        })
    }

    const inputChangeHandler = (event, index) => {
        const newCategory = {...category};
        const newQuestion = newCategory.questions[index];
        newQuestion[event.target.name] = event.target.value;
        setCategory(newCategory);
    }

    const suneditorChangeHandler = (event, editorContents) => {
        const element = event.target.parentElement.parentElement.parentElement.parentElement;
        const key = element.id.slice(0, 8);
        const index = element.id.slice(8);
        const newCategory = {...category};
        const newQuestion = newCategory.questions[index];
        newQuestion[key] = editorContents;
        setCategory(newCategory);
    }

    const handlePageChangeModal = callback => {
        if (formChanged) {
            const modal = React.createElement(PageChangeModal, {handleClose: confirm => {setPageChangeModal(null); if(confirm) setFormChanged(false)}, callback: callback});
            setPageChangeModal(modal);
        } else
            callback();
    }
    
    return (
        <div className="masterData_container" style={{marginBottom: "30px"}}>
            <Prompt when={formChanged} message={location => location.pathname.startsWith("/faq") ? true : t('masterData:pageChangeModal', "Voulez-vous vraiment quitter la page ? Toutes les modifications non sauvegardées seront perdues.")}/>
            {pageChangeModal}
            <div className="masterData_sides_container" > 
                {/* Partie gauche */}
                <div className="masterData_leftSide_container" >
                    <div className="leftSide_header_container">
                        <div className="header_actionTypes_container">
                            <div className="header_actionTypes_items ">
                                <div className="header_icon">?</div>
                                <span style={{fontSize:"1rem"}}>
                                    {t('faq:faq', "Foire aux questions")} 
                                </span>
                            </div>
                        </div>
                             {role === "AE" && 
                                <div className="header_addIcon_container" onClick={() => setShowAddCategoryModal(true)} >
                                    <i style={{color:'#0070D2'}} className="fas fa-plus"></i>
                                </div>}
                    </div>
                    <div className="leftSide_manuals">
                        <div className="manualRow">
                            <i className="fa fa-book manualIcon"></i>
                            <a href="#" onClick={() => getManualFile("agencyManual")}>{t("faq:agencyManual")}</a>
                        </div>
                        {role === "AE" && <div>
                            <input type="file" style={{fontSize: "14px", display: "none"}} name="agencyManual" id="agencyManual" onChange={e => uploadManualHandler(e)} />
                            <button type="button" className="btn btn-light" onClick={() => document.getElementById('agencyManual').click()}><PublishIcon /> Téléversement de manuel Mandataire</button>
                        </div>}
                        <div className="manualRow">
                            <i className="fa fa-book manualIcon"></i>
                            <a href="#" onClick={() => getManualFile("advertiserManual")}>{t("faq:advertiserManual")}</a>
                        </div>
                        {role === "AE" && <div>
                            <input type="file" style={{fontSize: "14px", display: "none"}} name="advertiserManual" id="advertiserManual" onChange={e => uploadManualHandler(e)} />
                            <button type="button" className="btn btn-light" onClick={() => document.getElementById('advertiserManual').click()}><PublishIcon /> Téléversement de manuel Annonceur</button>
                        </div>}
                        <div className="manualRow">
                            <i className="fa fa-book manualIcon"></i>
                            <a href="#" onClick={() => getManualFile("adDepManual")}>{t("faq:adDepManual")}</a>
                        </div>
                        {role === "AE" && <div>
                            <input type="file" style={{fontSize: "14px", display: "none"}} name="adDepManual" id="adDepManual" onChange={e => uploadManualHandler(e)} />
                            <button type="button" className="btn btn-light" onClick={() => document.getElementById('adDepManual').click()}><PublishIcon /> Téléversement de manuel régie</button>
                        </div>}
                   </div>
                    <div className="leftSide_searchInput_container">
                        <label className="searchInput_label" htmlFor="search">{t('default:search', "Rechercher")} </label>
                        <div className="searchInput_input_container">
                            <i className="fas fa-search searchInput_input_icon"></i>
                            <input 
                                type="text"  
                                className="form-control searchInput_input" 
                                name="searchQuery"  
                                id="searchQuery" 
                                placeholder={t('faq:category',"Catégorie")}
                                onChange={(e) => searchHandler(e)}
                                value={searchQuery || ''}
                            />
                        </div>
                    </div>
                    {/* Info list */}
                    <div className="leftSide_listSort_container">
                        <p className="listSort_container" style={{color:"#706E6B", cursor: "pointer"}}  
                            onClick={() => handleSortOrder()}>
                            <span>{t('faq:categories', "Catégories")}</span>
                            {
                                !sortOrder ? (
                                    <ArrowDownwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            color: "darkslategray",
                                            marginLeft: "10px"
                                        }} />
                                ): (
                                    <ArrowUpwardIcon
                                        style={{
                                            fontSize: "1rem",
                                            color: "darkslategray",
                                            marginLeft: "10px"
                                        }}
                                    />
                                )
                            }
                        </p>
                    </div>
                    <div className="leftSide_listElements_container">
                        {
                            getLoading ?
                                <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
                                    <Spinner animation="border" variant="secondary" />
                                </div>
                            : categoriesList && categoriesList.map(cat =>  
                                <div 
                                    className={
                                        (cat.uid === category?.uid) ? 
                                            "col-12 list-group-item-action questionCategory_selected" 
                                        : 
                                            "col-12 list-group-item-action questionCategory"
                                    }
                                    key={cat.id} 
                                    onClick={() => getQuestions(cat)}>
                                    <div className="row">
                                        <div className="col-10">
                                            <div className="listElements_container__element row">
                                                <strong>{getTranslatedField(cat, "label")}</strong>
                                            </div>
                                            {role === "AE" && <div className="listElements_container__element row">{cat?.labelEn}</div>}
                                        </div>
                                        {role === "AE" && <div className="col-2">
                                            <DeleteForeverIcon className="deleteCategoryIcon" onClick={() => setShowDeleteCategoryModal(cat)} />
                                        </div>}
                                    </div>
                                </div>)
                        }
                    </div>
                </div>
                <div className="masterData_rightSide_container" >
                    <div className="rightSide_header_container">
                        <div className="header_infos_container">  
                            <div className="header_title">
                                <strong>{getTranslatedField(category, "label")}</strong>
                            </div>
                        </div>  
                    </div>
                    <div className="rightSide_form_container">
                        <form>
                            {role === "AE" ? category?.questions?.map((question, index) =>
                                <div>
                                    <div className="questionAnswer_form_container">
                                        <div className="form_element_container">
                                            <label htmlFor="questionFr">Question (français)</label>
                                            <input type="text" className="form-control" required minLength="1" id={"questionFr"+question.id} name="questionFr" value={question?.questionFr || ''} onChange={(e) => inputChangeHandler(e, index)} />
                                        </div>
                                        <div className="form_element_container">
                                            <label htmlFor="questionEn">Question (anglais)</label>
                                            <input type="text" className="form-control" required minLength="1" id={"questionEn"+question.id} name="questionEn" value={question?.questionEn || ''} onChange={e => inputChangeHandler(e, index)} />
                                        </div>
                                        <div style={{width:"30px"}}></div>
                                    </div>
                                    <div className="questionAnswer_form_container">
                                        <div className="form_element_container" id={"answerFr"+index}>
                                            <label>Réponse (français)</label>
                                            <SunEditor setContents={question?.answerFr || ''} onBlur={suneditorChangeHandler} height="20vh" setOptions={{buttonList: buttonList.complex}} />
                                        </div>
                                        <div className="form_element_container" id={"answerEn"+index}>
                                            <label>Réponse (anglais)</label>
                                            <SunEditor setContents={question?.answerEn || ''} onBlur={suneditorChangeHandler} height="20vh" setOptions={{buttonList: buttonList.complex}} />
                                       </div>
                                        <div style={{width:"30px"}}>
                                            <DeleteForeverIcon className="deleteCategoryIcon" onClick={() => deleteQuestionHandler(index)} />
                                        </div>
                                    </div>
                                </div>
                            ) :
                            <div>
                            {category?.questions?.map(question => 
                                <div className='questionAnswer'>
                                    <div className='questionAnswer_question'>{getTranslatedField(question, "question")}</div>
                                    <div dangerouslySetInnerHTML={{__html: getTranslatedField(question, "answer") || ''}}></div>
                                </div>
                                )}
                            </div>}
                            <div className="questionAnswer_form_container">
                                {
                                    putLoading ?
                                        <div className="rightSide_form_submit_container">
                                            <Spinner animation="border" variant="primary" />
                                        </div>
                                    : 
                                        (role === "AE" && category &&
                                            <div className="rightSide_form_submit_container">
                                                <button 
                                                    type="button" 
                                                    onClick={() => handleAddQuestion()}
                                                    className="btn btn-light rightSide_form_submitBtn"><AddIcon /> Ajouter une question</button>
                                                <button 
                                                    type="button" 
                                                    onClick={() => saveCategory()}
                                                    className="btn btn-primary rightSide_form_submitBtn">{t('default:save', "Sauvegarder")}  </button>
                                           </div>)
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <AddCategoryModal                                    
                show={showAddCategoryModal}
                handleClose={() => setShowAddCategoryModal(false)}
                categories={categoriesList}
                categoryAddHandler={handleAddCategory}
            />
            <CustomConfirmModal 
                show={showDeleteCategoryModal}
                handleClose={() => setShowDeleteCategoryModal(false)}
                text="Voulez-vous vraiment supprimer cette catégorie de questions ? Toutes les questions/réponses qui lui sont liées seront également supprimées."
                okCallback={() => deleteCategoryHandler(showDeleteCategoryModal)}
            />
        </div>
    )
}
const mapStateToProps = (state) => ({ 
    role: state.auth.role
})
const mapDispatchToProps = dispatch => ({     
    //getCountMasterDatasPending: () => dispatch(actions.getCountMasterDatasPending())  //pas utilisé car on utilise pas flag pending
})

export default connect(mapStateToProps, mapDispatchToProps)(FAQ)