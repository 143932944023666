import React, { useEffect, useState , useCallback} from 'react'
import CreatableSelect from 'react-select/creatable';
import { Button, Modal } from 'react-bootstrap';
import {checkValidity, uuidv4, getNotyfObject} from '../../../../../shared/utility' 
import './ManualEntityCreationModal.css'
import countries from '../../../../../assets/countries.json'
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import Spinner from 'react-bootstrap/Spinner'

import Axios from '../../../../../axios-edipub';

function ManualEntityCreationModal({handleClose, show, createManualEntity, entity}) {
    const [advertiserMail, setAdvertiserMail] = useState(null);
    const [lang, setLang] = useState("FR")
    const [errors, setErrors] = useState(null);
    const {t} = useTranslation();
    const [usersList, setUsersList] = useState([]);
    const [getLoading, setGetLoading] = useState(false);
    let notyf = getNotyfObject(); 

    useEffect(() => {
        setErrors(null)
    }, [advertiserMail])


    const getUsersList = useCallback(() => {
        let params = {};
        params.docUid = null;
        Axios.get(`/entity/${entity.uid}/entityDetails`, {'params' : [params] })
            .then(response => {
                console.log('response ' , response) 
                console.log('response?.data?.entity?.users ' , response?.data?.entity?.users) 
                setGetLoading(false)
                setUsersList(response?.data?.entity?.users); 
            }).catch(response => {
               setGetLoading(false)
                notyf.error(t("mandat:generatedErrorMessage"));
            }) 
    }, [usersList]) 

    useEffect(() => {
        if(entity?.id)
            getUsersList()
    }, [entity?.id])

    console.log("entity", entity)
    console.log("usersList", usersList)
    console.log("advertiserMail", advertiserMail)
    
    const addressSelectInputChangeHandler = (e) => { 
        if( e?.value){
            let foundvalue = usersList.filter(function (user) {  return user.email === e.value;}).length > 0
            console.log("foundvalue "  , foundvalue)
            if(!foundvalue){
                setUsersList ( [...usersList, {email: e.value}])
            }

            setAdvertiserMail(e.value)
        }  else { 
            setAdvertiserMail("")
        }
    } 

    const addressInputChangeHandler = (value) => { 
        console.log("addressInputChangeHandler value = ", value)
        setAdvertiserMail(value)
    }  

    const createManualEntityClickHandler = () => { 
        let errors = checkAddressFormValidity(advertiserMail);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            createManualEntity(advertiserMail, lang)
            handleClose();
        }
    } 

    const checkAddressFormValidity = (address) => {
        let errors = [] 
        if(advertiserMail) {
            if(!checkValidity(advertiserMail, {isEmail: true})){
                errors.push(t("masterData:error_userEmail")) ;
            }
        }else 
            errors.push(t("masterData:error_userEmail")) ; 
        return errors;
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} contentClassName="modal_w_mc" >
                <Modal.Header closeButton>
                <Modal.Title>{t('masterData:sendManualEntityNewMail', "Email de formulaire à un contact")}
                </Modal.Title>
                </Modal.Header>  
                <Modal.Body className="addressModal_container" style={{backgroundColor: "#ffffff", minWidth: "40rem"}}>
                {
                    errors && errors.length > 0 ? (
                        <div class="alert alert-danger mt-2" role="alert">
                            <ul>
                                {
                                    errors.map(error => (<li>{error}</li>))
                                }
                            </ul>
                        </div>
                    ) : null
                }
                {   
                 getLoading  ?
                    <div style={{width: "100%", display: 'flex', justifyContent: 'center'}}>
                        <Spinner animation="border" variant="secondary" />
                    </div>
                  : <form > 
                        <div className="form-row "> 
                            <div className="form-group col-12">
                                <label for="">{t('masterData:serviceEmail', "Email du service")} </label>
                                { usersList?.length == 0  ? 
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="" 
                                        name="email" 
                                        value={advertiserMail || ''} 
                                        onChange={(e) => addressInputChangeHandler(e.target.value)}
                                        autoComplete="off"></input>
                                  : <CreatableSelect 
                                        classNames={{control: () => "form-control"}}
                                        styles={{control: (baseStyles) => ({...baseStyles, backgroundColor: "white", borderRadius: "0.25rem", transition: "border-color .15s", padding: "0 0 0 6px"})}}
                                        id="email" 
                                        name="email"  
                                        placeholder="-- Sélectionnez un email --" 
                                        options={usersList.map(u => ({value: u.email, label: u.email}))} 
                                        value={usersList.filter(function (user) {  return user.email === advertiserMail;})
                                                        .map(u => ({value: u.email, label: u.email}))}  
                                        isClearable 
                                        backspaceRemovesValue={true}
                                        onChange={(e) => addressSelectInputChangeHandler(e)} 
                                       
                                    /> 
                                }
                            </div>  
                            
                            <div className="form-group col-2">
                            </div>
                        </div>
                        <div className="form-row ">
                            <div className="form-group col-5">
                                <label for="">{t('default:language', "Langue")} </label>  
                                <select className="form-control" id="language" name="language" 
                                    value={lang} 
                                    onChange={(e) => setLang(e.target.value) }
                                    disabled={false} >
                                    <option value="FR">FR</option>
                                    <option value="EN">EN</option>
                            </select>
                            </div> 
                            <div className="form-group col-2">
                            </div>
                        </div>
                    </form>  
                }
                </Modal.Body>
                <Modal.Footer> 

                <Button variant="secondary" onClick={handleClose}>
                    {t('default:cancel', "Annuler")}
                </Button>

                <Button variant="primary" onClick={() => createManualEntityClickHandler()}  >
                    {t('default:send', "Envoyer")}
                </Button> 
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ManualEntityCreationModal
