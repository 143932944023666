import React from 'react'
import SignatureCard from './SignatureCard/SignatureCard'
import './Signatures.css'

function Signatures() {

    let signataires = [
        {name: "Isabelle Dont", company: "Groupe Carrefour ", siret: "12124611656521", address: "12, rue des petits commerçants"},
        {name: "John Doe", company: "Bluesky", siret: "12124611656521", address: "12, rue des petits commerçants"},
        {name: "Carole Burnout", company: "Bluesky ", siret: "12124611656521", address: "12, rue des petits commerçants"},
    ]
    return (
        <div className="homeRegieBodyAdm__signaturesContainer">
            <div className="homeRegieSignatures__header">
                <h6>Signatures</h6>
            </div>
            <div className="homeRegieSignature__list">
                    {
                        signataires.map(signataire => (
                            <SignatureCard 
                                name={signataire.name} 
                                company={signataire.company} 
                                siret={signataire.siret}
                                address={signataire.address}
                            />
                        ))
                    } 
                    
            </div>
        </div>
    )
}

export default Signatures
