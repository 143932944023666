import React, { useCallback, useEffect, useState } from 'react' 
// import '../../../../containers/MasterData/MasterData.css'; 
import './MCompanyForm.css';

import Axios from '../../../../axios-edipub'; 
import iconEntity from '../../../../assets/images/icon-entity.png'  
import { useTranslation } from 'react-i18next';
import TooltipInfo from '../../../UI/TooltipInfo/TooltipInfo'
import IdentificationNumModal from './Modals/IdentificationNumModal/IdentificationNumModal'
import { checkValidity, getNotyfObject, uuidv4, CTooltip} from '../../../../shared/utility'  
import CloseIcon from '@material-ui/icons/Close';   
import countries from '../../../../assets/countries.json'  
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FlipToFrontOutlinedIcon from '@material-ui/icons/FlipToFrontOutlined';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { CropPortrait } from '@material-ui/icons';

// TODO
        // -lorsque le mandantaire clique pour intégrer l'entité
            // afficher message  derreur si identification data existe deja

function MCompanyForm({validEntityData, setValidEntityData, loading, entity, setEntity, moveNext, addEntity, validateEntity, disableEntity, updateEntity, cancelEntity, deleteEntity, role, authUserId,  updateEntityWithApi,isAuth}) 
{
    const {t, i18n} = useTranslation(); 
    let notyf = getNotyfObject() 
    const [idNumModalShow, setIdNumModalShow] = useState(false)
    const [selectedIdentNumber , setSelectedIdentNumber] = useState(null);
    const [errors, setErrors] = useState(null); 
    const [handleFormChanged, setHandleFormChanged] = useState(false); 
    const [countriesLocal, setCountriesLocal] = useState(null); 
    const [siretError, setSiretError] = useState(null); 
    const [copiedAdress, setCopiedAdress]= useState();

    const entityInputChangeHandler = (field, value) => { 
        let entityTemp = {
            ...entity, 
            [field] : value
        }
        setErrors([])
        setEntity(entityTemp) 
        setHandleFormChanged(true);
    }

    useEffect(() => {
        if( (i18n.language || 'fr') == 'fr')
            setCountriesLocal(countries.sort(function(a, b){return  ((a.name > b.name) ? 1 : -1)}))
        else 
            setCountriesLocal(countries.sort(function(a, b){return  ((a.nameEn > b.nameEn) ? 1 : -1)}))
    }, [])
 
    useEffect(() => {
        if(isAuth){ 
            if (entity?.entity_number_siret && entity?.entity_number_siret != ''){ 
                Axios.get('identtificationNumber//checkExistIdent',{
                    params: {
                        type: 'SIRET',
                        number: entity.entity_number_siret.trim()
                    }
                }).then(response => {
                    //le siret existe deja 
                    if(response.data.existIdent == true){
                        if(!entity?.targetentity || entity?.oldSiret == null  || entity.entity_number_siret.trim()!=entity?.oldSiret ){ 
                            // si création d'un nouvel annonceur
                            // ou enrichissement d'un annonceur existant
                                    // n'ayant pas de siret 
                                    // ayant une siret que l'annonceur souhaite modifier
                            setSiretError([t("default:identif_number_already_exist")]); 
                        }else{
                            //enrichissement d'un annnonceur ayant d'un siret avec son propre siret 
                            setSiretError(null);  
                        }
                    }else {
                        setSiretError(null);
                    }
                })
            }else{
                setSiretError(null);
            }  
        } 
    }, [entity?.entity_number_siret])  

    const publicServiceClickHandler  = (value) => { 
        let entityTemp = null 
        if(value){
            if(entity.serviceCodes) {
                console.log("  public service  ici 1 ")
                let scsTemp = JSON.parse(JSON.stringify(entity.serviceCodes))
                for (let i = 0; i < scsTemp?.length; i++) {
                    if((scsTemp[i].service_code === null || scsTemp[i].service_code.length === 0) && scsTemp[i].service_code_default_value !== null)                                
                        scsTemp[i].service_code = scsTemp[i].service_code_default_value
                } 
                entityTemp = {
                    ...entity, 
                    ["entity_public_service"] : value,
                    ["serviceCodes"] : scsTemp
                }    
                console.log(entityTemp)
                setEntity(entityTemp) 
                setHandleFormChanged(true);
            } else {
                entityTemp = {
                    ...entity, 
                    ["entity_public_service"] : value,
                }
                let scsTemp = null; 
                console.log("appel public service  serviceCodes") 
                setEntity(entityTemp) 
                setHandleFormChanged(true); 
            }
        }else {
            console.log("  public service  ici 3 ")
            entityTemp = {
                ...entity, 
                ["entity_public_service"] : value,
                ["serviceCodes"] : null
            }
            setEntity(entityTemp)
            setHandleFormChanged(true);
        }
    }

    const addIdNumIconClick = () => {
        setSelectedIdentNumber(null);
        setIdNumModalShow(true);
    }

    const selectIdentNumHandler = (identNum) => {
        let identNumTemp = {...identNum}
        setSelectedIdentNumber(identNumTemp);
        setIdNumModalShow(true);
    }

    const addIdentNumHandler = (identNum) => { 
        let entityTemp = {...entity}; 
        switch(identNum.type){
            case 'SIRET':
                if (entity?.entity_number_siret && entity?.entity_number_siret.length > 0) 
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else    
                    entityTemp.entity_number_siret  = identNum.number   
                break;
            case 'SIREN':
                if (entity?.entity_number_siren && entity?.entity_number_siren.length > 0)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else    
                    entityTemp.entity_number_siren  = identNum.number   
                break;
            case 'TVA':
                if (entity?.entity_number_tva && entity?.entity_number_tva.length > 0)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else    
                    entityTemp.entity_number_tva  = identNum.number   
                break;
            case 'GLN':
                if (entity?.entity_number_gln && entity?.entity_number_gln.length > 0)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else    
                    entityTemp.entity_number_gln  = identNum.number   
                break;
            case 'DUNS':
                if (entity?.entity_number_duns && entity?.entity_number_duns.length > 0)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else    
                    entityTemp.entity_number_duns  = identNum.number   
                break;
            case 'AUT':
                if (entity?.entity_number_aut && entity?.entity_number_aut.length > 0)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else    
                    entityTemp.entity_number_aut  = identNum.number   
                break;  
            default:
        }  
        setEntity(entityTemp)
        setIdNumModalShow(false);
        setHandleFormChanged(true);
    }

    const updateIdentNumHandler = (identNum) => {
        let oldIndexToDelete

        let entityTemp = {...entity}; 
        switch(identNum.type){
            case 'SIRET':  
                if (entity?.entity_number_siret && entity?.entity_number_siret.length > 0 && identNum.index != identNum.type)   
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else  { 
                    entityTemp.entity_number_siret  = identNum.number   
                    if(identNum.index != identNum.type)
                        oldIndexToDelete = true
                }  
                break;
            case 'SIREN':
                if (entity?.entity_number_siren && entity?.entity_number_siren.length > 0  && identNum.index != identNum.type)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else   {
                    entityTemp.entity_number_siren  = identNum.number   
                    if(identNum.index != identNum.type)
                        oldIndexToDelete = true  
                }
                break;
            case 'TVA':
                if (entity?.entity_number_tva && entity?.entity_number_tva.length > 0  && identNum.index != identNum.type)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else {
                    entityTemp.entity_number_tva  = identNum.number   
                    if(identNum.index != identNum.type)
                        oldIndexToDelete = true  
                }
                break;
            case 'GLN':
                if (entity?.entity_number_gln && entity?.entity_number_gln.length > 0  && identNum.index != identNum.type)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else  {
                    entityTemp.entity_number_gln  = identNum.number   
                    if(identNum.index != identNum.type)
                        oldIndexToDelete = true 
                }  
                break;
            case 'DUNS':
                if (entity?.entity_number_duns && entity?.entity_number_duns.length > 0  && identNum.index != identNum.type)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else{
                    entityTemp.entity_number_duns  = identNum.number   
                    if(identNum.index != identNum.type)
                        oldIndexToDelete = true
                }   
                break;
            case 'AUT':
                if (entity?.entity_number_aut && entity?.entity_number_aut.length > 0  && identNum.index != identNum.type)
                    notyf.error(t("masterData:identificationNumberAlreadyExist"));
                else{
                    entityTemp.entity_number_aut  = identNum.number   
                    if(identNum.index != identNum.type)
                        oldIndexToDelete = true
                }    
                break;  
            default:
        }  
        if(oldIndexToDelete){ 
            switch( identNum.index ){
                case 'SIRET': 
                        delete entityTemp.entity_number_siret ;  
                    break;
                case 'SIREN':
                        delete entityTemp.entity_number_siren;    
                    break;
                case 'TVA':
                    delete entityTemp.entity_number_tva;     
                    break;
                case 'GLN':
                    delete entityTemp.entity_number_gln;        
                    break;
                case 'DUNS':
                    delete entityTemp.entity_number_duns;    
                    break;
                case 'AUT':
                    delete entityTemp.entity_number_aut;      
                    break;  
                default:
            }   
        }

        setEntity(entityTemp)
        setIdNumModalShow(false);
        setHandleFormChanged(true);
    }

    const deleteIdentNumHandler = (type) => {
        let entityTemp = {...entity}; 
        switch(type){
            case 'SIRET': 
                delete entityTemp.entity_number_siret ;  
                break;
            case 'SIREN':
                delete entityTemp.entity_number_siren;    
                break;
            case 'TVA':
                delete entityTemp.entity_number_tva;     
                break;
            case 'GLN':
                delete entityTemp.entity_number_gln;        
                break;
            case 'DUNS':
                delete entityTemp.entity_number_duns;    
                break;
            case 'AUT':
                delete entityTemp.entity_number_aut;      
                break;  
            default:
        }  
        setEntity(entityTemp)
        setHandleFormChanged(true);
    }

    const verifyCopiedElement =()=>{ 
        if (copiedAdress && entity && 
            (copiedAdress.corporate_name == entity?.entity_address_hq_corporate_name) &&
            (copiedAdress.address_line == entity?.entity_address_hq_address_line) &&
            (copiedAdress.city_cedex == entity?.entity_address_hq_city_cedex)  &&
            (copiedAdress.distribution_service == entity?.entity_address_hq_distribution_service)  &&
            (copiedAdress.email == entity?.entity_address_hq_email)  &&
            (copiedAdress.iso_country == entity?.entity_address_hq_iso_country)  && 
            (copiedAdress.label_primary == entity?.entity_address_hq_label_primary)  && 
            (copiedAdress.label_secondary == entity?.entity_address_hq_label_secondary)  && 
            (copiedAdress.post_code == entity?.entity_address_hq_post_code)) {  
            return true 
        }else{ 
            return false 
        }
    }  
   
    const setCopiedAdressClickHandler =()=>{ 
        let copiedAdressTmp = [];
        copiedAdressTmp.corporate_name = entity?.entity_address_hq_corporate_name;
        copiedAdressTmp.address_line = entity?.entity_address_hq_address_line
        copiedAdressTmp.city_cedex = entity?.entity_address_hq_city_cedex
        copiedAdressTmp.distribution_service = entity?.entity_address_hq_distribution_service
        copiedAdressTmp.email = entity?.entity_address_hq_email
        copiedAdressTmp.iso_country = entity?.entity_address_hq_iso_country
        copiedAdressTmp.label_primary = entity?.entity_address_hq_label_primary
        copiedAdressTmp.label_secondary = entity?.entity_address_hq_label_secondary
        copiedAdressTmp.post_code = entity?.entity_address_hq_post_code
        setCopiedAdress(copiedAdressTmp);
    }  
 
    const copierAddressHandler = (addressType, address) => { 
        if(address){ 
            let entityTemp = {
                ...entity, 
                ["entity_" + addressType + "_corporate_name"] : address.corporate_name,
                ["entity_" + addressType + "_address_line"] : address.address_line,
                ["entity_" + addressType + "_city_cedex"] : address.city_cedex,
                ["entity_" + addressType + "_distribution_service"] : address.distribution_service,
                ["entity_" + addressType + "_email"] : address.email,
                ["entity_" + addressType + "_iso_country"] :address?.iso_country ? address.iso_country : "FR",
                ["entity_" + addressType + "_label_primary"] : address.label_primary,
                ["entity_" + addressType + "_label_secondary"] : address.label_secondary,
                ["entity_" + addressType + "_post_code"] : address.post_code
            } 
            setErrors([])
            setEntity(entityTemp) 
            setHandleFormChanged(true);  
        }
    } 

    const checkIsoCodeIsUE = (isoCode) => {
        if(isoCode){
            let index = countries.findIndex(country => country.alpha2 === isoCode)
        return countries[index].isUE 
        }
        return false
    }

    const moveNextClickHandler = (e) => {
        e.preventDefault(); 
        let errorsTmp = checkEntityFormValidity(entity);
        if(errorsTmp && errorsTmp.length > 0){
            setErrors(errorsTmp)
            window.scrollTo(0, 0);           
        }else{ 
            moveNext();  
        }  
    }

    const checkAddressFormValidity = (addressType, address) => {
        let errorstr =  ''
        let errors = []
        switch( addressType ){
            case 'entity_address_hq' : 
                errorstr = t('mandat:headquarterAddress', "Adresse du siège social") + ' ' + t('manualEntity:isMandatory', "est obligatoire")   
                break  
            case 'entity_address_invoice' : 
                errorstr = t('mandat:billingAddress', "Adresse de facturation")+ ' ' + t('manualEntity:isMandatory', "est obligatoire") + '. ' +  t('manualEntity:thinkCopyPasteButtons', "Pensez à utiliser les boutons copier-coller d’adresse")
                break  
            case 'entity_address_mail' :  
                errorstr = t('mandat:sendInvAddress', "Adresse d'envoi des factures") + ' ' + t('manualEntity:isMandatory', "est obligatoire") + '. ' +  t('manualEntity:thinkCopyPasteButtons', "Pensez à utiliser les boutons copier-coller d’adresse")
                break 
            default:
        } 

        if(addressType == "entity_address_mail" && address.hasOwnProperty( addressType + '_email') && address[addressType + '_email'] !='' && address[addressType + '_email'] ) {
            if(!checkValidity(address[addressType + '_email'], {isEmail: true})){
                errors.push( errorstr + ' : ' + t("masterData:empty_email_service"));
            }
        }else{
            if(!address.hasOwnProperty(addressType + '_address_line') || !checkValidity( address[addressType + '_address_line'], {required: true})){
                errors.push( errorstr + ' : ' + t("masterData:empty_street_numberLabel")) ;
            }
            if(!address.hasOwnProperty(addressType + '_post_code') || !checkValidity(address[addressType + '_post_code'], {required: true})){
                errors.push( errorstr + ' : ' + t("masterData:empty_postal_code")) ;
            }
            if(!address.hasOwnProperty(addressType + '_city_cedex') || !checkValidity(address[addressType + '_city_cedex'], {required: true})){
                errors.push( errorstr + ' : ' + t("masterData:empty_locality_field")) ;
            }
            if(!address.hasOwnProperty(addressType + '_iso_country') || !checkValidity(address[addressType + '_iso_country'], {required: true})){
                errors.push( errorstr + ' : ' + t("masterData:empty_country_field")) ;
            }
            if(address.hasOwnProperty(addressType + '_email') && address[addressType + '_email'] !='' && address[addressType + '_email']) {
                if(!checkValidity(address[addressType + '_email'], {isEmail: true})){
                    errors.push( errorstr + ' : ' + t("masterData:empty_email_service"));
                }
            } 
        }
        // if(siretError) {
        //     console.log(t("manualEntity:errorSiretMessage"));
        //     errors.push(t("manualEntity:errorSiretMessage"));
        // }

        return errors;
    }

    const checkEntityFormValidity = (entity) => {
        let errorsTmp = []
        if(!entity.hasOwnProperty('category') || !checkValidity(entity?.category, {required: true})){
            errorsTmp.push(t("default:emptyCategorie")) ;
        }
        if(!entity.hasOwnProperty('entity_official_name') || !checkValidity(entity?.entity_official_name, {required: true})){
            errorsTmp.push( t("default:emptyCompanyNameField")) ;
        }
        if(!entity.hasOwnProperty('entity_commercial_name') || !checkValidity(entity?.entity_commercial_name, {required: true})){
            errorsTmp.push(t("default:emptyCommercialLabelField")) ;
        } 
        errorsTmp = errorsTmp.concat(checkAddressFormValidity( "entity_address_hq", entity)) 
       /* if(entity.targetaddressinvoice != null |  entity?.entity_address_invoice_corporate_name || entity?.entity_address_invoice_label_primary ||
            entity?.entity_address_invoice_label_secondary ||  entity?.entity_address_invoice_address_line || entity?.entity_address_invoice_distribution_service ||
            entity?.entity_address_invoice_post_code || entity?.entity_address_invoice_city_cedex || entity?.entity_address_invoice_email) { 
                errorsTmp = errorsTmp.concat(checkAddressFormValidity( "entity_address_invoice", entity))
        } 
        if(entity.targetaddressmail != null || entity?.entity_address_mail_corporate_name || entity?.entity_address_mail_label_primary ||
            entity?.entity_address_mail_label_secondary || entity?.entity_address_mail_address_line || entity?.entity_address_mail_distribution_service ||
            entity?.entity_address_mail_post_code || entity?.entity_address_mail_city_cedex || entity?.entity_address_mail_email) { 
                errorsTmp = errorsTmp.concat(checkAddressFormValidity( "entity_address_mail", entity))
        }*/
        
        errorsTmp = errorsTmp.concat(checkAddressFormValidity( "entity_address_invoice", entity))
        errorsTmp = errorsTmp.concat(checkAddressFormValidity( "entity_address_mail", entity))

        if((!entity.hasOwnProperty('entity_forme_juridique') || entity?.entity_forme_juridique === "" || entity?.entity_forme_juridique === null) && entity.category !== "Regie"){
            errorsTmp.push(t("default:emptyLegalForm"));
        } 
         
        if(checkIsoCodeIsUE(entity?.entity_address_hq_iso_country)){ 
            if(!entity.entity_number_tva || entity.entity_number_tva == ''){
                errorsTmp.push(t("default:EmptyVTANumber")) ;
            } 
            if(entity?.entity_address_hq_iso_country === "FR"){
                if(!entity.entity_number_siret || entity.entity_number_siret == ''){
                    errorsTmp.push(t("default:EmptySIRETNumberSIRET")) ;    
                } 
            } 
        }else {
            if(!entity.entity_number_siret || entity.entity_number_siret == '' || !entity.entity_number_siren || entity.entity_number_siren == '' ||
                !entity.entity_number_tva || entity.entity_number_tva == '' || !entity.entity_number_gln  || entity.entity_number_gln == ''  ||
                !entity.entity_number_duns || entity.entity_number_duns == '' || !entity.entity_number_aut  || entity.entity_number_aut){
                	errorsTmp.push(t("default:emptyIdentificationNumber")) ;
            }
        }
        if(entity.entity_public_service == "1"){
            if(!entity.hasOwnProperty('entity_mandating_siret') ||  entity?.entity_mandating_siret === null || !checkValidity(entity?.entity_mandating_siret, {required: true})){
                errorsTmp.push(t("default:emptyMandatingSiret")) ;
            }
        }   
        if(isAuth && entity.entity_number_siret != null){
            if(siretError){
                errorsTmp.push(t("manualEntity:errorSiretMessage"));
            } 
        }
        return errorsTmp;
    } 
    
    return ( 
        <>
	    <form>
                <div style={{padding : "20px",paddingTop : "40px", width:"100%"   }}> 
                    <span style={{fontSize: "12.0pt" }}> 
                        {t('manualEntity:introline1', "L’agence média que vous mandatez pour l’achat d’espace publicitaire utilise la plateforme marché de dématérialisation des attestations de mandat (MyMandat). A ce titre, pourriez-vous saisir ou confirmer les informations qui seront mentionnées sur les Attestations de Mandat SVP ? Il vous est demandé : ")}                        
                        <ul style = {{ paddingLeft: "20px"}}>
                            <li>
                                {t('manualEntity:introline2', "des informations identifiantes, obligatoires dans la plupart des documents contractuels, vos adresses de facturation et d’envoi de factures, à saisir obligatoirement pour fiabiliser le processus de facturation,")}                        
                            </li>
                            <li> 
                                {t('manualEntity:introline3', "vos contacts intervenant dans le processus d’approbation ou de signature électronique de l’attestation de mandat. A partir de leur adresse email, nous leur créerons un compte dans MyMandat pour consulter les attestations de mandat, les approuver et/ou signer électroniquement,")}                        
                            </li> 
                            <li>
                                {t('manualEntity:introline4', "les marques et produits si vous souhaitez qu’elles apparaissent sur les attestations de mandat (il est possible de préciser par marque / produit des adresses de facturation et d’envoi de factures différentes, le cas échéant).")}                        
                            </li>
                        </ul> 
                   </span>   
               </div>  
                <div style={{padding : "20px", width:"100%", textAlign: "center"}}>
                    <img className="rightSide_header_logo_big" src={iconEntity}></img>
                    <span  style={{fontSize: "18.0pt" }} >{t('manualEntity:entityData', "Données de l'entreprise Annonceur")}</span>     
                </div> 
                {    
                    errors && errors.length > 0 ? (
                        <div className="alert alert-danger mt-2" role="alert">
                            <ul style={{marginBottom:"0px"}}>
                                {
                                    errors.map(error => (<li key={error}>{error}</li>))
                                }
                            </ul>
                        </div>
                    ) : null
                }
                <div className="rightSide_form_container">
                    <div className="form_twoElements_container" style={{marginTop: "1rem"}}>
                        <div className="form_element_container"> 
                            <span className="form_required_asterisk">*</span>
                            {t("masterData:requiredFields", "Champs obligatoires")}
                        </div> 
                    </div>
                
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <div style={{display:"flex", flex: "90%"}}>
                            <label htmlFor="entity_official_name">{t('default:companyName', "Dénomination sociale")} <span className="form_required_asterisk">*</span></label>
                            </div>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="entity_official_name" 
                                required 
                                name="entity_official_name" 
                                value={entity?.entity_official_name || ''} 
                                onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                autoComplete="off" 
                                />
                        </div>
                        <div className="form_element_container">
                            <label htmlFor="entity_commercial_name">{t('masterData:commercialName', "Nom commercial")} <span className="form_required_asterisk">*</span></label>
                            <input 
                                type="text"    
                                className="form-control" 
                                id="entity_commercial_name" 
                                required 
                                name="entity_commercial_name"  
                                value={entity?.entity_commercial_name || ''} 
                                onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                autoComplete="off" 
                                />
                                
                        </div>
                    </div>  
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <input 
                                style={{margin:"23px 8px 0px 2px"}}
                                className="form-check-input" 
                                type="checkbox"  
                                name="entity_public_service" 
                                value="" 
                                id="entity_public_service" 
                                checked={(entity?.entity_public_service && entity?.entity_public_service == "1") ? true : false }                            
                                onChange={(e) => publicServiceClickHandler(e.target.checked)}                              
                                />
                            <label className="form-check-label" htmlFor="entity_public_service"> 
                                {t('masterData:publicService', "Annonceur service public")} 
                            </label>
                        </div> 
                    </div>  
                     
                    {                        
                        entity?.entity_public_service == "1" ? (
                            <div className="form_twoElements_container">
                                <div className="form_element_container">
                                    <div style={{display:"flex", flex: "90%"}}>
                                    <label htmlFor="entity_mandating_siret">{t('masterData:mandatingSiret', "SIRET de la structure mandante réservée au marché de régies publicitaire ")} <span className="form_required_asterisk">*</span></label>
                                    </div>
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        id="entity_mandating_siret" 
                                        required 
                                        name="entity_mandating_siret" 
                                        value={entity?.entity_mandating_siret || ''} 
                                        onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                        autoComplete="off" 
                                        />
                                </div>
                                { <div className="form_element_container">
                                    <label htmlFor="entity_engagement_number">{t('masterData:engagementNumber', "N° d’engagement juridique")}</label>
                                    <input 
                                        type="text"    
                                        className="form-control" 
                                        id="entity_engagement_number" 
                                        required 
                                        name="entity_engagement_number"  
                                        value={entity?.entity_engagement_number || ''} 
                                        onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                        autoComplete="off" 
                                        />
                                        
                                </div> }
                            </div>
                            
                        ) : null 
                           
                    }
                   
                    <div className="form_twoElements_container">  
                        <div className="form_element_container">
                        <div style={{display:"flex"}}>
                            <label style={{margin: "5px", flex: "90%", display: "flex", alignItems: "baseline"}}>
                                {t('masterData:identNumbers', "Numéros d'identification")} <span className="form_required_asterisk" style={{marginRight: '0', marginLeft: "4px"}}>*</span>
                                <TooltipInfo
                                    content={t('masterData:identificationNumberInfo', "Numéros d'identification obligatoires :\n- France : N° de TVA intracommunautaire et SIRET ou SIREN\n- Union européenne : N° de TVA intracommunautaire\n- Non Union européenne : AUT")}
                                    dropdownZindex={100}
                                />
                                <i className="fas fa-plus addEntities_icon" onClick={() => addIdNumIconClick()} ></i>  
                                {   
                                idNumModalShow ?  
                                    <IdentificationNumModal
                                        handleClose = {() => setIdNumModalShow(false)}
                                        show = {idNumModalShow}
                                        identNum = {selectedIdentNumber}
                                        addIdentNum = {addIdentNumHandler}
                                        updateIdentNum = {updateIdentNumHandler}
                                    /> : "" 
                                }
                            </label> 
                            </div>
                            
                            <div className="form_company_pillsElements_container">
                                <>
                                {
                                    entity?.entity_number_siret && entity?.entity_number_siret.length > 0 ? (
                                        <div 
                                            className="form_pillElement_container" 
                                            key={"entity_number_siret_pill"}
                                            >
                                                <div className="pillElement_infos" style={{cursor: "pointer"}} onClick={() => selectIdentNumHandler({'type':'SIRET', 'number':entity.entity_number_siret, 'index':'SIRET'})  }>
                                                    <img className="pillElement_icon"src={iconEntity} style={{marginRight:'4px'}}></img>
                                                    <div className="pillElement_label">SIRET : {entity.entity_number_siret}</div>
                                                </div>  
                                                <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteIdentNumHandler('SIRET')} />
                                        </div>
                                    ) : null 
                                }   
                                {
                                     entity?.entity_number_siren && entity?.entity_number_siren.length > 0 ? (
                                        <div 
                                            className="form_pillElement_container" 
                                            key={"entity_number_siren_pill"}
                                            >
                                                <div className="pillElement_infos" style={{cursor: "pointer"}} onClick={() => selectIdentNumHandler({ 'type':'SIREN', 'number' :  entity.entity_number_siren, 'index':'SIREN' })  }>
                                                    <img className="pillElement_icon"src={iconEntity} style={{marginRight:'4px'}}></img>
                                                    <div className="pillElement_label">SIREN:  {entity.entity_number_siren}</div>
                                                </div>  
                                                <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteIdentNumHandler('SIREN')} />
                                        </div>
                                    ) : null 
                                }  
                                {
                                    entity?.entity_number_tva && entity?.entity_number_tva.length > 0 ? (
                                       <div 
                                           className="form_pillElement_container" 
                                           key={"entity_number_siren_pill"}
                                           >
                                               <div className="pillElement_infos" style={{cursor: "pointer"}} onClick={() => selectIdentNumHandler({ 'type':'TVA', 'number' :  entity.entity_number_tva, 'index':'TVA' })  }>
                                                   <img className="pillElement_icon"src={iconEntity} style={{marginRight:'4px'}}></img>
                                                   <div className="pillElement_label">TVA:  {entity.entity_number_tva}</div>
                                               </div>  
                                               <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteIdentNumHandler('TVA')} />
                                       </div>
                                   ) : null 
                                }  
                                {
                                    entity?.entity_number_gln && entity?.entity_number_gln.length > 0 ? (
                                       <div 
                                           className="form_pillElement_container" 
                                           key={"entity_number_siren_pill"}
                                           >
                                               <div className="pillElement_infos" style={{cursor: "pointer"}} onClick={() => selectIdentNumHandler({ 'type':'GLN', 'number' :  entity.entity_number_gln, 'index':'GLN' })  }>
                                                   <img className="pillElement_icon"src={iconEntity} style={{marginRight:'4px'}}></img>
                                                   <div className="pillElement_label">GLN:  {entity.entity_number_gln}</div>
                                               </div>  
                                               <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteIdentNumHandler('GLN')} />
                                       </div>
                                   ) : null 
                                }         
                                {
                                    entity?.entity_number_duns && entity?.entity_number_duns.length > 0 ? (
                                       <div 
                                           className="form_pillElement_container" 
                                           key={"entity_number_siren_pill"}
                                           >
                                               <div className="pillElement_infos" style={{cursor: "pointer"}} onClick={() => selectIdentNumHandler({ 'type':'DUNS', 'number' :  entity.entity_number_duns, 'index':'DUNS' })  }>
                                                   <img className="pillElement_icon"src={iconEntity} style={{marginRight:'4px'}}></img>
                                                   <div className="pillElement_label">DUNS:  {entity.entity_number_duns}</div>
                                               </div>  
                                               <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteIdentNumHandler('DUNS')} />
                                       </div>
                                   ) : null 
                                }         
                                {
                                    entity?.entity_number_aut && entity?.entity_number_aut.length > 0 ? (
                                       <div 
                                           className="form_pillElement_container" 
                                           key={"entity_number_siren_pill"}
                                           >
                                               <div className="pillElement_infos" style={{cursor: "pointer"}} onClick={() => selectIdentNumHandler({ 'type':'AUT', 'number' :  entity.entity_number_aut, 'index':'DUNS' })  }>
                                                   <img className="pillElement_icon"src={iconEntity} style={{marginRight:'4px'}}></img>
                                                   <div className="pillElement_label">AUT:  {entity.entity_number_aut}</div>
                                               </div>  
                                               <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteIdentNumHandler('AUT')} />
                                       </div>
                                   ) : null 
                                }         
                                </>
                            </div> 
                        </div>                         
                    </div>
                </div>
               
                <div className="form_twoElements_container">
                    <div className="form_element_container">
                        <div style={{display:"flex"}}> 
                          
                            <label style={{margin:"5px", flex: "90%", display: "flex", alignItems: "baseline"}}>
                                {t('masterData:legalForm', "Forme juridique")} 
                                <span className="form_required_asterisk" style={{marginRight: '0', marginLeft: "4px"}}>*</span>
                                <TooltipInfo
                                    content={t('masterData:legalFormInfo', "Table de référence INSEE Niveau II https://www.insee.fr/fr/information/2028129")}
                                    dropdownZindex={100}
                                /> 
                            </label> 
                        </div>   
                        <select className="form-control" 
                                id="entity_forme_juridique" 
                                name="entity_forme_juridique"  
                                value={entity?.entity_forme_juridique || ''} 
                                onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                autoComplete="off"
                                >                                  
                            <option value=""></option>
                            <option value="_00">Organisme de placement collectif en valeurs mobilières sans personnalité morale (00)</option>
                            <option value="_10">Entrepreneur individuel (10)</option>
                            <option value="_21">Indivision (21)</option>
                            <option value="_22">Société créée de fait (22)</option>
                            <option value="_23">Société en participation (23)</option>
                            <option value="_24">Fiducie (24)</option>
                            <option value="_27">Paroisse hors zone concordataire (27)</option>
                            <option value="_28">Assujetti unique à la TVA (28)</option>
                            <option value="_29">Autre groupement de droit privé non doté de la personnalité morale (29)</option>
                            <option value="_31">Personne morale de droit étranger, immatriculée au RCS (registre du commerce et des sociétés) (31)</option>
                            <option value="_32">Personne morale de droit étranger, non immatriculée au RCS (32)</option>
                            <option value="_41">Etablissement public ou régie à caractère industriel ou commercial (41)</option>
                            <option value="_51">Société coopérative commerciale particulière (51)</option>
                            <option value="_52">Société en nom collectif (52)</option>
                            <option value="_53">Société en commandite (53)</option>
                            <option value="_54">Société à responsabilité limitée (SARL) (54)</option>
                            <option value="_55">Société anonyme à conseil d'administration (55)</option>
                            <option value="_56">Société anonyme à directoire (56)</option>
                            <option value="_57">Société par actions simplifiée (57)</option>
                            <option value="_58">Société européenne (58)</option>
                            <option value="_61">Caisse d'épargne et de prévoyance (61)</option>
                            <option value="_62">Groupement d'intérêt économique (62)</option>
                            <option value="_63">Société coopérative agricole (63)</option>
                            <option value="_64">Société d'assurance mutuelle (64)</option>
                            <option value="_65">Société civile (65)</option>
                            <option value="_69">Autre personne morale de droit privé inscrite au registre du commerce et des sociétés (69)</option>
                            <option value="_71">Administration de l'état (71)</option>
                            <option value="_72">Collectivité territoriale (72)</option>
                            <option value="_73">Etablissement public administratif (73)</option>
                            <option value="_74">Autre personne morale de droit public administratif (74)</option>
                            <option value="_81">Organisme gérant un régime de protection sociale à adhésion obligatoire (81)</option>
                            <option value="_82">Organisme mutualiste (82)</option>
                            <option value="_83">Comité d'entreprise (83)</option>
                            <option value="_84">Organisme professionnel (84)</option>
                            <option value="_85">Organisme de retraite à adhésion non obligatoire (85)</option>
                            <option value="_91">Syndicat de propriétaires (91)</option>
                            <option value="_92">Association loi 1901 ou assimilé (92)</option>
                            <option value="_93">Fondation (93)</option>
                            <option value="_99">{t("masterData:other")} (99)</option>    
                        </select>
                        
                    </div>
                    {
                        entity?.entity_address_hq_iso_country == "FR" ? 
                        <div className="form_element_container">
                            <div style={{display:"flex"}}> 
                                <label style={{margin:"5px", flex: "90%"}}>{t("masterData:APECode")}</label>                             
                            </div> 
                            <input 
                                type="text"    
                                className="form-control" 
                                id="entity_code_ape" 
                                name="entity_code_ape"  
                                value={entity?.entity_code_ape || ''} 
                                onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                autoComplete="off"
                                />
                        </div>
                        : ""
                    }
                    
                </div>
              
          
                <div className="form_twoElements_container">
                    {
                        entity?.entity_address_hq_iso_country == "FR" ? 
                        <>
                            <div className="form_element_container">
                            <div style={{display:"flex"}}> 
                                <label style={{margin:"5px", flex: "90%"}}>{t('masterData:shareCapital', "Capital social")} </label>  
                            </div> 
                                <input 
                                    type="number"  
                                    className="form-control" 
                                    id="entity_capital_social" 
                                    name="entity_capital_social"  
                                    value={entity?.entity_capital_social || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    />
                            </div>
                            <div className="form_element_container">
                            <div style={{display:"flex"}}>
                                <label style={{margin:"5px", flex: "90%"}}>RCS</label>  
                            </div> 
                                <input 
                                    type="text"    
                                    className="form-control" 
                                    id="entity_rcs" 
                                    name="entity_rcs"  
                                    value={entity?.entity_rcs || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off"
                                    />
                            </div>
                        </>
                        : "" 
                    }   
                </div>  
                <div style={{marginTop:"20px"}}> 
                    <div className="form_manual_entity_container"> 
                        <div style={{padding : "20px", width:"100%", textAlign: "center"}}>   
                            {(entity?.entity_address_hq_address_line && entity?.entity_address_hq_address_line != "" && 
                            entity?.entity_address_hq_city_cedex && entity?.entity_address_hq_city_cedex != "" && 
                            entity?.entity_address_hq_post_code && entity?.entity_address_hq_post_code != "" && 
                            entity?.entity_address_hq_iso_country && entity?.entity_address_hq_iso_country != "")
                                ? 
                                <CTooltip title={t(`masterData:${ verifyCopiedElement() ? "copiedAddress" : "copyAddress"}`)}>
                                    {   verifyCopiedElement() ?  
                                        <FileCopyIcon style={{ cursor: "pointer",color: "#767675",width:"16px"}} fontSize="small" onClick={()=> setCopiedAdressClickHandler()}/>       
                                        :  
                                        <FileCopyOutlinedIcon style={{ cursor: "pointer",color: "#767675",width:"16px"}} fontSize="small" onClick={()=> setCopiedAdressClickHandler()}/>       
                                    }
                                </CTooltip> 
                                :  <>&nbsp; &nbsp;&nbsp;&nbsp;</>
                            }
                             <>&nbsp; &nbsp;&nbsp;&nbsp;</>

                            <img className="rightSide_header_logo" src={iconEntity}></img>
                            <span  style={{fontSize: "10.0pt" }} >{t('mandat:headquarterAddress', "Adresse du siège social")}</span><span className="form_required_asterisk">&nbsp;*</span>     
                        </div>  
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:corporatName', "Dénomination sociale")} </label> 
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_hq_corporate_name" 
                                    name="entity_address_hq_corporate_name" 
                                    value={entity?.entity_address_hq_corporate_name || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="identity">{t('masterData:identityService', "Service ou identité du destinataire")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_hq_label_primary" 
                                    name="entity_address_hq_label_primary" 
                                    value={entity?.entity_address_hq_label_primary || ''}
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}  
                                    autoComplete="off" />
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:addressComplement', "Complément de localisation de l'adresse")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_hq_label_secondary"
                                    name="entity_address_hq_label_secondary" 
                                    value={entity?.entity_address_hq_label_secondary  || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="">{t('masterData:street', "Numéro et libellé de voie")} </label>
                                <span className="form_required_asterisk"> *</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_hq_address_line" 
                                    name="entity_address_hq_address_line" 
                                    value={entity?.entity_address_hq_address_line || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:distributionService', "Service de distribution")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_hq_distribution_service" 
                                    name="entity_address_hq_distribution_service" 
                                    value={entity?.entity_address_hq_distribution_service || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="">{t('masterData:postalCode', "Code postal")} </label>
                                <span className="form_required_asterisk"> *</span> 
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_hq_post_code" 
                                    name="entity_address_hq_post_code" 
                                    value={entity?.entity_address_hq_post_code || ''}  
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="entity_address_hq_city_cedex">{t('masterData:localisationCedex', "Localité / Cedex")} </label>
                                <span className="form_required_asterisk"> *</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_hq_city_cedex" 
                                    name="entity_address_hq_city_cedex" 
                                    value={entity?.entity_address_hq_city_cedex || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="Pays">{t('masterData:country', "Pays")} </label>
                                <select style={{height: '33px'}} class="form-control" name="entity_address_hq_iso_country" value={entity?.entity_address_hq_iso_country ||  'FR'} onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} aria-label=".form-select-lg example">
                                    {
                                        countriesLocal?.map(country => (
                                            <option key={country.alpha2} value={country.alpha2}>{ ((i18n.language || 'fr') === 'fr' ? country.name : country.nameEn)}</option>
                                        ))
                                    }
                                </select>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:serviceEmail', "Email du service")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_hq_email" 
                                    name="entity_address_hq_email" 
                                    value={entity?.entity_address_hq_email || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div> 
                        </div>
                    </div>
                </div> 

                <div style={{marginTop:"20px"}}>    
                    <div className="form_manual_entity_container">  
                        <div style={{padding : "20px", width:"100%", textAlign: "center"}}>  
                            <CTooltip title={t("masterData:pasteAddress")} >
                                <FlipToFrontOutlinedIcon style={{cursor: "pointer",color: "#2d2f30",width:"16px"  }} fontSize="small"  onClick={() => copierAddressHandler("address_invoice", copiedAdress)}/>
                            </CTooltip>
                            <>&nbsp; &nbsp;&nbsp;&nbsp;</>
                            <img className="rightSide_header_logo" src={iconEntity}></img>
                            <span  style={{fontSize: "10.0pt" }} >   {t('mandat:billingAddress', "Adresse de facturation")}</span>     
                        </div> 
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:corporatName', "Dénomination sociale")} </label> 
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_invoice_corporate_name" 
                                    name="entity_address_invoice_corporate_name" 
                                    value={entity?.entity_address_invoice_corporate_name || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="identity">{t('masterData:identityService', "Service ou identité du destinataire")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_invoice_label_primary" 
                                    name="entity_address_invoice_label_primary" 
                                    value={entity?.entity_address_invoice_label_primary || ''}
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}  
                                    autoComplete="off" />
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:addressComplement', "Complément de localisation de l'adresse")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_invoice_label_secondary"
                                    name="entity_address_invoice_label_secondary" 
                                    value={entity?.entity_address_invoice_label_secondary  || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="">{t('masterData:street', "Numéro et libellé de voie")} </label>
                                <span className="form_required_asterisk"> *</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_invoice_address_line" 
                                    name="entity_address_invoice_address_line" 
                                    value={entity?.entity_address_invoice_address_line || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:distributionService', "Service de distribution")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_invoice_distribution_service" 
                                    name="entity_address_invoice_distribution_service" 
                                    value={entity?.entity_address_invoice_distribution_service || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="">{t('masterData:postalCode', "Code postal")} </label>
                                <span className="form_required_asterisk"> *</span> 
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_invoice_post_code" 
                                    name="entity_address_invoice_post_code" 
                                    value={entity?.entity_address_invoice_post_code || ''}  
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="entity_address_invoice_city_cedex">{t('masterData:localisationCedex', "Localité / Cedex")} </label>
                                <span className="form_required_asterisk"> *</span>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_invoice_city_cedex" 
                                    name="entity_address_invoice_city_cedex" 
                                    value={entity?.entity_address_invoice_city_cedex || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="Pays">{t('masterData:country', "Pays")} </label>
                                <select style={{height: '33px'}} class="form-control" name="entity_address_invoice_iso_country" value={entity?.entity_address_invoice_iso_country ||  'FR'} onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} aria-label=".form-select-lg example">
                                    {
                                        countriesLocal?.map(country => (
                                            <option key={country.alpha2} value={country.alpha2}>{ ((i18n.language || 'fr') === 'fr' ? country.name : country.nameEn)}</option>
                                        ))
                                    }
                                </select>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:serviceEmail', "Email du service")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_invoice_email" 
                                    name="entity_address_invoice_email" 
                                    value={entity?.entity_address_invoice_email || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div> 
                        </div>
                    </div>
                </div>


                <div style={{marginTop:"20px"}}> 
                    <div className="form_manual_entity_container">  
                        <div style={{padding : "20px", width:"100%", textAlign: "center"}}>  
                            <CTooltip title={t("masterData:pasteAddress")} >
                                <FlipToFrontOutlinedIcon style={{cursor: "pointer",color: "#2d2f30",width:"16px"  }} fontSize="small"  onClick={() => copierAddressHandler("address_mail", copiedAdress)}/>
                            </CTooltip>
                            <>&nbsp; &nbsp;&nbsp;&nbsp;</>  
                            <img className="rightSide_header_logo" src={iconEntity}></img>
                            <span  style={{fontSize: "10.0pt" }} >{t('masterData:sendInvAddressOrOnlyEmail',"Adresse ou simplement email d'envoi des factures")}</span>   
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:corporatName', "Dénomination sociale")} </label> 
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_mail_corporate_name" 
                                    name="entity_address_mail_corporate_name" 
                                    value={entity?.entity_address_mail_corporate_name || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="identity">{t('masterData:identityService', "Service ou identité du destinataire")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_mail_label_primary" 
                                    name="entity_address_mail_label_primary" 
                                    value={entity?.entity_address_mail_label_primary || ''}
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}  
                                    autoComplete="off" />
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:addressComplement', "Complément de localisation de l'adresse")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_mail_label_secondary"
                                    name="entity_address_mail_label_secondary" 
                                    value={entity?.entity_address_mail_label_secondary  || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} 
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="">{t('masterData:street', "Numéro et libellé de voie")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_mail_address_line" 
                                    name="entity_address_mail_address_line" 
                                    value={entity?.entity_address_mail_address_line || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:distributionService', "Service de distribution")} </label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_mail_distribution_service" 
                                    name="entity_address_mail_distribution_service" 
                                    value={entity?.entity_address_mail_distribution_service || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="">{t('masterData:postalCode', "Code postal")} </label> 
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_mail_post_code" 
                                    name="entity_address_mail_post_code" 
                                    value={entity?.entity_address_mail_post_code || ''}  
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="entity_address_mail_city_cedex">{t('masterData:localisationCedex', "Localité / Cedex")} </label> 
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_mail_city_cedex" 
                                    name="entity_address_mail_city_cedex" 
                                    value={entity?.entity_address_mail_city_cedex || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div>
                            <div className="form_element_container">
                                <label for="Pays">{t('masterData:country', "Pays")} </label>
                                <select style={{height: '33px'}} class="form-control" name="entity_address_mail_iso_country" value={entity?.entity_address_mail_iso_country ||  'FR'} onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)} aria-label=".form-select-lg example">
                                    {
                                        countriesLocal?.map(country => (
                                            <option key={country.alpha2} value={country.alpha2}>{ ((i18n.language || 'fr') === 'fr' ? country.name : country.nameEn)}</option>
                                        ))
                                    }
                                </select>
                            </div>  
                        </div>
                        <div className="form_twoElements_container">
                            <div className="form_element_container"> 
                                <label for="">{t('masterData:serviceEmail', "Email du service")} </label>
                                
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="entity_address_mail_email" 
                                    name="entity_address_mail_email" 
                                    value={entity?.entity_address_mail_email || ''} 
                                    onChange={(e) => entityInputChangeHandler(e.target.name, e.target.value)}
                                    autoComplete="off"></input>
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="rightSide_form_submit_container">       
                    <input 
                        type="submit" 
                        value={t('manualEntity:defineUsers', "Définir les contacts et signataires")}    
                        className="btn btn-primary mt-3 rightSide_form_submitBtn" 
                        onClick={(e) => moveNextClickHandler(e)} />
                </div>
            </form> 
       </>
		);
}

export default MCompanyForm
