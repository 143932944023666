import React, {useCallback, useEffect, useState } from 'react'
import './MandatEdition.css';
import ProductsModal from '../Modals/ProductsModal/ProductsModal';
import MediasModal from '../Modals/MediasModal/MediasModal';
import RegiesModal from '../Modals/RegiesModal/RegiesModal';
import SupportsModal from '../Modals/SupportsModal/SupportsModal';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as actions from '../../../../../../../store/actions/index';
import {uniqueObjsArrayByUid} from '../../../../../../../shared/utility'
import AddBoxIcon from '@material-ui/icons/AddBox';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Axios from "../../../../../../../axios-edipub";
import MarketingModeModal from '../Modals/MarketingModeModal/MarketingModeModal';
import GeographicalAreaModal from '../Modals/GeographicalAreaModal/GeographicalAreaModal';
import AddMissionModal from '../Modals/AddMissionModal/AddMissionModal';
import NoPayerMissionModal from '../Modals/NoPayerMissionModal/NoPayerMissionModal'
import { useTranslation } from 'react-i18next';

function MandatEdition(props) {
    const {
        isMandataire,
        admObject,
        admOIndex, 
        advertiserUid, 
        period, 
        updateMandatData, 
        admData, 
        updateMandatAdmObject,
        addMandatProduct, 
        deleteMandatProduct, 
        addMandatMedia, 
        deleteMandatMedia, 
        addMandatRegie, 
        deleteMandatRegie,
        addMandatSupport,
        deleteMandatSupport,
        addMandatMarketingMode,
        deleteMandatMarketingMode,
        addMandatGeographicalArea,
        deleteMandatGeographicalArea,
        updateMandatObjects
    } = props

    const {t} = useTranslation();
    const [showProducts, setShowProducts] = useState(false);
    const [showMedias, setShowMedias] = useState(false);
    const [showRegies, setShowRegies] = useState(false);
    const [showSupports, setShowSupports] = useState(false);
    const [showMarketingModes, setShowMarketingModes] = useState(false);
    const [showGeographicalAreas, setShowGeographicalAreas] = useState(false);
    const [addMissionShow, setAddMissionShow] = useState(false);
    const [showNoPayerMissionModal, setShowNoPayerMissionModal] = useState(false);
    const [noPayerMissionModalParams, setNoPayerMissionModalParams] = useState(false);


    const updateAllProductsAdmObjectHandler = (value) => {
        let admObjectsTemp = [...admData.admObjects];

        if(value == "1" && admObject?.products.length > 0 ) {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allProducts : value,
                products : []
            }
            admObject.products.map(product => deleteMandatProduct(admObject.dspUid, product))
        
        } else {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allProducts : value
            }
        }
        
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        updateMandatAdmObject(admObject.dspUid, "allProducts", value)
        
    }

    const addProductsHandler = (productsToAdd) => {
        let admObjectsTemp = [...admData.admObjects]
        let productsToAddTemp = productsToAdd.filter(product => {
            let exist = admObjectsTemp[admOIndex].products.findIndex(prod => prod.uid === product.uid) >= 0
            return !exist
        })
        let productsTemp = [...admObjectsTemp[admOIndex].products, ...productsToAddTemp]
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            products : productsTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        addMandatProduct(admObject.dspUid, productsToAddTemp)
    }
   
    const deleteProductsHandler = (productToDelete) => {
        let admObjectsTemp = [...admData.admObjects]
        let productIndex = admObjectsTemp[admOIndex].products.findIndex(prod => prod.uid === productToDelete.uid)
        let productsTemp = [...admObjectsTemp[admOIndex].products]
        productsTemp.splice(productIndex, 1)
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            products : productsTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        deleteMandatProduct(admObject.dspUid, productToDelete)
    }

    const updateAllMediasAdmObjectHandler = (value) => {
        let admObjectsTemp = [...admData.admObjects];
        if(value == "1" && admObject?.medias.length > 0 ) {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allMedias : value,
                medias : []
            }
            admObject.medias.map(media => deleteMandatMedia(admObject.dspUid, media))
        
        } else {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allMedias : value,
                allAddeps : "0",
                allSupports : "0",
                regies : [],
                hasAddeps : 0,
                supports : []
            }
            admObject.regies.map(regie => deleteMandatRegie(admObject.dspUid, regie))
            updateMandatAdmObject(admObject.dspUid, "allAddeps", "0")
            updateMandatAdmObject(admObject.dspUid, "hasAddeps", "0")
            admObject.supports.map(support => deleteMandatSupport(admObject.dspUid, support))
            updateMandatAdmObject(admObject.dspUid, "allSupports", "0")
        }
        
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        updateMandatAdmObject(admObject.dspUid, "allMedias", value)
        
    }
    
    const addMediasHandler = (mediasToAdd) => {
        let admObjectsTemp = [...admData.admObjects]
        let mediasToAddTemp = mediasToAdd.filter(media => {
            let exist = admObjectsTemp[admOIndex].medias.findIndex(row => media.uid && row.uid === media.uid || row.label === media.label) >= 0
            return !exist
        })
        let mediasTemp = [...admObjectsTemp[admOIndex].medias, ...mediasToAddTemp]
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            medias : mediasTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        addMandatMedia(admObject.dspUid, mediasToAddTemp)
    }

    const deleteMediaHandler = (mediaToDelete) => {
        let admObjectsTemp = [...admData.admObjects]
        let mediaIndex = admObjectsTemp[admOIndex].medias.findIndex(row => mediaToDelete.uid && row.uid === mediaToDelete.uid || row.label === mediaToDelete.label)
        let mediasTemp = [...admObjectsTemp[admOIndex].medias]
        mediasTemp.splice(mediaIndex, 1)
    
        //delete related regies
        let regiesTemp = [...admObjectsTemp[admOIndex].regies];
        let regiesToDelete = regiesTemp.filter(regie => {
            let relatedMedias = [...regie?.relatedMedias];
            let mediaToDelIndex = relatedMedias.findIndex(row => row.uid === mediaToDelete.uid);
            if(mediaToDelIndex >= 0) {
                relatedMedias.splice(mediaToDelIndex, 1)
                if (relatedMedias.length > 0 ){
                    let existOtherRelatedMedia = relatedMedias.filter(row => mediasTemp.findIndex(admMedia => admMedia.uid === row.uid) >= 0).length > 0 
                    if(existOtherRelatedMedia)
                        return false
                    else 
                        return true
                } else 
                    return true
            } else return false
        })

        regiesToDelete.map(regieToDelete => {
            let regieIndex = regiesTemp.findIndex(row => row.uid === regieToDelete.uid)
            regiesTemp.splice(regieIndex, 1)
            deleteMandatRegie(admObject.dspUid, regieToDelete)
        })
        
        //delete related supports
        let supportsTemp = [...admObjectsTemp[admOIndex].supports];
        let supportsToDelete = []

        if(regiesTemp.length <= 0 ){
            supportsToDelete = [...supportsTemp]
        } else {
            supportsToDelete = supportsTemp.filter(support => support?.relatedMedia === mediaToDelete.uid);
        }
        
        supportsToDelete.map(supportToDelete => {
            let supportIndex = supportsTemp.findIndex(row => row.uid === supportToDelete.uid);
            supportsTemp.splice(supportIndex, 1)
            deleteMandatSupport(admObject.dspUid, supportToDelete)
        })

        if(mediasTemp?.length <=0){
            updateMandatAdmObject(admObject.dspUid, "allAddeps", "0")
            updateMandatAdmObject(admObject.dspUid, "allSupports", "0")
        }

        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            medias : mediasTemp,
            regies : regiesTemp,
            hasAddeps : regiesTemp.length > 0,
            supports : supportsTemp,
            allAddeps : mediasTemp?.length <=0 ? "0" : admObjectsTemp[admOIndex]?.allAddeps,
            allSupports : mediasTemp?.length <=0 ? "0" : admObjectsTemp[admOIndex]?.allSupports
        }

        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }

        updateMandatData(mandatDataTemp)
        deleteMandatMedia(admObject.dspUid, mediaToDelete)
        updateMandatAdmObject(admObject.dspUid, "hasAddeps", regiesTemp.length > 0 ? "1" : "0")
    }

    const updateAllRegiesAdmObjectHandler = (value) => {
        let admObjectsTemp = [...admData.admObjects];

        if(value == "1" && admObject?.regies.length > 0 ) {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allAddeps : value,
                regies : [],
                hasAddeps : 0
            }
            admObject.regies.map(regie => deleteMandatRegie(admObject.dspUid, regie))
        
        } else {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allAddeps : value,
                allSupports : "0",
                supports : []
            }
            admObject.supports.map(support => deleteMandatSupport(admObject.dspUid, support))
            updateMandatAdmObject(admObject.dspUid, "allSupports", "0")
        }
        
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        updateMandatAdmObject(admObject.dspUid, "allAddeps", value)
        updateMandatAdmObject(admObject.dspUid, "hasAddeps", "0")
        
    }


    const addRegiesHandler = (regiesToAdd) => {
        let admObjectsTemp = [...admData.admObjects]
        let regiesToAddTemp = regiesToAdd.filter(regie => {
            let exist = admObjectsTemp[admOIndex].regies.findIndex(row => row.uid === regie.uid) >= 0
            return !exist
        })
        let regiesTemp = [...admObjectsTemp[admOIndex].regies, ...regiesToAddTemp]
        let allSupportsT = +admObjectsTemp[admOIndex]?.allSupports
        //set supports to all if we have selected regies and supports is empty
        if(regiesToAdd.length > 0 && admObjectsTemp[admOIndex]?.supports?.length === 0){
            allSupportsT = 1
        }
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            regies : regiesTemp,
            hasAddeps : 1,
            allSupports: allSupportsT
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        addMandatRegie(admObject.dspUid, regiesToAddTemp)
        updateMandatAdmObject(admObject.dspUid, "allSupports", allSupportsT)
        updateMandatAdmObject(admObject.dspUid, "hasAddeps", "1")
    }

    const deleteRegieHandler = (regieToDelete) => {
        let admObjectsTemp = [...admData.admObjects]
        let regieIndex = admObjectsTemp[admOIndex].regies.findIndex(row => row.uid === regieToDelete.uid)
        let regiesTemp = [...admObjectsTemp[admOIndex].regies]
        regiesTemp.splice(regieIndex, 1)
    
        //delete related supports
        let supportsTemp = [...admObjectsTemp[admOIndex].supports];
        let supportsToDelete = []

        if(regiesTemp.length <= 0 ){
            supportsToDelete = [...supportsTemp]
        } else {
            supportsToDelete = supportsTemp.filter(support => support?.relatedRegie === regieToDelete.uid);
        }
        
        supportsToDelete.map(supportToDelete => {
            let supportIndex = supportsTemp.findIndex(row => row.uid === supportToDelete.uid);
            supportsTemp.splice(supportIndex, 1)
            deleteMandatSupport(admObject.dspUid, supportToDelete)
        })

        if(regiesTemp?.length <=0){
            updateMandatAdmObject(admObject.dspUid, "allSupports", "0")
        }

        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            regies : regiesTemp,
            hasAddeps : regiesTemp.length > 0,
            supports : supportsTemp,
            allSupports : regiesTemp?.length <=0 ? "0" : admObjectsTemp[admOIndex]?.allSupports
        }

        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        deleteMandatRegie(admObject.dspUid, regieToDelete)
        updateMandatAdmObject(admObject.dspUid, "hasAddeps", regiesTemp.length > 0 ? "1" : "0")
    }

    const updateAllSupportsAdmObjectHandler = (value) => {
        console.log(value)
        let admObjectsTemp = [...admData.admObjects];

        if(value == "1" && admObject?.supports.length > 0 ) {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allSupports : value,
                supports : []
            }
            admObject.supports.map(support => deleteMandatSupport(admObject.dspUid, support))
        
        } else {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allSupports : value
            }
        }
        
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        
        updateMandatData(mandatDataTemp)
        updateMandatAdmObject(admObject.dspUid, "allSupports", value)
        
    }

    const addSupportsHandler = (supportsToAdd) => {
        let admObjectsTemp = [...admData.admObjects]
        let supportsToAddTemp = supportsToAdd.filter(support => {
            let exist = false
            if(support.hasOwnProperty("uid"))
                exist = admObjectsTemp[admOIndex].supports.findIndex(row => row.uid === support?.uid || row.label === support.label ) >= 0
            else
                exist = admObjectsTemp[admOIndex].supports.findIndex(row => row.label === support.label ) >= 0
            return !exist
        })
        let supportsTemp = [...admObjectsTemp[admOIndex].supports, ...supportsToAddTemp]
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            supports : supportsTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        addMandatSupport(admObject.dspUid, supportsToAddTemp)
    }

    const deleteSupportHandler = (supportToDelete) => {
        let admObjectsTemp = [...admData.admObjects]
        let supportIndex = admObjectsTemp[admOIndex].supports.findIndex(row => supportToDelete.uid && row.uid === supportToDelete.uid || row.label === supportToDelete.label)
        let supportsTemp = [...admObjectsTemp[admOIndex].supports]
        supportsTemp.splice(supportIndex, 1)
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            supports : supportsTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        deleteMandatSupport(admObject.dspUid, supportToDelete)
    }

    const updateAllMarketingmodesAdmObjectHandler = (value) => {
        let admObjectsTemp = [...admData.admObjects];

        if(value == "1" && admObject?.marketingModes.length > 0 ) {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allMarketingmodes : value,
                marketingModes : []
            }
            admObject.marketingModes.map(marketingMode => deleteMandatMarketingMode(admObject.dspUid, marketingMode))
        
        } else {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allMarketingmodes : value
            }
        }
        
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        updateMandatAdmObject(admObject.dspUid, "allMarketingmodes", value)
        
    }

    const addMarketingModesHandler = (marketingModesToAdd) => {
        let admObjectsTemp = [...admData.admObjects]
        let marketingModesToAddTemp = marketingModesToAdd.filter(marketingMode => {
            let exist = admObjectsTemp[admOIndex].marketingModes.findIndex(row => row.uid === marketingMode.uid) >= 0
            return !exist
        })
        let marketingModesTemp = [...admObjectsTemp[admOIndex].marketingModes, ...marketingModesToAddTemp]
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            marketingModes : marketingModesTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        addMandatMarketingMode(admObject.dspUid, marketingModesToAddTemp)
    }

    const deleteMarketingModeHandler = (marketingModeToDelete) => {
        let admObjectsTemp = [...admData.admObjects]
        let marketingModeIndex = admObjectsTemp[admOIndex].marketingModes.findIndex(row => row.uid === marketingModeToDelete.uid)
        let marketingModesTemp = [...admObjectsTemp[admOIndex].marketingModes]
        marketingModesTemp.splice(marketingModeIndex, 1)
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            marketingModes : marketingModesTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        deleteMandatMarketingMode(admObject.dspUid, marketingModeToDelete)
    }

    const updateAllGeographicalareasAdmObjectHandler = (value) => {
        let admObjectsTemp = [...admData.admObjects];

        if(value == "1" && admObject?.geographicalAreas.length > 0 ) {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allGeographicalareas : value,
                geographicalAreas : []
            }
            admObject.geographicalAreas.map(geographicalArea => deleteMandatGeographicalArea(admObject.dspUid, geographicalArea))
        
        } else {
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                allGeographicalareas : value
            }
        }
        
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        updateMandatAdmObject(admObject.dspUid, "allGeographicalareas", value)
        
    }

    const addGeographicalAreasHandler = (geographicalAreasToAdd) => {
        let admObjectsTemp = [...admData.admObjects]
        let geographicalAreasToAddTemp = geographicalAreasToAdd.filter(geographicalArea => {
            let exist = admObjectsTemp[admOIndex].geographicalAreas.findIndex(row => row.uid === geographicalArea.uid) >= 0
            return !exist
        })
        let geographicalAreasTemp = [...admObjectsTemp[admOIndex].geographicalAreas, ...geographicalAreasToAddTemp]
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            geographicalAreas : geographicalAreasTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        addMandatGeographicalArea(admObject.dspUid, geographicalAreasToAddTemp)
    }

    const deleteGeographicalAreaHandler = (geographicalAreaToDelete) => {
        let admObjectsTemp = [...admData.admObjects]
        let geographicalAreaIndex = admObjectsTemp[admOIndex].geographicalAreas.findIndex(row => row.uid === geographicalAreaToDelete.uid)
        let geographicalAreasTemp = [...admObjectsTemp[admOIndex].geographicalAreas]
        geographicalAreasTemp.splice(geographicalAreaIndex, 1)
    
        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            geographicalAreas : geographicalAreasTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        deleteMandatGeographicalArea(admObject.dspUid, geographicalAreaToDelete)
    }

    const updateMissionHandler = (mission, section, checked) => {
        let admObjectsTemp = [...admData.admObjects]
        let value = 'PROXY'
        if(section === "SUBPROXY")
            value = checked ? 'SUBPROXY' : null;
        else value = checked ? 'PROXY' : null; 
      
        let missionIndex = admObjectsTemp[admOIndex].missions.findIndex(row => row.uid === mission.uid)
        let missionsTemp = [...admObjectsTemp[admOIndex].missions] 
        missionsTemp[missionIndex].responsible = value; 

        admObjectsTemp[admOIndex] = {
            ...admObjectsTemp[admOIndex],
            missions : missionsTemp
        }
        let mandatDataTemp = {
            ...admData,
            admObjects : admObjectsTemp
        }
        updateMandatData(mandatDataTemp)
        let field = '_' + mission.id
        updateMandatObjects(admObject.dspUid, {field, value})
    }

    const addMissionHandler = missionContent => {
        Axios.post("entity/" + admData.mandataire.uid + "/purpose", missionContent).then(response => {
            const newMission = response.data.purpose;
            newMission.globalpurpose = "0"
            newMission.code = "_" + newMission.id
            newMission.responsible = (isMandataire ? "PROXY" : "SUBPROXY") 
            newMission.label = (admData.lang === 'FR' ? newMission.label : newMission.label_en)
            delete newMission.label_en; 
             
            let admObjectsTemp = [...admData.admObjects]
            let missionsTemp = [...admObjectsTemp[admOIndex].missions, newMission]
            admObjectsTemp[admOIndex] = {
                ...admObjectsTemp[admOIndex],
                missions : missionsTemp
            }
            let mandatDataTemp = {
                ...admData,
                admObjects : admObjectsTemp
            } 
            updateMandatData(mandatDataTemp)
          
            let field = '_' + newMission.id
            let value=  (isMandataire ? "PROXY" : "SUBPROXY")
            updateMandatObjects(admObject.dspUid, {field,value})
        }).catch(err => console.error(err.response?.data))
    }

    const onClickMissionHandler =  (mission, section, checked)  => {  
        if(admData.mandataire.payer === "1" && mission?.code && mission?.code === "PAYER" && (((section === "SUBPROXY") && !checked  ) || ((section === "PROXY") && !checked))){
            let params = {mission, section, checked}
            setNoPayerMissionModalParams (params)
            setShowNoPayerMissionModal(true)
        }else
            updateMissionHandler(mission,section, checked)
    } 

    return (
        <>
            <div className="ADMEdition_mandat_title">
                {t('mandat:missionsTitle', "Pour effectuer en notre nom et pour notre compte les missions suivantes :")} 
            </div>
            <div className="ADMEdition_mandat_body">
                <div className="ADMEdition_mandatBody_periode">
                    {t('mandat:missionPeriod', "Sur la période du")}  {period} </div>
                <div className="ADMEdition_mandatBody_sections">
                    <div className="ADMEdition_mandatBody_section">

                        <div className="ADMEdition_mandatBody_sectionTitle">
                            {t('mandat:forBrand', "Pour les marques/produits")} 
                        </div>
                        <div className="ADMEdition_mandatBody_sectionOptions">
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allProducts" + admObject.dspUid} 
                                    id={"allProducts" + admObject.dspUid}
                                    checked = {+admObject?.allProducts === 1} 
                                    value="1" 
                                    onChange={(e) =>updateAllProductsAdmObjectHandler( e.target.value)} />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"allProducts" + admObject.dspUid}>
                                    {t('default:all', "Tous")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allProducts" + admObject.dspUid}  
                                    id={"productsSelection" + admObject.dspUid}
                                    checked = {+admObject?.allProducts !== 1}
                                    value="0" 
                                    onChange={(e) =>updateAllProductsAdmObjectHandler( e.target.value)}/>
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"productsSelection" + admObject.dspUid}>
                                    {t('mandat:selection', "Une sélection")}
                                </label>
                            </div>
                        </div>
                        {
                            +admObject?.allProducts !== 1 ? (
                                <>
                                    <div className="ADMEdition_mandatBody_sectionAdd">
                                        <Button 
                                            className="btn btn-light btn-sm text-primary" 
                                            onClick={() => setShowProducts(true)}>
                                                + {t('mandat:addBrand', "Ajouter marques/produits ")} 
                                            </Button>
                                        {showProducts ? (
                                            <ProductsModal 
                                                mandataireUid={admData?.mandataire?.uid}
                                                agencyUid={admObject?.agencyUid}
                                                advertiserUid = {advertiserUid}
                                                advertiserGroup={admData?.advertiserGroup}
                                                handleClose = {() => setShowProducts(false)}
                                                show = {showProducts}
                                                addProducts = {addProductsHandler}
                                            />
                                        ) : null}
                                        
                                    </div>
                                    <div className="ADMEdition_mandatBody_sectionList">
                                        {
                                            admObject?.products.length > 0 ? admObject.products.map(product => (
                                                <div className="ADMEdition_mandatBody_sectionItem" key={product.uid}>
                                                    <div className="ADMEdition_mandatBody_sectionItemValue">{product.label} </div>
                                                    <div className="ADMEdition_mandatBody_sectionItemIcon">
                                                        <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteProductsHandler(product)} />
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                        
                                    </div>
                                </>
                            ) : null
                        }
                        
                    </div>
                    <div className="ADMEdition_mandatBody_section">
                        <div className="ADMEdition_mandatBody_sectionTitle">{t('mandat:marketingMode', "Mode de commercialisation")} </div>
                        <div className="ADMEdition_mandatBody_sectionOptions">
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allMarketingmodes" + admObject.dspUid} 
                                    id={"allMarketingmodes" + admObject.dspUid}
                                    checked = {+admObject?.allMarketingmodes === 1} 
                                    value="1" 
                                    onChange={(e) =>updateAllMarketingmodesAdmObjectHandler( e.target.value)} />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"allMarketingmodes" + admObject.dspUid}>
                                    {t('default:all', "Tous")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allMarketingmodes" + admObject.dspUid}  
                                    id={"marketingmodesSelection" + admObject.dspUid}
                                    checked = {+admObject?.allMarketingmodes !== 1}
                                    value="0" 
                                    onChange={(e) =>updateAllMarketingmodesAdmObjectHandler( e.target.value)}/>
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"marketingmodesSelection" + admObject.dspUid}>
                                    {t('mandat:selection', "Une sélection")}
                                </label>
                            </div>
                        </div>
                        {
                            +admObject?.allMarketingmodes !== 1 ? (
                                <>
                                    <div className="ADMEdition_mandatBody_sectionAdd">
                                        <Button 
                                            className="btn btn-light btn-sm text-primary" 
                                            onClick={() => setShowMarketingModes(true)}>
                                                + {t('mandat:addMarketingMode', "Ajouter des modes de commercialisation")}  
                                        </Button>
                                        {showMarketingModes ? (
                                            <MarketingModeModal 
                                                lang={admData?.lang}
                                                agencyUid={admObject?.agencyUid}
                                                handleClose = {() => setShowMarketingModes(false)}
                                                show = {showMarketingModes}
                                                addMarketingModes = {addMarketingModesHandler}
                                            />
                                        ) : null}
                                        
                                    </div>
                                    <div className="ADMEdition_mandatBody_sectionList">
                                        {
                                            admObject?.marketingModes.length > 0 ? admObject.marketingModes.map(marketingMode => (
                                                <div className="ADMEdition_mandatBody_sectionItem" key={marketingMode.uid}>
                                                    <div className="ADMEdition_mandatBody_sectionItemValue">{ admData?.lang === "FR" ? marketingMode.label: marketingMode.label_en } </div>
                                                    <div className="ADMEdition_mandatBody_sectionItemIcon">
                                                        <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteMarketingModeHandler(marketingMode)} />
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                        
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                    <div className="ADMEdition_mandatBody_section">
                        <div className="ADMEdition_mandatBody_sectionTitle">{t('mandat:geographicalZone', "Zone géographique")} </div>
                        <div className="ADMEdition_mandatBody_sectionOptions">
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allGeographicalareas" + admObject.dspUid} 
                                    id={"allGeographicalareas" + admObject.dspUid}
                                    checked = {+admObject?.allGeographicalareas === 1} 
                                    value="1" 
                                    onChange={(e) =>updateAllGeographicalareasAdmObjectHandler( e.target.value)} />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"allGeographicalareas" + admObject.dspUid}>
                                    {t('default:all', "Tous")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allGeographicalareas" + admObject.dspUid}  
                                    id={"geographicalareasSelection" + admObject.dspUid}
                                    checked = {+admObject?.allGeographicalareas !== 1}
                                    value="0" 
                                    onChange={(e) =>updateAllGeographicalareasAdmObjectHandler( e.target.value)}/>
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"geographicalareasSelection" + admObject.dspUid}>
                                    {t('mandat:selection', "Une sélection")}
                                </label>
                            </div>
                        </div>
                        {
                            +admObject?.allGeographicalareas !== 1 ? (
                                <>
                                    <div className="ADMEdition_mandatBody_sectionAdd">
                                        <Button 
                                            className="btn btn-light btn-sm text-primary" 
                                            onClick={() => setShowGeographicalAreas(true)}>
                                                + {t('mandat:addGeoZone', "Ajouter des zones géographiques")} 
                                        </Button>
                                        {showGeographicalAreas ? (
                                            <GeographicalAreaModal 
                                                lang={admData?.lang}
                                                agencyUid={admObject?.agencyUid}
                                                handleClose = {() => setShowGeographicalAreas(false)}
                                                show = {showGeographicalAreas}
                                                addGeographicalAreas = {addGeographicalAreasHandler}
                                            />
                                        ) : null}
                                    </div>
                                    <div className="ADMEdition_mandatBody_sectionList">
                                        {
                                            admObject?.geographicalAreas.length > 0 ? admObject.geographicalAreas.map(geographicalArea => (
                                                <div className="ADMEdition_mandatBody_sectionItem" key={geographicalArea.uid}>
                                                    <div className="ADMEdition_mandatBody_sectionItemValue">{admData?.lang === "FR" ? geographicalArea.label: geographicalArea.label_en } </div>
                                                    <div className="ADMEdition_mandatBody_sectionItemIcon">
                                                        <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteGeographicalAreaHandler(geographicalArea)} />
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                    </div>
                                </>
                            ) : null
                        }
                    </div>

                </div>
                <div className="ADMEdition_mandatBody_sections">
                    <div className="ADMEdition_mandatBody_section">
                        <div className="ADMEdition_mandatBody_sectionTitle">{t('mandat:forMedias', "Pour les médias")} </div>
                        <div className="ADMEdition_mandatBody_sectionOptions">
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allMedias" + admObject.dspUid} 
                                    id={"allMedias" + admObject.dspUid}
                                    checked = {+admObject?.allMedias === 1} 
                                    value="1" 
                                    onChange={(e) =>updateAllMediasAdmObjectHandler(e.target.value)} 
                                    />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"allMedias" + admObject.dspUid}>
                                    {t('default:all', "Tous")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allMedias" + admObject.dspUid}  
                                    id={"mediasSelection" + admObject.dspUid}
                                    checked = {+admObject?.allMedias !== 1}
                                    value="0" 
                                    onChange={(e) =>updateAllMediasAdmObjectHandler( e.target.value)}/>
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"mediasSelection" + admObject.dspUid}>
                                    {t('mandat:selection', "Une sélection")}
                                </label>
                            </div>
                        </div>
                        {
                            +admObject?.allMedias !== 1 ? (
                                <>
                                    <div className="ADMEdition_mandatBody_sectionAdd">
                                        <Button 
                                            className="btn btn-light btn-sm text-primary" 
                                            onClick={() => setShowMedias(true)}>
                                                + {t('mandat:addMedias', "Ajouter des médias")}  
                                        </Button>
                                        {showMedias ? (
                                            <MediasModal 
                                                lang={admData?.lang}
                                                agencyUid={admObject?.agencyUid}
                                                advertiserUid = {advertiserUid}
                                                handleClose = {() => setShowMedias(false)}
                                                show = {showMedias}
                                                addMedias = {addMediasHandler}
                                            />
                                        ) : null}
                                    </div>
                                    <div className="ADMEdition_mandatBody_sectionList">
                                        {
                                            admObject?.medias.length > 0 ? admObject.medias.map(media => (
                                                <div className="ADMEdition_mandatBody_sectionItem" key={media.uid}>
                                                    <div className="ADMEdition_mandatBody_sectionItemValue">{admData?.lang === "FR" ? media.label:media.label_en} </div>
                                                    <div className="ADMEdition_mandatBody_sectionItemIcon">
                                                        <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteMediaHandler(media)} />
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                    <div className="ADMEdition_mandatBody_section">
                        <div className="ADMEdition_mandatBody_sectionTitle">{t('mandat:forRegies', "Pour les régies")} </div>
                        <div className="ADMEdition_mandatBody_sectionOptions">
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allAddeps" + admObject.dspUid} 
                                    id={"allAddeps" + admObject.dspUid}
                                    checked = {+admObject?.allAddeps === 1} 
                                    value="1" 
                                    onChange={(e) =>updateAllRegiesAdmObjectHandler( e.target.value)}
                                    disabled={+admObject?.allMedias === 0 && admObject?.medias?.length <= 0} 
                                />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"allAddeps" + admObject.dspUid}>
                                    {t('default:allF', "Toutes")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allAddeps" + admObject.dspUid}  
                                    id={"regiesSelection" + admObject.dspUid}
                                    checked = {+admObject?.allAddeps !== 1}
                                    value="0" 
                                    onChange={(e) =>updateAllRegiesAdmObjectHandler( e.target.value)}
                                    disabled={+admObject?.allMedias === 0 && admObject?.medias?.length <= 0}
                                />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"regiesSelection" + admObject.dspUid}>
                                    {t('mandat:selection', "Une sélection")}
                                </label>
                            </div>
                        </div>
                        {
                            +admObject?.allAddeps !== 1 ? (
                                <>
                                    <div className="ADMEdition_mandatBody_sectionAdd">
                                        <Button 
                                            className="btn btn-light btn-sm text-primary" 
                                            onClick={() => setShowRegies(true)} 
                                            disabled={(admObject?.medias.length === 0 && +admObject?.allMedias !== 1)}
                                        >
                                            + {t("mandat:addRegies")}
                                        </Button>
                                        {showRegies ? (
                                            <RegiesModal 
                                                agencyUid={admObject?.agencyUid}
                                                advertiserUid = {advertiserUid}
                                                handleClose = {() => setShowRegies(false)}
                                                show = {showRegies}
                                                addRegies = {addRegiesHandler}
                                                mediaFilters= {admObject.medias}
                                            />
                                        ) : null}
                                    </div>
                                    <div className={"ADMEdition_mandatBody_sectionList" + ((admObject?.medias.length === 0 && +admObject?.allMedias !== 1)  ? " sectionListDisabled" : "")}>
                                        {
                                            admObject?.regies.length > 0 ? admObject.regies.map(regie => (
                                                <div className="ADMEdition_mandatBody_sectionItem" key={regie.uid}>
                                                    <div className="ADMEdition_mandatBody_sectionItemValue">{regie.official_name} </div>
                                                    <div className="ADMEdition_mandatBody_sectionItemIcon">
                                                        <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteRegieHandler(regie)} />
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                    <div className="ADMEdition_mandatBody_section">
                        <div className="ADMEdition_mandatBody_sectionTitle">
                            {t('mandat:forSupports', "Pour les supports")} 
                        </div>
                        <div className="ADMEdition_mandatBody_sectionOptions">
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allSupports" + admObject.dspUid} 
                                    id={"allSupports" + admObject.dspUid}
                                    checked = {+admObject?.allSupports === 1} 
                                    value="1" 
                                    onChange={(e) =>updateAllSupportsAdmObjectHandler( e.target.value)} 
                                    disabled={(+admObject?.allMedias === 0 && admObject?.medias?.length <=0)
                                                || (+admObject?.allAddeps === 0 && admObject?.regies?.length <= 0)}     
                                />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"allSupports" + admObject.dspUid}>
                                    {t('default:all', "Tous")}
                                </label>
                            </div>
                            <div className="form-check">
                                <input 
                                    className="form-check-input" 
                                    type="radio" 
                                    name={"allSupports" + admObject.dspUid}  
                                    id={"supportsSelection" + admObject.dspUid}
                                    checked = {+admObject?.allSupports !== 1}
                                    value="0" 
                                    onChange={(e) =>updateAllSupportsAdmObjectHandler( e.target.value)}
                                    disabled={(+admObject?.allMedias === 0 && admObject?.medias?.length <=0)
                                                || (+admObject?.allAddeps === 0 && admObject?.regies?.length <= 0)}     
                                />
                                <label 
                                    className="form-check-label" 
                                    htmlFor={"supportsSelection" + admObject.dspUid}>
                                    {t('mandat:selection', "Une sélection")}
                                </label>
                            </div>
                        </div>
                        {
                            +admObject?.allSupports !== 1 ? (
                                <>
                                    <div className="ADMEdition_mandatBody_sectionAdd">
                                        <Button 
                                            className="btn btn-light btn-sm text-primary" 
                                            onClick={() => setShowSupports(true)} 
                                            disabled={(admObject?.regies.length === 0 && +admObject?.allAddeps !== 1)}
                                            >
                                                + {t('mandat:addSupports', "Ajouter des supports")}  
                                        </Button>
                                        {showSupports ? (
                                            <SupportsModal 
                                                agencyUid={admObject?.agencyUid}
                                                advertiserUid = {advertiserUid}
                                                handleClose = {() => setShowSupports(false)}
                                                show = {showSupports}
                                                addSupports = {addSupportsHandler}
                                                regies = {admObject.regies}
                                                medias= {admObject?.medias}
                                            />
                                        ) : null}
                                    </div>
                                    <div className={"ADMEdition_mandatBody_sectionList" + ((admObject?.regies.length === 0 && +admObject?.allAddeps !== 1) ? " sectionListDisabled" : "")}>
                                        {                 
                                            admObject?.supports.length > 0 ? admObject.supports.map(support => (
                                                <div className="ADMEdition_mandatBody_sectionItem" key={support.uid ? support.uid : support.label}>
                                                    <div className="ADMEdition_mandatBody_sectionItemValue">{support.label} </div>
                                                    <div className="ADMEdition_mandatBody_sectionItemIcon">
                                                        <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteSupportHandler(support)} />
                                                    </div>
                                                </div>
                                            )) : null
                                        }
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                    
                </div>
                <div style={{display:"flex"}}>
                    <span>{t('default:missions', "Missions")} </span>
                        <hr style={{width:"98%", marginTop:"11px"}}/>
                        <AddBoxIcon 
                            className="admEdition__addClauseIcon"
                            onClick={() => setAddMissionShow(!addMissionShow)}
                        />
                        {
                            addMissionShow &&
                                <AddMissionModal 
                                    show={addMissionShow}
                                    handleClose={() => setAddMissionShow(false)}
                                    missions={admObject?.missions} 
                                    addMission={addMissionHandler}  
                                    lang={( admData?.lang === 'FR' || admData?.lang === 'EN') ? admData?.lang : 'FR'  }
                                />
                        }
                </div> 
                <div className="ADMEdition_mandatBody_options">
                    <table className="purposesTable">
                        <tbody>
                            <tr>
                                <td style={{minWidth:"300px"}}></td>
                                <td>{t('default:representative', "Mandataire")}</td>
                                {isMandataire === false && <td>{t('default:subrepresentative', "Sous Mandataire")}</td>}
                            </tr>
                            {
                                admObject?.missions.map(p => (
                                    <tr key={p.id}>
                                        <td><div>
                                            {
                                                (p.label !== null) ?
                                                    p.label
                                                : admData.lang === 'FR' ?
                                                    t('masterData:missionNotTranslatedToFR')
                                                :   t('masterData:missionNotTranslatedToEN')
                                            }
                                        </div></td>
                                        <td>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" name={p.label}
                                                    checked={(p.responsible === "PROXY")}                                                    
                                                    onChange={(e) => onClickMissionHandler(p,"PROXY", e.target.checked)}
                                                    disabled={p.mandatory === "1" && p.responsible === "PROXY"}
                                                    />
                                            </div>
                                        </td>
                                        {isMandataire === false && 
                                            <td>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" name={p.label}
                                                    checked={(p.responsible === "SUBPROXY")}
                                                    onChange={(e) => onClickMissionHandler(p,"SUBPROXY", e.target.checked)}
                                                    disabled={p.mandatory === "1" && p.responsible === "SUBPROXY"}
                                                    />
                                                </div>
                                            </td>
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table> 
                </div>
                
            </div>  
            {                                   
                showNoPayerMissionModal &&
                <NoPayerMissionModal
                    show = {showNoPayerMissionModal}
                    handleClose = {() => setShowNoPayerMissionModal(false)}
                    params = {noPayerMissionModalParams}
                    updateMissionHandler = {updateMissionHandler}                   
                />          
            }
        </>
    )
}
const mapStateToProps = (state) => ({
    admData: state.mandat.admData,
    admUpdate: state.mandat.admUpdate
})
const mapDispatchToProps = dispatch => ({
    updateMandatData: (admNewData) => dispatch(actions.updateAdmData(admNewData)),
    updateMandat : (mandatUid, toUpdateData) => dispatch(actions.updateMandat(mandatUid, toUpdateData)),
    addMandatProduct : (admOUid, products) => dispatch(actions.addMandatProduct(admOUid, products)),
    updateMandatAdmObject : (admOUid, field, value) => dispatch(actions.updateMandatAdmObject(admOUid, field,value)),
    deleteMandatProduct : (admOUid, product) => dispatch(actions.deleteMandatProduct(admOUid, product)),
    addMandatMedia : (admOUid, medias) => dispatch(actions.addMandatMedia(admOUid, medias)),
    deleteMandatMedia : (admOUid, media) => dispatch(actions.deleteMandatMedia(admOUid, media)),
    addMandatRegie : (admOUid, regies) => dispatch(actions.addMandatRegie(admOUid, regies)),
    deleteMandatRegie : (admOUid, regie) => dispatch(actions.deleteMandatRegie(admOUid, regie)),
    addMandatSupport : (admOUid, supports) => dispatch(actions.addMandatSupport(admOUid, supports)),
    deleteMandatSupport : (admOUid, support) => dispatch(actions.deleteMandatSupport(admOUid, support)),
    addMandatMarketingMode : (admOUid, marketingModes) => dispatch(actions.addMandatMarketingMode(admOUid, marketingModes)),
    deleteMandatMarketingMode : (admOUid, marketingMode) => dispatch(actions.deleteMandatMarketingMode(admOUid, marketingMode)),
    addMandatGeographicalArea : (admOUid, geographicalAreas) => dispatch(actions.addMandatGeographicalArea(admOUid, geographicalAreas)),
    deleteMandatGeographicalArea : (admOUid, geographicalArea) => dispatch(actions.deleteMandatGeographicalArea(admOUid, geographicalArea)),
    updateMandatObjects : (admOUid, object) => dispatch(actions.updateMandatObjects(admOUid, object))
})
export default connect(mapStateToProps, mapDispatchToProps) (MandatEdition)

