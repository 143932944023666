import React, { useEffect, useState } from 'react'
import './UserFormValidation.css';
import avatar from '../../../../assets/images/avatar.svg'
import iconEntity from '../../../../assets/images/icon-entity.png'
import iconCross from '../../../../assets/images/icon-cross.png'
import EntityUserModal from '../EntityUserModal/EntityUserModal'
import CloseIcon from '@material-ui/icons/Close';
import { checkValidity } from '../../../../shared/utility'
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2'
import fr from 'react-phone-input-2/lang/fr.json'
import InfoIcon from '@material-ui/icons/Info';
import DropDown from '../../../UI/DropDown/DropDown'
import ChangesList from './ChangesList/ChangesList'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AskedBy from './AskedBy/AskedBy';
import OriginalValue from './OriginalValue/OriginalValue';
import Spinner from 'react-bootstrap/Spinner';

function UserFormValidation(props) {
    let {
        usersPending,
        user,
        setUser,
        inputUserChange,
        addUser,
        updateUser,
        validateUser,
        validateUsercopy,
        resignUsercopy,
        validateUserAdd,
        resignUserAdd,
        desactivateUser,
        handleFormChanged,
        loading
    } = props

    const [userPending, setUserPending] = useState(null)
    const [originalUser, setOriginalUser] = useState(null)
    const [showEntityModal, setShowEntityModal] = useState(false);
    const [errors, setErrors] = useState(null);
    const [changesListshow, setChangesListShow] = useState(null)
    const {t} = useTranslation();

    const arrayUniqueValues = (arrayT) => {
        return arrayT.filter((value, index, self) => self.indexOf(value) === index)
    }

    const arrayUniqueValuesByUid = (arrayT) => {
        if(arrayT && arrayT?.length > 0)
            return arrayT.filter((value, index, self) => {
                return self.findIndex(row => value?.uid === row?.uid) === index 
            })
        else 
            return []
    }

    // group array elements by value and concat creators of those elements in single line
    const arrayGroupByValue = (arrayT) => {
        let uniqueValues = arrayUniqueValues(arrayT.map(el => el.value));
        return uniqueValues.map(val => {
            let creators = arrayT.filter(el => el.value === val).map(el => el.creator)
            let uniqueCreators = arrayUniqueValues(creators);
            return {value : val, creators : uniqueCreators}
        });
    }

    const formatUsersPending = (users) => {
        let userKeys = Object.keys(users[0])
        let userTemp = {}
        userKeys.map(key => {
            if(key === 'entities') {
                let userEntities = []
                userEntities = users.map(user => user.entities).flat()
                let userUniqueEntities = userEntities.filter((entity, index,self) => self.findIndex(e => e.uid === entity.uid) === index)
                userTemp = {
                    ...userTemp,
                    entities : userUniqueEntities
                }
            } else if(key === 'creatorEntities') {
                let creators = users.map(user => ({
                    id : +user?.creator,
                    firstName : user?.creatorFirstName,
                    lastName : user?.creatorLastName,
                    entities : user.creatorEntities[user?.creator]
                }))
                userTemp = {
                    ...userTemp,
                    creatorsEntities : arrayUniqueValuesByUid(users.map(user => user.creatorEntities[user?.creator]).flat()),
                    creators
                }
            } else {
                userTemp = {
                    ...userTemp,
                    [key] : arrayGroupByValue(users.map(user => ({value : user[key], creator : user.creator})))
                }
            }
        })
        return userTemp
    }

    useEffect(() => {
        if(usersPending) {
            if(usersPending.length === 1){
                if(user?.status === "Modifié") {
                    setUser({
                        ...usersPending[0],
                        userId : user?.userId,
                        userUid : user?.userUid,
                        email : user?.email,
                        entities : user?.entities,
                        role: "AC",
                        is_legal_representative : false,
                        is_signataire : false,
                        status : "Modifié"
                    })
                    setOriginalUser(user)
                    setUserPending(null)
                } else {
                    setUser(usersPending[0])
                    setOriginalUser(null)
                    setUserPending(null) 
                }
                
            } else if(user?.status === "Modifié") {
                setUserPending({
                    ...formatUsersPending(usersPending),
                    role: "AC"
                })
                setOriginalUser(null)
            } else {
                setUser({
                    entities: formatUsersPending(usersPending)?.entities,
                    creatorsEntities: formatUsersPending(usersPending)?.creatorsEntities,
                    role: "AC",
                    email : usersPending[0]?.email,
                    is_legal_representative : false,
                    is_signataire : false,
                    // telephone_number : "33",
                    pending : 1,
                })
                setUserPending(formatUsersPending(usersPending))
                setOriginalUser(null)
            }
        }
        
        setErrors(null)
    }, [usersPending])

    const deleteEntityclickHandler = (entity) => {
        let userTemp = {...user}
        if(user?.userId && !entity?.new ){
            userTemp = {
                ...userTemp,
                entitiesToDelete : userTemp?.entitiesToDelete ? [...userTemp?.entitiesToDelete, entity.uid] : [entity.uid]
            }
        }
        let userEntities = [...user?.entities]
        let entityIndex = user?.entities.findIndex(ent => ent.uid === entity.uid);
        if(entityIndex >=0 ){
            userEntities.splice(entityIndex, 1)
        }
        setUser({
            ...userTemp,
            entities: userEntities
        })
        handleFormChanged(true);
    }

    const checkUserFormValidity = (user) => {
        let errors = []
        if(!user.hasOwnProperty('first_name') || !checkValidity(user?.first_name, {required: true})){
            errors.push(t("masterData:error_userFirstName")) ;
        }
        if(!user.hasOwnProperty('last_name') || !checkValidity(user?.last_name, {required: true})){
            errors.push(t("masterData:error_userLastName")) ;
        }
        // if(!user.hasOwnProperty('telephone_number') || !checkValidity(user?.telephone_number, {required: true, isPhone: true})){
        //     errors.push(t("masterData:error_userPhoneNumber")) ;
        // }
        if(!user.hasOwnProperty('role') || !checkValidity(user?.role, {required: true})){
            errors.push(t("masterData:error_userRole")) ;
        }
        if(!user.hasOwnProperty('email') || !checkValidity(user?.email, {required: true, isEmail: true})){
            errors.push(t("masterData:error_userEmail")) ;
        }
        if(user.hasOwnProperty('role')!='AE' && (!user.hasOwnProperty('entities') || !user?.entities || user?.entities.length == 0)){
            errors.push(t("masterData:error_userEntity")) ;
        }
        return errors;
    }

    const addUserclickHandler = (e) => {
        e.preventDefault();
        let errors = checkUserFormValidity(user);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            setErrors(null)
            addUser(e)
        }
    }
    const updateUserclickHandler = (e, userUid) => {
        e.preventDefault();
        let errors = checkUserFormValidity(user);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            setErrors(null)
            updateUser(e, userUid)
        }
    }

    const validateUserAddClickHandler = (e, userEmail) => {
        e.preventDefault();
        let errors = checkUserFormValidity(user);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            setErrors(null)
            validateUserAdd(e, userEmail)
        }
    }

    const validateUserCopyClickHandler = (e) => {
        e.preventDefault();
        let errors = checkUserFormValidity(user);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            setErrors(null)
            validateUsercopy(e, user?.userUid)
        }
    }

    const validateUserclickHandler = (e, userUid) => {
        e.preventDefault();
        let errors = checkUserFormValidity(user);
        if(errors && errors.length > 0){
            setErrors(errors)
        }else{
            setErrors(null)
            validateUser(e, userUid)
        }
    }

    const desactivateUserclickHandler = (e, userUid) => {
        e.preventDefault();
        setErrors(null)
        desactivateUser(e, userUid)
    }

    const showChangesListClickHandler = (field) => {
        if(changesListshow && changesListshow.hasOwnProperty(field)){
            setChangesListShow({
                [field] : !changesListshow[field]
            })
        }else {
            setChangesListShow({
                [field] : true
            })
        }
    }
    return (
        <>  
            {/* Partie droite */}
            
            <div className="rightSide_header_container">
                <div className="header_infos_container">                
                    <img className="rightSide_header_logo" src={avatar} />
                    {/*
                        user?.userId && (user?.status.toLowerCase() === "invitation") ? (
                            <div className="rightSide_header_sentInvitation">
                            <div style={{paddingRight : "10px"}}><i style={{margin : "5px"}} className="fas fa-check"></i> Invitation envoyée</div>
                            </div>
                            ) : null
                        */}
                </div>
                
            </div>
            {
                (user?.userId && user?.creator) ? (
                    <div className="rightSide_askedBy_container">
                        <em>{t("default:askedBy")}</em>
                        <DropDown 
                            icon={<InfoIcon style={{width: "15px", marginLeft: "4px"}} className='infoIcon' />}
                            zIndex={200}
                        >
                            <div className="askedBy_dropDown_container">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                        <th scope="col">{t("default:user")}</th>
                                        <th scope="col">{t("default:company")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <th scope="row">
                                            <i> 
                                                {user?.creatorFirstName + ' ' + user?.creatorLastName}
                                            </i>
                                        </th>
                                        <td>
                                            <div className="askedBy_agenciesList">
                                                {
                                                    user?.creatorEntities[user?.creator]?.map(e => e.commercial_name).join()
                                                }
                                            </div>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                        </DropDown>
                    </div>
                ) : null
            }
            {
                errors && errors.length > 0 ? (
                     <div className="alert alert-danger mt-2" role="alert">
                        <ul>
                            {
                                errors.map(error => (<li>{error}</li>))
                            }
                        </ul>
                    </div>
                ) : null
            }
           
            <div className="rightSide_form_container">
                <form>
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <label for="last_name">{t('default:firstName', "Prénom")}</label>
                            <div className="userValidation_inputContainer">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    required 
                                    minLength="1" 
                                    id="first_name" 
                                    name="first_name" 
                                    value={user?.first_name || ''} 
                                    onChange={(e) => inputUserChange(e.target.name, e.target.value) }   
                                />
                                {
                                    userPending ? (
                                        <ExpandMoreIcon 
                                            className="userValidation_inputIcon" 
                                            onClick={() => showChangesListClickHandler("first_name")}
                                        />
                                    ) : null
                                }
                            </div>
                            {
                                userPending ? (
                                    <>
                                        <ChangesList 
                                            values={userPending?.first_name}
                                            show={changesListshow?.first_name}
                                            close={showChangesListClickHandler}
                                            field={"first_name"}
                                            click={inputUserChange}
                                        />
                                        <AskedBy 
                                            askedChanges={userPending?.first_name}
                                            creators={userPending?.creators}
                                            dropdownZindex={106}
                                        />
                                    </>
                                ) : null
                            }
                            {
                                originalUser ? (
                                    <OriginalValue
                                        value={originalUser?.first_name}
                                    />
                                ) : null
                            }
                            
                        </div>
                        <div className="form_element_container">
                            <label for="first_name">{t('default:name', "Nom")}</label>
                            <div className="userValidation_inputContainer">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    required 
                                    minLength="1" 
                                    id="last_name" 
                                    name="last_name"
                                    value={user?.last_name || ''} 
                                    onChange={(e) => inputUserChange(e.target.name, e.target.value) } 
                                />
                                {
                                    userPending ? (
                                        <ExpandMoreIcon 
                                            className="userValidation_inputIcon" 
                                            onClick={() => showChangesListClickHandler("last_name")}
                                        />
                                    ) : null
                                }
                            </div>
                            {
                                userPending ? (
                                    <>    
                                        <ChangesList 
                                            values={userPending?.last_name}
                                            show={changesListshow?.last_name}
                                            close={showChangesListClickHandler}
                                            field={"last_name"}
                                            click={inputUserChange}
                                        />
                                        <AskedBy 
                                            askedChanges={userPending?.last_name}
                                            creators={userPending?.creators}
                                            dropdownZindex={105}
                                        />
                                    </>
                                ) : null
                            }
                            {
                                originalUser ? (
                                    <OriginalValue
                                        value={originalUser?.last_name}
                                    />
                                ) : null
                            }
                        </div>
                    </div>

                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <label htmlFor="language">{t('default:language', "language")} </label>
                            <div className="userValidation_inputContainer">
                                <select 
                                    className="form-control" 
                                    id="language" 
                                    name="language" 
                                    value={user?.language || 'FR'} 
                                    onChange={(e) => inputUserChange(e.target.name, e.target.value)}
                                    >
                                    <option value="FR">FR</option>
                                    <option value="EN">EN</option>
                                </select>
                            </div>
                            {
                                userPending ? (
                                    <>
                                        <AskedBy 
                                            askedChanges={userPending?.language}
                                            creators={userPending?.creators}
                                            dropdownZindex={106}
                                        />
                                    </>
                                ) : null
                            }
                            {
                                originalUser ? (
                                    <OriginalValue
                                        value={originalUser?.language}
                                    />
                                ) : null
                            }
                        </div>
                        <div className="form_element_container">
                            <input 
                                style={{margin:"23px 8px 0px 2px"}}
                                className="form-check-input" 
                                type="checkbox"  
                                name="notification" 
                                value="" 
                                id="notification" 
                                checked={(user?.notification && user?.notification == "1") } 
                                onChange={(e) => inputUserChange(e.target.name, e.target.checked) }
                                />
                            <label className="form-check-label" htmlFor="notification">
                                {t('default:userNotifications', "userNotifications")} 
                            </label>
                        </div>
                    </div>

                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <label htmlFor="mail">{t('default:mailAddress', "Adresse mail")}</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="mail" 
                                required 
                                minLength="1" 
                                name="email" 
                                value={user?.email || ''} 
                                onChange={(e) => inputUserChange(e.target.name, e.target.value)} 
                                disabled
                            />
                        </div>
                        <div className="form_element_container">
                            <label htmlFor="phone">{t('default:mobileNumber', "Numéro de portable")}</label>
                            {/* <input 
                                type="text" 
                                className="form-control" 
                                id="phone" 
                                required minLength="1" 
                                name="telephone_number" 
                                value={userCopy?.telephone_number || user?.telephone_number || ''} 
                                onChange={(e) => (userCopy?.userId ? inputUserCopyChange(e.target.name, e.target.value) : inputUserChange(e.target.name, e.target.value)) } 
                                disabled={( "MC, MR".includes(role))? true : false} ></input> */}
                            
                                <div className="userValidation_inputContainer">
                                    <PhoneInput
                                        //disabled={( "MC, MR".includes(role)  || ("MG".includes(role) && ("AG".includes(user?.role) || "RG".includes(user?.role))))? true : false}
                                        localization={fr}
                                        country={'fr'}
                                        inputClass={"form-control w-100 userValidation_phoneInput"}
                                        dropdownStyle={{width: "auto"}}
                                        preferredCountries={['fr', 'us']}
                                        enableSearch={false}
                                        inputProps={{name: "telephone_number"}}
                                        placeholder={"33 0 00 00 00 00"}
                                        value={user?.telephone_number?.substr(2) || ''}
                                        onChange={phone => inputUserChange("telephone_number", phone ? "00" + phone : null)}
                                    />
                                    {
                                        userPending ? (
                                            <ExpandMoreIcon 
                                                className="userValidation_inputIcon" 
                                                onClick={() => showChangesListClickHandler("telephone_number")}
                                            />
                                        ) : null
                                    }
                                    
                                </div>
                                {
                                    userPending ? (
                                        <>
                                            <ChangesList 
                                                values={userPending?.telephone_number}
                                                show={changesListshow?.telephone_number}
                                                close={showChangesListClickHandler}
                                                field={"telephone_number"}
                                                click={inputUserChange}
                                            />
                                            <AskedBy 
                                                askedChanges={userPending?.telephone_number}
                                                creators={userPending?.creators}
                                                dropdownZindex={104}
                                            />
                                        </>
                                    ) : null
                                }
                                {
                                    originalUser ? (
                                        <OriginalValue
                                            value={originalUser?.telephone_number}
                                        />
                                    ) : null
                                }
                                
                        </div>
                    </div>

                    {/* les 2 cards */}
                    <div className="form_twoElements_container">
                        <div className="form_element_container">
                            <label>{t("default:role")} </label>
                            <select 
                                className="form-control" 
                                required 
                                id="role" 
                                name="role"  
                                aria-label="Default select example" 
                                value={user?.role || ''} 
                                onChange={(e) => inputUserChange(e.target.name, e.target.value) }
                                disabled
                            >
                                <option value="AC">{t('masterData:roleAC', "Annonceur contributeur")}</option>
                            </select>
                            <div className="entityUser_roles" >
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox"   
                                        name="is_signataire" 
                                        value="" 
                                        id="is_signataire" 
                                        checked={(user?.is_signataire && user?.is_signataire == "1") ? true : false } 
                                        //disabled
                                        onChange={(e) => inputUserChange(e.target.name, e.target.checked) }
                                        />
                                    <label className="form-check-label" for="is_signataire">
                                        {t("default:signatory")}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input 
                                        className="form-check-input" 
                                        type="checkbox"  
                                        name="is_legal_representative" 
                                        value="" 
                                        id="is_legal_representative" 
                                        checked={(user?.is_legal_representative && user?.is_legal_representative == "1") ? true : false } 
                                        //disabled
                                        onChange={(e) => inputUserChange(e.target.name, e.target.checked) }
                                        />
                                    <label className="form-check-label" for="is_legal_representative">
                                        {t("default:legalRepresentative")}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form_element_container">
                            <label >
                            {t("default:society")} 
                                <i className="fas fa-plus addEntities_icon" onClick={() => setShowEntityModal(true)} ></i> 
                                {showEntityModal ? (
                                    <EntityUserModal
                                        handleClose = {() => setShowEntityModal(false)}
                                        show = {showEntityModal}
                                        addEntitiesUser={inputUserChange}
                                        userEntities={user?.entities}
                                        userRole={user?.role}
                                    />
                                ) : null}
                            </label>
                            <div className="form_pillsElements_container" >
                                {
                                    user?.entities?.length > 0 ? user?.entities.map(entity => (
                                        <div className="form_pillElement_container">
                                            <div className="pillElement_infos">
                                                <img className="pillElement_icon" src={iconEntity} ></img>
                                                <div className="pillElement_label">{entity.commercial_name}</div>
                                            </div>
                                            <CloseIcon style={{color: "red", cursor: "pointer"}} fontSize="small" onClick={() => deleteEntityclickHandler(entity)} />
                                        </div> 
                                    )) : null
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className="form_oneElement_container">
                        <div className="form_element_container" style={{width: "100%"}}>
                            <label for="fonction">{t('default:function', "Fonction")}</label>
                            <div className="userValidation_inputContainer">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="fonction" 
                                    minLength="1" 
                                    name="fonctionLabel" 
                                    value={user?.fonctionLabel || ''} 
                                    onChange={(e) => inputUserChange(e.target.name, e.target.value)}  
                                />
                                {
                                    userPending ? (
                                        <ExpandMoreIcon 
                                            className="userValidation_inputIcon" 
                                            onClick={() => showChangesListClickHandler("fonctionLabel")}
                                        />
                                    ) : null
                                }
                            </div>
                            {
                                userPending ? (
                                    <>
                                        <ChangesList 
                                            values={userPending?.fonctionLabel}
                                            show={changesListshow?.fonctionLabel}
                                            close={showChangesListClickHandler}
                                            field={"fonctionLabel"}
                                            click={inputUserChange}
                                        />
                                        <AskedBy 
                                            askedChanges={userPending?.fonctionLabel}
                                            creators={userPending?.creators}
                                            dropdownZindex={104}
                                        />
                                    </>
                                ) : null
                            }
                            {
                                originalUser ? (
                                    <OriginalValue
                                        value={originalUser?.fonctionLabel}
                                    />
                                ) : null
                            }
                        </div>
                    </div>
                    
                    
                    {
                        loading ?
                            <div className="rightSide_form_submit_container">
                                <Spinner animation="border" variant="secondary" />
                            </div>
                        : !user?.userId ? (
                            <div className="rightSide_form_submit_container">
                                <input 
                                    type="submit" 
                                    onClick={(e) => validateUserAddClickHandler(e, user?.email)} 
                                    value={t('default:validate', "Valider")} 
                                    className="btn btn-primary rightSide_form_submitBtn"></input>
                                <input 
                                    type="submit" 
                                    onClick={(e) => resignUserAdd(e,user?.email)} 
                                    value={t('default:deny', "Refuser")} 
                                    className="btn btn-primary rightSide_form_submitBtn"></input>
                            </div>
                        ) : user?.status === "Modifié" ? (
                            <div className="rightSide_form_submit_container">
                                <input 
                                    type="submit" 
                                    onClick={(e) => validateUserCopyClickHandler(e)} 
                                    value={t('default:validate', "Valider")} 
                                    className="btn btn-primary rightSide_form_submitBtn"></input>
                                <input 
                                    type="submit" 
                                    onClick={(e) => resignUsercopy(e,user?.userUid)} 
                                    value={t('default:deny', "Refuser")} 
                                    className="btn btn-primary rightSide_form_submitBtn"></input>
                            </div>  
                        ) : (
                            <div className="rightSide_form_submit_container">
                                <input 
                                    type="submit" 
                                    onClick={(e) => validateUserclickHandler(e,user?.userUid)} 
                                    value={t('default:validate', "Valider")} 
                                    className="btn btn-primary rightSide_form_submitBtn"></input>
                                <input 
                                    type="submit" 
                                    onClick={(e) => resignUserAdd(e,user?.email)} 
                                    value={t('default:deny', "Refuser")} 
                                    className="btn btn-primary rightSide_form_submitBtn"></input>
                            </div>  
                        )
                    }    
                </form>
            </div>
            
        </>
    )
}

export default UserFormValidation
