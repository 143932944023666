import axios from 'axios';
import * as actionTypes from './actionTypes';
import {Base64} from 'js-base64';
import Axios from '../../axios-edipub';
import { getNotyfObject } from '../../shared/utility';
import { env } from './../../env'

const notyf = getNotyfObject();

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (login,token, userId, role) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        login: login,
        userId: userId,
        role: role
    }
}

export const authVisSuccess = (login,token, userUId, role, connectedUserUid, connectedUserLogin) => {
    return {
        type: actionTypes.AUTH_VIS_SUCCESS,
        token: token,
        login: login,
        userId: userUId,
        role: role,
        connectedUserUid,
        connectedUserLogin
    }
}

export const setUserVisualisation = (userVisToken, userVisUid, userVisRole, userVisLogin) => {
    Axios.defaults.headers.authorization = 'Bearer ' + userVisToken;
    localStorage.setItem('token', userVisToken)
    localStorage.setItem('userId', userVisUid)
    localStorage.setItem('login', userVisLogin)
    localStorage.setItem('role', userVisRole)
    return {
        type : actionTypes.SET_VISUALISATION_USER,
        userVisToken,
        userVisUid,
        userVisRole,
        userVisLogin
    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('userId')
    localStorage.removeItem('login')
    localStorage.removeItem('role')
    
    return {
        type: actionTypes.AUTH_LOGOUT
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout())
        }, expirationTime)
    }
}

export const exitVisualisation = (userUid) => {
    return dispatch => {
        dispatch(authStart());
        Axios.get('/user/'+userUid+'/userTokenExitVisualisation')
            .then(response => {
                const token = response?.data?.token;
                Axios.defaults.headers.authorization = 'Bearer ' + token;
                const login = response?.data?.email;
                const expireIn = response?.data?.expireIn;
                const userUid = response?.data?.uid;
                const role = response?.data?.role;
                localStorage.setItem('token', token)
                localStorage.setItem('expirationDate', expireIn)
                localStorage.setItem('userId', userUid)
                localStorage.setItem('login', login)
                localStorage.setItem('role', role)
                dispatch(authSuccess(login,token, userUid, role))
            }).catch(response => {
                notyf.error('Une erreur s\'est produite !')
                dispatch(authFail("Erreur"))
            }) 
    }
}

export const auth = (email, password) => { 
    return dispatch => {
        dispatch(authStart());
        let authAuthorization = Base64.encode(email + ":" + password)
        let config = {
            headers: {
                'Authorization': 'Basic ' + authAuthorization
            }
        }
        axios.get(env.REACT_APP_BASE_URL+'/user/'+email+'/authenticate/', config)
        //axios.get('http://favexws.rec.e.icdsc.fr/user/'+email+'/authenticate/', config)
        //axios.get('https://favexws.preprod.dematrust.com/user/'+email+'/authenticate/', config)
            .then(response => {
                const expirationDate = new Date(response.data.expireIn * 1000 );
                Axios.defaults.headers.authorization = 'Bearer ' + response.data.token;
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('expirationDate', response.data.expireIn)
                //localStorage.setItem('userId', response.data.id)
                localStorage.setItem('userId', response.data.uid)
                localStorage.setItem('login', response.data.email)
                localStorage.setItem('role', response.data.role)
                dispatch(authSuccess(response.data.email ,response.data.token, response.data.uid, response.data.role));
                //dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            })
            .catch(err => {
                notyf.error('Une erreur s\'est produite !')
                dispatch(authFail(err.response?.data.message))
            })
    }
}

export const authFromJwt = (token) => { 
    return dispatch => { 
        if(!token) {
            dispatch(logout())
        } else {
            dispatch(authStart());
            const instance = Axios;
            instance.defaults.headers.authorization = 'Bearer ' + token;
            instance.get('/user//checkAuth')
                .then(response =>{
                    const access = response?.data?.access;
                    if(access){
                        localStorage.setItem('token', response.data.token)
                        localStorage.setItem('expirationDate', response.data.expireIn)
                        localStorage.setItem('userId', response.data.uid)
                        localStorage.setItem('login', response.data.login)
                        localStorage.setItem('role', response.data.role)
                        dispatch(authSuccess(response.data.login ,response.data.token, response.data.uid, response.data.role));
                    }else {
                        dispatch(logout());
                    }
                })
                .catch(err => {
                    notyf.error('Une erreur s\'est produite !')
                    dispatch(authFail(err.response?.data.message +  ' ' + err.response?.data?.error))                    
                })           
        }
    }
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        const email = localStorage.getItem('login');
        if(!token) {
            dispatch(logout())
        } else {
            dispatch(authStart());
            const instance = Axios;
            instance.defaults.headers.authorization = 'Bearer ' + token;
            instance.get('/user/'+email+'/checkAuth')
                .then(response =>{
                    const access = response?.data?.access;
                    if(access){
                        const token = response?.data?.token;
                        const login = response?.data?.login;
                        const role = response?.data?.role;
                        const userUid = response?.data?.userUid;
                        const visualisation = response?.data?.visualisation;
                        if(visualisation){
                            const userVisLogin = response?.data?.userVisLogin;
                            const userVisRole = response?.data?.userVisRole;
                            const userVisUid = response?.data?.userVisUid;
                            dispatch(authVisSuccess(userVisLogin, token, userVisUid, userVisRole, userUid, login))
                        } else {
                            dispatch(authSuccess(login,token, userUid, role))
                        }
                    }else {
                        dispatch(logout());
                    }
                })
                .catch(err => {
                    dispatch(logout());
                    
                })
            // const expirationDate = new Date(localStorage.getItem('expirationDate') * 1000)
            // if (expirationDate <= new Date()) {
            //     dispatch(logout())
            // } else {
            //     const userId = localStorage.getItem('userId')
            //     dispatch(authSuccess(token, userId))
            //     //dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
            // }
        }
    }
}