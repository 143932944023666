import React, { useCallback, useEffect, useState, useRef } from 'react'
import ListManagerAbstract from './ListManagerAbstract/ListManagerAbstract';
import { useTranslation } from 'react-i18next';
import Axios from '../../../axios-edipub'
import { getNotyfObject } from '../../../shared/utility'; 


function ListManagerMarketing(props) { 
    let { 
        handleFormChanged, 
    } = props  
    
    const notyf = getNotyfObject();  
    const {t} = useTranslation(); 
    const [items , setItems] = useState([]);
    const [itemsToUpdate , setItemsToUpdate] = useState([]);
    const [itemsToDelete , setItemsToDelete] = useState([]);
    const [newItem, setNewItem] = useState({}); 
    const [updateLoading , setUpdateLoading] = useState(false);       

    const getMarketingList = useCallback((order = false) => { 
        Axios.get('marketingmode', {
            params : { 
                sortOrder: (order) ? "DESC" : "ASC"
            }
        }).then(response => {
            setItems(response.data) 
        })
    }, [])
 
    const marketingAddHandler = () => {
        
        console.log(newItem)
        Axios.post('/marketingmode', newItem
        ).then (response => {
            getMarketingList()
            notyf.success('Ajout sauvegardé') 
        }).catch(err => {
            notyf.error('Une erreur s\'est produite. ' + err?.response?.data?.error)
        })   
    }

    const marketingsUpdateHandler = ( ) => {
        setUpdateLoading(true)
        let data = { 
            'modesToUpdate' : itemsToUpdate,
            'modesToDelete' : itemsToDelete
        } 
        Axios.put(`/marketingmode//editall`, data)
            .then(response => {
                setItemsToUpdate([])
                setItemsToDelete([])
                getMarketingList()
                setUpdateLoading(false)
                handleFormChanged(false)  
                notyf.success('Modifications sauvegardées')
            }).catch(err => { 
                setUpdateLoading(false)   
                notyf.error('Une erreur s\'est produite !')
            }) 
    } 

    return (
        <ListManagerAbstract 
            title = {t('masterData:marketingModes',"Modes de commercialisation")}    
            addNewTitle =  {t('masterData:addNewMarketingMode', "Ajouter un nouveau mode de commercialisation")}
            addNewAlreadyExistLabel = {t("masterData:existMarketingMode",  "Ce mode de commercialisation existe déja!")}   
            fieldColumnNames= { {label: 'Francais', label_en: 'Anglais', code_marketing: 'Code'}}
            uniqueFields={["code_marketing"]}
            mandatoryFields={["label","label_en","code_marketing"]}
            fieldsMaxLen= {{code_marketing:32}}
            items={items}
            setItems={setItems}
            itemsToUpdate={itemsToUpdate}
            setItemsToUpdate={setItemsToUpdate}
            itemsToDelete={itemsToDelete}
            setItemsToDelete={setItemsToDelete}
            newItem={newItem}
            setNewItem={setNewItem} 
            getItemList={getMarketingList}
            itemAddHandler={marketingAddHandler}
            itemsUpdateHandler={marketingsUpdateHandler}         
            handleFormChanged={handleFormChanged}
            updateLoading={updateLoading} 
        />
     ) 
}

export default ListManagerMarketing