import { Tooltip, withStyles } from '@material-ui/core';
import { Notyf } from 'notyf';
import { useTranslation } from 'react-i18next';

export const CTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 15,
    },
}))(Tooltip);

export const  getNotyfObject = () => {
    const notyf = new Notyf({
        types: [
            {
                type: 'warning',
                background: 'orange',
                duration: 10000,
                dismissible: true
              },
              {
                type: 'error',
                background: 'indianred',
                duration: 10000,
                dismissible: true
              }
        ]
    });

    return notyf
}
export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
        return true;
    }
    
    if (rules.required) {
        isValid = value && value?.trim() !== '' && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if (rules.isEmail) {
        const pattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@([a-zA-Z0-9\.]*)/;
        isValid = pattern.test(value) && isValid
    }

    if (rules.isNumeric) {
        const pattern = /^\d+$/;
        isValid = pattern.test(value) && isValid
    }

    if(rules.isPhone) {
       // const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
        //const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
        const pattern = /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))/g;
		isValid = pattern.test(value) && isValid
    }

    if(rules.isFrenchPhone) {
        const pattern = /^((\+)33|0)[1-9](\d){8}$/;
        isValid = pattern.test(value) && isValid
    }

    return isValid;
}

export function phonenumberValidation(inputtxt) {
    var phoneno = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    if(inputtxt.value.match(phoneno)) {
      return true;
    }  
    else {  
      alert("message");
      return false;
    }
  }

export const formatDate = (date) => {
    if (date !== null && date.valueOf() > 0){
        let newDate = new Date(date);
        let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
        let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
    return day + '/' + month + '/' + newDate.getFullYear()
    }else return null;    
}

export const eventHeaderFromKey = (step) =>{
    // 'STEP_CREATION',
    // 'STEP_SHARED',
    // 'STEP_CHANGED',
    // 'STEP_VALIDATION',
    // 'USER_VALIDATION',
    // 'USER_CHAT_EVENT',
    // 'STEP_SIGNATURE',
    // 'USER_SIGNATURE',
    // 'USER_SIGNATURE_RESIGN',
    // 'STEP_PUBLICATION',
    // 'CREATOR_CHANGED',
    // 'POST_SIGN_CHAT',
    // 'DOCUMENT_CANCELLED',
    // 'USER_DELETED', creator only
    // 'RECIPIENTS_CHANGED'
    let eventHeaders = {
        "USER_CONTRIBUTOR_REMINDER" : {
            label : "STEP_RELANCE_CONTRIBUTOR_LABEL", 
            message : "STEP_RELANCE_CONTRIBUTOR_MESSAGE"
        }, 
        "USER_SIGNATURE_REMINDER" : {
                label : "STEP_RELANCE_SIGNATAIRE_LABEL", 
                message : "STEP_RELANCE_SIGNATAIRE_MESSAGE"
            }, 
        "SIGNATORIES_CHANGED" : {
                label : "STEP_VALIDATION_LABEL", 
                message : "STEP_VALIDATION_MESSAGE"
            },
        "STEP_SHARED" : {
                label : "STEP_SHARED_LABEL", 
                message : "STEP_SHARED_MESSAGE"
            }, 
        "STEP_CHANGED": {
                label : "STEP_CHANGED_LABEL", 
                message : "STEP_CHANGED_MESSAGE"
            },
        "STEP_VALIDATION":{
                label : "STEP_VALIDATION_LABEL", 
                message : "STEP_VALIDATION_MESSAGE"
            },
        "USER_VALIDATION":{
                label : "USER_VALIDATION_LABEL", 
                message : "USER_VALIDATION_MESSAGE"
            },
        "USER_SIGNATURE" : {
                label : "USER_SIGNATURE_LABEL", 
                message : "USER_SIGNATURE_MESSAGE"
            },
        "STEP_SIGNATURE":{
                label : "STEP_SIGNATURE_LABEL", 
                message : "STEP_SIGNATURE_MESSAGE"
            },
        "USER_CHAT_EVENT" :{
                label : "USER_CHAT_EVENT_LABEL"
            },
        "USER_SIGNATURE_RESIGN" :{
                label : "USER_SIGNATURE_RESIGN_LABEL",
                message : "USER_SIGNATURE_RESIGN_MESSAGE"
            },
        "STEP_PUBLICATION":{
                label : "STEP_PUBLICATION_LABEL", 
                message : "STEP_PUBLICATION_MESSAGE"
            },
        "CREATOR_CHANGED":{
                label : "CREATOR_CHANGED_LABEL", 
                message : "CREATOR_CHANGED_MESSAGE"
            },
        "POST_SIGN_CHAT":{
                label : "POST_SIGN_CHAT_LABEL"
            },
        "SUPPORT_TRANSFER":{
                label : "SUPPORT_TRANSFER_LABEL"
            },
        "RECIPIENTS_CHANGED":{
                label: "RECIPIENTS_CHANGED_LABEL", 
                message: "RECIPIENTS_CHANGED_MESSAGE"
            },
        "USER_DELETED" : {
                label: "USER_DELETED_LABEL", 
                message: "USER_DELETED_MESSAGE"
            },
        "DOCUMENT_CANCELLED" : {
                label: "DOCUMENT_CANCELLED_LABEL", 
                message: "DOCUMENT_CANCELLED_MESSAGE"
            }
            
    } 
    return eventHeaders[step] ? eventHeaders[step] : "dosen't exist "+step 
}

export const formatDateD = (date) => {
    if (date !== null && date.valueOf() > 0){
        let newDate = new Date(date);
        let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()
        let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
    return day + '/' + month + '/' + newDate.getFullYear() + ' ' 
            + ( (newDate.getHours()+1) < 10 ? ('0' + newDate.getHours()) : newDate.getHours()  )
            +':'+ ( (newDate.getMinutes()+1) < 10 ? ('0' + newDate.getMinutes()) : newDate.getMinutes()  )
            +':'+ ( (newDate.getSeconds() +1) < 10 ? ('0' + (newDate.getSeconds()+1)) : (newDate.getSeconds() +1 ) )
    }else return null;    
}


export const getTimeDate = (date) => {
    let newDate = new Date(date);
    let hour = newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours()
    let minutes = newDate.getMinutes() < 10 ? "0" + newDate.getMinutes() : newDate.getMinutes()
    return hour + ":" + minutes
}

export const relativeDate = (date) => {
    let relativeDate = " "
    const relativeTime = new Intl.RelativeTimeFormat('fr-fr')
    let nbdays = Math.ceil( (new Date(+date) - Date.now()) / (1000 * 3600 * 24))
    if(nbdays == 0) return "Aujourd'hui"
    else if( (nbdays > 0 && nbdays <= 30) ||  (nbdays < 0 && nbdays >= -30) ){
        relativeDate = relativeTime.format( nbdays, 'days')
        return relativeDate.charAt(0).toUpperCase() + relativeDate.slice(1);
    }else if( (nbdays > 30 && nbdays < 365) ||  (nbdays < -30 && nbdays >= -365) ){
        let nbmonth = Math.ceil( (new Date(+date) - Date.now()) / ( 12 * 1000 * 3600 * 24))
        relativeDate = relativeTime.format( nbmonth, 'months')
        return relativeDate.charAt(0).toUpperCase() + relativeDate.slice(1);
    }else {
        let nbYears = Math.ceil( (new Date(+date) - Date.now()) / ( 12 * 12 * 1000 * 3600 * 24))
        relativeDate = relativeTime.format( nbYears, 'years')
        return relativeDate.charAt(0).toUpperCase() + relativeDate.slice(1);
    }

}

export const isSameDay = (date1, date2) => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export const isToday = (date) => {
    return isSameDay(date, new Date());
}

export const isYesterday = date => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return isSameDay(date, yesterday);
}

export const uniqueObjsArrayByUid = (arrayToFilter) => {
    const seen = new Set();
    const filteredArr = arrayToFilter.filter(el => {
        const duplicate = seen.has(el.uid);
        seen.add(el.uid);
        return !duplicate;
    });
    return filteredArr
}

export const  uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const ucFirst = (toFormat) => {
    return toFormat.charAt(0).toUpperCase() + toFormat.slice(1);
}
export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };
