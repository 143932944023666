import React, { useCallback, useEffect, useState, useRef } from 'react';
import Axios from '../../axios-edipub';
import { connect } from 'react-redux';
import { getNotyfObject, updateObject } from '../../shared/utility';
import Spinner from '../../components/UI/Spinner/Spinner';
import './NewHome.css';
import NewHomeHeader from '../../components/NewHome/NewHomeHeader/NewHomeHeader';
import TableBody from '../../components/NewHome/TableBody/TableBody';
import TableActions from '../../components/NewHome/TableAction/TableActions';
import TableFilters from '../../components/NewHome/TableFilters/TableFilters';
import TableHead from '../../components/NewHome/TableHead/TableHead';
import { formatDate } from '../../shared/utility';
import TablePagination from '../../components/NewHome/TablePagination/TablePagination';
import { useTranslation } from 'react-i18next';

function NewHome({role}) {
    const {t} = useTranslation();   
    const notyf = getNotyfObject()
    const [ csvData, setCsvData ] = useState();
    const csvBtn = useRef();
    const [ selectedRows, setSelectedRows ] = useState([])
    
    const columns = [
        {title: t('newHome:status', "Statut"), field: 'status', width: "150px"},
        {title: t('newHome:admNumber'), field: 'id', width: "170px"},
        {title: t('default:creationDate', "Date de création"), field: 'creationDate', width: "200px"},
        {title: t('newHome:official_name', "Annonceur"), field: 'official_name', width: "150px"},
        {title: "N° identification Annonceur", field: 'idNumber', width: "150px"},
        {title: t('newHome:mandataire', "Mandataire"), field: 'mandataire', width: "150px"},
        {title: t('newHome:sousMandataires', "Sous Mandataires"), field: 'sousMandataires', width: "150px"},  
        {title: t('newHome:start_period', "Date début"), field: 'start_period', width: "150px"},
        {title: t('newHome:end_period', "Date fin"), field: 'end_period', width: "150px"},
        {title: t('newHome:description', "Description"), field: 'description', width: "200px"},
        {title: t('newHome:medias', "Medias"), field: 'medias', width: "200px"},
        {title: t('newHome:products', "Produits"), field: 'products', width: "200px"}
    ]

    if(role==='RG' || role==='RC')
        columns.push({title: t('newHome:download', "Télécharger"), field: '', width: "200px"});
    
    const initialFiltersState = {
        status: "", creationDate: "", official_name: "", idNumber: "", mandataire: "", sousMandataires: "",start_period: "", end_period: "", description: "",
        medias: "", products: "", orderBy: "creationDate", sortOrder: "DESC", page:1, selectedRows: [], selectedAllRows: false,
        selectedAllPerPage : []
    }
    
    const reverseTable = {
        'Status' : false ,'creationDate': false,'official_name': false,
        'mandataire': false,'start_period': false,'end_period': false,
        'description': false,'medias': false,'produits': false
    }
    const [filters, setFilters] = useState(initialFiltersState);
    const [sortQuery, setSortQuery] = useState("creationDate");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [adms, setAdms] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [reverse, setReverse] = useState(reverseTable);


    useEffect(() => {
        refreshContent(initialFiltersState)
    }, [])

    let stepsRef = {
        "DRAFT" :t('default:DRAFT', "Brouillon"),
        "TO_BE_APPROVED" :t('default:TO_BE_APPROVED',"A approuver" ),
        "TO_BE_SIGNED" :t('default:TO_BE_SIGNED', "Signature"),
        "SIGNED" :t('default:SIGNED', "Signé"),
        "ABANDONED" :t('default:ABANDONED', "Abandon"),
        "EXPIRED" :t('default:EXPIRED', "Expirée"),
        "CANCELLED" :t('default:CANCELLED', "Résiliée")
    }
    const refreshContent = (filters) => {
        setLoading(true);
        Axios.get('/document//newHomeDocs', {  params : {...filters} }    
        ).then(res => {
            let data = res.data?.mandats
            let countItems = res.data?.nbItems
            if(data){
                setAdms(data)
            } 
            setCount(countItems)
        })
        .catch(err => {
            if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else 
                notyf.error('Une erreur s\'est produite !')   
        }) 
        .finally(() => {
            setLoading(false);
        })
    }

    const isSelectedAllPageDocs = () => {
        if(adms && adms.length > 0){
            for (let i = 0; i < adms.length; i++) {
                const docUid = adms[i].uid;
                if(!selectedRows.includes(docUid))
                    return false;
            }
            return true;
        }else 
            return false;
    }

    const unselectAllRowsPage = () => {
        let selectedRowsTemp = [...selectedRows]
        if(adms && adms.length > 0){
            adms.map(row => {
                const selectedRowIndex = selectedRowsTemp.findIndex(u => u === row.uid)
                if(selectedRowIndex >= 0)
                    selectedRowsTemp.splice(selectedRowIndex, 1)
            })
            setSelectedRows(selectedRowsTemp)
        }
        
    }

    const selectAllRowsPage = () => {
        let selectedRowsTemp = [...selectedRows]
        if(adms && adms.length > 0){
            adms.map(row => {
                if(!selectedRowsTemp.includes(row.uid))
                    selectedRowsTemp.push(row.uid)
            })
            setSelectedRows(selectedRowsTemp);
        }
        
    }

    const selectAllRowsClickHandler = () => {
        const docsPageCount = adms.length;
        if(docsPageCount > 0){
            if(isSelectedAllPageDocs()){
                unselectAllRowsPage();
            }else {
                selectAllRowsPage()
            }
        }
    }
    
    const inputChangeHandler = (e) => {
        let key = e.target.name;
        let newFilters = {
            ...filters,
            [key]: e.target.value,
            selectedAllPerPage: [],
            selectedRows: []
        }
        setFilters(newFilters)
        refreshContent(newFilters)
    }
    
    const exportSelectionCsv = () => {
        Axios.post('/document//csvCompressedDocument',{
            selection : selectedRows
        }).then((response) => {
            let csvDataTemp = response.data.content.map(row => {
                return {
                    ...row,
                    status: stepsRef[row.status],
                    creationDate: formatDate(+row.creationDate),
                    end_period: formatDate(+row.end_period),
                    start_period: formatDate(+row.start_period)
                } 
            })
            setCsvData(csvDataTemp);
            csvBtn.current.link.click();
        }).catch(err => notyf.error(t("mandat:generatedErrorMessage")))
    } 
    
    const exportAllCsv = () => {   
        let tmpFilters = {...filters}
        delete tmpFilters.page
        Axios.get('/document//csvCompressedAllDocument',{  params : {...tmpFilters}  }).then((response) => {
            let csvDataTemp = response.data.content.map(row => {
                return {
                    ...row,
                    status: stepsRef[row.status],
                    creationDate: formatDate(+row.creationDate),
                    end_period: formatDate(+row.end_period),
                    start_period: formatDate(+row.start_period)
                } 
            })
            setCsvData(csvDataTemp);
            csvBtn.current.link.click();
        }).catch(err => {
            if(err.response?.data?.error && (err.response?.data?.error === 'Expired token'))
                notyf.error('Une erreur s\'est produite ! Votre session a expiré. Veuillez vous déconnecter et vous reconnecter avant de renouveler l\'opération.')
            else 
                notyf.error(t("mandat:generatedErrorMessage"))
        })
    }

    const handleReset = () => {
        setFilters(initialFiltersState)
        setCurrentPage(1)
        refreshContent(initialFiltersState)
    }

    let tBody = null;
    
    if (loading)
        tBody = <tr><td colSpan="12"><Spinner /></td></tr>
    else if( adms?.length > 0){
        tBody = (
            <TableBody 
                columns={columns} 
                data={adms} 
                formatDate={formatDate}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                stepsRef={stepsRef}/>
            )
    }

    return (
            <div className="home">
                
                <div className="home__container">
                    <NewHomeHeader />

                        <div className="invoiceHeader">
                            <TableActions 
                                filterState={showFilters} 
                                setShowFilters={setShowFilters} 
                                resetData={handleReset}
                                formatDate={formatDate} 
                                exportSelectionCsv={exportSelectionCsv}
                                exportAllCsv={exportAllCsv}
                                csvBtn={csvBtn}
                                csvData={csvData}
                                showCSVIcon={selectedRows.length > 0}
                                showAllCSVIcon={adms?.length > 0}
                                />
                            <TablePagination
                                filters={filters} 
                                filtersChange={setFilters} 
                                applyFilters={refreshContent} 
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage} 
                                totalElement={count} 
                                perPage={25}/>
                        </div>
                    
                    <div className="home__homeBodyContainer" style={{marginTop: "7px"}}>
                        <div className="home__homeBodyMain">
                            <table className="table tableFixHead">
                                <TableHead 
                                    columns={columns} 
                                    refreshContent ={refreshContent} 
                                    reverse={reverse}
                                    sortQuery={sortQuery}
                                    setSortOrder={setSortOrder}
                                    sortOrder={sortOrder}
                                    setReverse={setReverse}
                                    setSortQuery={setSortQuery}
                                    filters={filters}
                                    setFilters={setFilters}
                                    isSelectedAllPageDocs={isSelectedAllPageDocs}
                                    selectAllRowsClickHandler={selectAllRowsClickHandler}
                                     />
                                <tbody>
                                    {showFilters && 
                                        <TableFilters 
                                        show={showFilters} 
                                        setShowFilters={setShowFilters} 
                                        formatDate={formatDate} 
                                        filters={filters} 
                                        inputChangeHandler={inputChangeHandler}
                                        filtersChange={setFilters} 
                                        applyFilters={refreshContent}
                                        role={role}
                                        />
                                    }
                                    {tBody}
                                </tbody>            
                            </table>
                    </div></div>
                    
                
                </div>
            </div>
    )
}
const mapStateToProps = (state) => ({
    isAuth : state.auth.token !== null,
    token: state.auth.token,
    role : state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (NewHome)



