import React, { useState, useEffect } from 'react'
import './TableBody.css' 
import DocDuplicateModal from './DocDuplicateModal/DocDuplicateModal'
import DocSupModal from './DocSupModal/DocSupModal'
import { Link} from 'react-router-dom'  
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import { CTooltip} from '../../../shared/utility';
import Axios from '../../../axios-edipub';
import VisibilitIcon from '@material-ui/icons/Visibility';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { getNotyfObject } from '../../../shared/utility';

function TableBody(props) {
    const {data, formatDate, refreshContent, filters} = props;
    const {t} = useTranslation(); 
    const history = useHistory();
    const notyf = getNotyfObject()
    const userRole = localStorage.getItem('role');
    const [advertisers, setAdvertisers] = useState()
    const [searchQuery, setSearchQuery] = useState(null)
    const [showDupModal, setShowDupModal] = useState(false); 
    const [showSupModal, setShowSupModal] = useState(false);
    const [selectedAdvertiser, setSelectedAdvertiser] = useState() 
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState() 
    const currentDate = new Date();
    const defaultStartDate = new Date((currentDate.getMonth()>=8 ? currentDate.getFullYear() + 1 : currentDate.getFullYear()), 0, 1);
    const defaultEndDate = new Date(defaultStartDate.getFullYear(), 12, 0);
    const [selectedStartDateDupplicate, setSelectedStartDateDupplicate] = useState(defaultStartDate)
    const [selectedEndDateDupplicate, setselectedEndDateDupplicate] = useState(defaultEndDate)   

    useEffect(() => {
        let filter = {
            category: "annonceur",
            status: "ACTIVE",
            searchQuery: searchQuery
        }
        Axios.get('/entity', {
            params: filter
        })
        .then(res => {
            setAdvertisers(res.data.entities)
        })
        .catch(err => notyf.error(t("mandat:generatedErrorMessage")))           
    }, [searchQuery]) 
   
    const deleteMandatHanlder = (template) =>{
        setSelectedTemplate(template) 
        setShowSupModal(!showSupModal)
    }

    const newMandatHanlder = (advertiserUid, advertiserGroupUid, template) =>{
        setSelectedAdvertiser(advertiserGroupUid ? null : advertiserUid)
        setSelectedGroup(advertiserGroupUid)
        setSelectedTemplate(template)
        setShowDupModal(!showDupModal)
    }

    const deleteMandat = () => {
        Axios.delete('/document/'+selectedTemplate.uid+'/drop').
            then(response => {
                if(response.data.Success){
                    setShowSupModal(false)
                    notyf.success(response.data.Success)
                    refreshContent(filters)
                }
                else
                    notyf.error(response.data.Failed)
        })
    }

    const duplicateMandat = () => {
        if( selectedTemplate?.advertiserGroupUid ){
            Axios.post('/document/'+selectedTemplate.uid+'/duplicate', {
                advertiserGroupRef: selectedGroup, 
                startPeriod: selectedStartDateDupplicate.getTime(),
                endPeriod: selectedEndDateDupplicate.getTime()
            }).then(response => {
                if(response.data.Success){
                    notyf.success(response.data.Success)  
                    history.push('/mandat/' + response.data?.uidDocument)
                }
                else 
                    notyf.error(response.data.Failed)
            })
        }else{
            Axios.post('/document/'+selectedTemplate.uid+'/duplicate', {
                advertiserRef: selectedAdvertiser,
                startPeriod: selectedStartDateDupplicate.getTime(),
                endPeriod: selectedEndDateDupplicate.getTime()
            }).then(response => {
                if(response.data.Success){
                    notyf.success(response.data.Success)  
                    history.push('/mandat/' + response.data?.uidDocument)
                }
                else 
                    notyf.error(response.data.Failed)
            })
        } 
        setShowDupModal(false)
    }

    return (
        <>    
            {
                data?.map(row => (
                    <tr key={row.id} className="tableBody__tr" style={{ cursor: userRole!=='RG' && userRole!=='RC' ? 'pointer' : 'default' }} >                       
                        <td><div style={{display:"flex", justifyContent:"flex-end"}}>{ row?.id }</div></td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{ row.mandataire }</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{ row.official_name }</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{ formatDate(+row.start_period) }</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{ formatDate(+row.end_period) }</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{ row.description }</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{ row.medias }</td>
                        <td onClick={() => history.push('/mandat/' + row.uid)}>{ row.products }</td>
                        <td>
                            <div style={{display:"flex"}}> 
                                {   userRole==='MG' ? (
                                        <> 
                                        <CTooltip title={t('template:editTemplate',"Editer le modèle")} > 
                                            <Link to={{pathname: "/mandat/"+row.uid}}  > 
                                                <EditIcon className="tableBody_editIcon" fontSize="small"/>  
                                            </Link>  
                                        </CTooltip> 
                                        <CTooltip title={t('default:delete', "Supprimer")}> 
                                            <ClearIcon 
                                                className="tableBody_clearIcon" fontSize="small"
                                                onClick={() => deleteMandatHanlder(row)} />     
                                        </CTooltip> 
                                        </> 
                                    ):( <CTooltip title={t('template:seeTemplate',"Voir le modèle")} > 
                                            <Link to={{ pathname: "/mandat/"+row.uid}} > 
                                                <VisibilitIcon className="tableBody_visibilitIcon" fontSize="small"/>  
                                            </Link>   
                                        </CTooltip> 
                                    )
                                }    
                                <CTooltip title={ t('template:createAdm',"Créer un mandat") }> 
                                    <div>
                                        <AddIcon 
                                            className="tableBody_addIcon" fontSize="small"
                                            onClick={() => newMandatHanlder(row.advertiserUid, row.advertiserGroupUid, row)}   
                                        />     
                                    </div>
                                </CTooltip>
                                <>
                               
                                </> 
                            </div>
                         </td>
                        
                    </tr>
                    
                ))
                
            }
            { 
                showSupModal ? <DocSupModal
                    show={showSupModal}
                    docReference={selectedTemplate.id}
                    handleClose={() => setShowSupModal(false)}
                    deleteDocument={deleteMandat} /> : null
            } 
            {
                showDupModal ? <DocDuplicateModal
                    selectedAdvertiser={selectedAdvertiser}
                    setSelectedAdvertiser={setSelectedAdvertiser}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup} 
                    advertiserType={selectedTemplate?.advertiserGroupUid ? "group": "advertiser"} 
                    setSearchQuery={setSearchQuery}
                    advertisers={advertisers}
                    show={showDupModal} 
                    selectedStartDate={selectedStartDateDupplicate}
                    selectedEndDate={selectedEndDateDupplicate}
                    setSelectedStartDate={setSelectedStartDateDupplicate}
                    setSelectedEndDate={setselectedEndDateDupplicate}
                    handleClose={() => setShowDupModal(false)}
                    duplicateDocument={duplicateMandat} /> : null                            
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    isAuth : state.auth.token !== null,
    token: state.auth.token,
    role : state.auth.role
})
const mapDispatchToProps = dispatch => ({
})
export default connect(mapStateToProps, mapDispatchToProps) (TableBody)
