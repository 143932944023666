import React, { useCallback, useEffect, useState } from 'react'
import './DocumentEvent.css' 
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {formatDate, getTimeDate} from '../../../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import Axios from "../../../../../../../axios-edipub";  
import { Notyf } from "notyf";   


function DocumentEvent({isCreator, eventData, isLast}) {
    const [showContent, setShowContent] = useState(false)
    const {t} = useTranslation();
    const notyf = new Notyf();

    useEffect(() => {
        if(isLast)
            setShowContent(true)
        else
            setShowContent(false)
    }, [isLast]) 

    const getDocumentPdfFile = (uid) => { 
        Axios.get('/document/'+uid+'/chatDocumentPdfFile', { 
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            if(response.status === 200){
                let blob = new Blob([response.data], {type:'application/pdf'})
                            let filename =""
                            const disposition = response.request.getResponseHeader('Content-Disposition')
                            console.log(disposition);
                            if (disposition && disposition.indexOf('attachment') !== -1) {
                                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                                var matches = filenameRegex.exec(disposition);
                                if (matches!== null && matches[1]) { 
                                filename = matches[1].replace(/['"]/g, '');
                                }
                            }
                            const downloadUrl = URL.createObjectURL(blob)
                            let a = document.createElement("a"); 
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
            }else {  
                notyf.error(t("mandat:docNotFound"));  
            } 
        })
        .catch(err => {
            console.log("getDocumentPdfFile erreur " , err ) 
            notyf.error(t("default:errorMessage")); 
        }) 
    }

    return (
        <div className="timelineCard__container">
            <div className="timelineCard__hideicon" onClick={() => setShowContent(!showContent)}>
                {
                    showContent ? 
                        <ExpandMoreIcon />
                    :
                        <ChevronRightIcon />
                }
            </div>
            <div className="timelineCard__sideContainer">
                <div className="timelineCard__header"> 
                    <InsertDriveFileIcon style={{backgroundColor: "rgb(141, 151, 168)", color: "#FFFFFF", borderRadius: "5px", padding: "2px"}}  />
                    <div className="timelineCard__header_label">{t('timeline:userChatDocument_title', "Document partagé")} </div>  
                    <div className="timelineCard__header_time">
                        <span className="timelineCard__header_hour"> {getTimeDate(+eventData?.creationDate)} </span> 
                        <span className="timelineCard__header_date"> {formatDate(+eventData?.creationDate)} </span> 
                    </div>
                </div>
                <div className="timelineCard__content" style={{borderLeft: "2px solid rgb(141, 151, 168)",  display: showContent ? "block" : "none"}}>

                    <div className="timelineCard__content__info">
                        {
                            isCreator ? 
                                t('timeline:userChatDocument_creator', "Vous avez partagé un document")  
                            : 
                                eventData?.data?.first_name + ' ' + eventData?.data?.last_name + ' ' + t('timeline:userChatDocument_other', "a partagé un document")
                        } 
                    </div> 

                    <div className="timelineCard__content__body">
                        <div className="messageEvent__content__header">
                            <div className="messageEvent__header__name">
                                <div className="messageEvent__header__nameLabel">
                                    {t("default:name", "Nom")} 
                                </div>
                                <div className="messageEvent__header__nameValue">{eventData?.data?.first_name + ' ' + eventData?.data?.last_name}</div>
                            </div>
                            <div className="messageEvent__header__company">
                                <div className="messageEvent__header__companyLabel">
                                    {t("default:society", "Société")} 
                                </div>
                                <div className="messageEvent__header__companyValue">{eventData?.data?.entities}</div>
                            </div>
                        </div>
                        <div className="messageEvent__content__message">
                            <div className="messageEvent__message__label">
                                {t("default:file", "Fichier")}
                            </div>
                            <div className="messageEvent__message__value"> 
                                <div onClick={(e) => getDocumentPdfFile(eventData.uid)} > 
                                    <span style={{cursor:"pointer",color:"blue", textDecoration: "underline"}}> 
                                        {eventData?.message}    
                                    </span>
                                </div>   
                            </div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
    )
}

export default DocumentEvent 
