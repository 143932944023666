import React, {useEffect, useState} from 'react'
import './App.css';
import {Redirect, Switch, Route, useLocation} from 'react-router-dom';
import * as actions from './store/actions/index';
import { connect } from 'react-redux';
import Home from './containers/Home/Home'
import NewHome from './containers/NewHome/NewHome'
import Emails from './containers/Emails/Emails'
import Header from './components/Header/Header';
import Mandat from './containers/Mandat/Mandat';
import './components/Utilisateur/Utilisateur.css'; //on garde sinon pete le style de l'appli
import HomeRegie from './containers/HomeRegie/HomeRegie';
import MasterData from './containers/MasterData/MasterData';
import FAQ from './containers/FAQ/FAQ';
import KPI from './containers/KPI/KPI';
import Template from './containers/Template/Template';
import ManualEntity from './containers/ManualEntity/ManualEntity';
import Auth from './containers/Auth/Auth';

import ResetPassword from './components/Signin/ResetPassword/ResetPassword';
import SignMandat from './containers/TokenAccess/SignMandat/SignMandat';
import CreateEntity from './containers/TokenAccess/CreateEntity/CreateEntity';
import Profile from './containers/Profile/Profile';
import ForgotPassword from './components/Signin/ForgottenPassword/ForgotPassword';
import SsoAccess from './containers/TokenAccess/SsoAccess/SsoAccess';

function App(props) {

  const {onTryAutoSignup, userRole, authRedirectPath} = props;
  const [accessToken, setAccessToken] = useState(false);
  const accesTokenList = ["signMandat", "forgotPassword", "account", "createEntity","ssoAccess"];  
  let location = useLocation();
const [filter, setFilter] = useState();
const [all, setAll] = useState(false);
//const [userselected, setUserselected] = useState();
const [userselected, setUserselected] = useState({new: [], del: []});
const [etatpage, setEtatpage] = useState([]);
  useEffect(() => {
    setAccessToken(accesTokenList.includes(location.pathname.split("/")[1])); // check if access token or not 
    onTryAutoSignup()
  }, [onTryAutoSignup]);

  let routes 

  if (props.isAuthenticated ) {
    switch (userRole) {
      case "RG":
        routes = (
          <>
            <Header />
            <Switch>
              <Route path="/" exact component={HomeRegie} />
              <Route path="/masterData" exact component={MasterData} />
              <Route path="/mandat/:uid" component={Mandat} />   
              <Route path="/profile" component={Profile} />
              <Route path="/newHome" exact component={NewHome} />
              <Route path="/faq" exact component={FAQ} />
              <Redirect to="/" />
            </Switch>
          </>
        )
        break;
        case "AC":
        routes = (
          <>
            <Header />
              <Switch>
                <Route path="/regie" exact component={HomeRegie} />
                <Route path="/mandat/:uid" component={Mandat} />
                <Route path="/profile" component={Profile} />
                <Route path="/newHome" exact component={NewHome} />
                <Route path="/faq" exact component={FAQ} />
                <Route path="/" exact component={Home} />
                <Redirect to="/" />
              </Switch>
          </>
        )
        break;
      case "RC":
        routes = (
          <>
            <Header />
            <Switch>
              <Route path="/" exact component={HomeRegie} />
              <Route path="/mandat/:uid" component={Mandat} />
              <Route path="/profile" component={Profile} />
              <Route path="/newHome" exact component={NewHome} />
              <Route path="/faq" exact component={FAQ} />
              <Redirect to="/" />
            </Switch>
          </>
        )
        break;
      case "MG":
      case "MR":
      case "MC":
        routes = (
          <>
            <Header />
            <Switch>
              <Route path="/regie" exact component={HomeRegie} />
              <Route path="/masterData" exact component={MasterData} />
              <Route path="/mandat/:uid" component={Mandat} />
              <Route path="/profile" component={Profile} />
              <Route path="/newHome" exact component={NewHome} />
              <Route path="/faq" exact component={FAQ} />  
              <Route path="/kpi" exact component={KPI} />
              {"MG, MR".includes(userRole) &&  
                <Route path="/template" exact component={Template} /> 
              } 
              {"MG, MR".includes(userRole) &&   
                <Route path="/manualentity" exact component={ManualEntity} />
              } 
              <Route path="/" exact component={Home} />
              <Redirect to="/" />
            </Switch>
          </>
        )
        break;
      case "AE":
        routes = (
          <>
            <Header />
            <Switch>
              <Route path="/regie" exact component={HomeRegie} />
              <Route path="/masterData" exact component={MasterData} />
              <Route path="/mandat/:uid" component={Mandat} />
              <Route path="/profile" component={Profile} />
              <Route path="/newHome" exact component={NewHome} />
              <Route path="/emails" exact component={Emails} />
              <Route path="/faq" exact component={FAQ} />
              <Route path="/kpi" exact component={KPI} />
              <Route path="/template" exact component={Template} />
              <Route path="/manualentity" exact component={ManualEntity} />
              <Route path="/" exact component={Home} />
              <Redirect to="/" />
            </Switch>
          </>
        )
        break;
      default:
        routes = (
          <>
            <Header />
            <Switch>
              <Route path="/regie" exact component={HomeRegie} />
              <Route path="/masterData" exact component={MasterData} />
              <Route path="/mandat/:uid" component={Mandat} />
              <Route path="/profile" component={Profile} />
              <Route path="/newHome" exact component={NewHome} />
              <Route path="/faq" exact component={FAQ} />              
              <Route path="/" exact component={Home} />
              <Redirect to="/" />
            </Switch>
          </>
        )
    }      
  } else {
    routes = (
      <Switch>
        <Route path="/ssoAccess/:token" exact component={SsoAccess}/>
        <Route path="/forgotPassword" exact component={ForgotPassword}/>
        <Route path="/signMandat/:token" exact component={SignMandat}/>
        <Route path="/account/:token" exact component={ResetPassword}/>  
        <Route path="/createEntity/:token" exact component={CreateEntity} />  
        <Route path="/" exact component={Auth} />
      </Switch>
    )
  }
  return (
    <div className="App">
      {
        authRedirectPath && accessToken !== true ? (
          <Redirect to="/" />
        )
         : null
      }
     
        {routes}
      
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    userRole: state.auth.role,
    authRedirectPath: state.auth.authRedirectPath
  }
}

const mapDispatchProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default connect(mapStateToProps, mapDispatchProps)(App);
