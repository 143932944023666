import React, { useEffect, useState } from 'react'
import './TimeLine.css';
import CreationEvent from './TimelineCards/CreationEvent/CreationEvent';
import DocumentEvent from './TimelineCards/DocumentEvent/DocumentEvent';
import Axios from '../../../../../axios-edipub'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import Spinner from '../../../../UI/Spinner/Spinner';
import { Notyf } from 'notyf';
import SharedEvent from './TimelineCards/SharedEvent/SharedEvent';
import MessageEvent from './TimelineCards/MessageEvent/MessageEvent';
import ApprovedEvent from './TimelineCards/ApprovedEvent/ApprovedEvent';
import ChangedEvent from './TimelineCards/ChangedEvent/ChangedEvent';
import ValidatedEvent from './TimelineCards/ValidatedEvent/ValidatedEvent';
import UserSignedEvent from './TimelineCards/UserSignedEvent/UserSignedEvent';
import SignatureEvent from './TimelineCards/SignatureEvent/SignatureEvent';
import UserSignedResignEvent from './TimelineCards/UserSignedResignEvent/UserSignedResignEvent';
import UserSignedOfflineBeganEvent from './TimelineCards/UserSignedOfflineBeganEvent/UserSignedOfflineBeganEvent';
import UserSignedOfflineCancelledEvent from './TimelineCards/UserSignedOfflineCancelledEvent/UserSignedOfflineCancelledEvent';
import UserSignedOfflineImportedEvent from './TimelineCards/UserSignedOfflineImportedEvent/UserSignedOfflineImportedEvent';
import CreatorChangedEvent from './TimelineCards/CreatorChangedEvent/CreatorChangedEvent';
import { getNotyfObject } from '../../../../../shared/utility';
import { useTranslation } from 'react-i18next';
import DocumentCancelledEvent from './TimelineCards/DocumentCancelledEvent/DocumentCancelledEvent';
import UserDeletedEvent from './TimelineCards/UserDeletedEvent/UserDeletedEvent';
import UserDeletedAndReplaced from './TimelineCards/UserDeletedAndReplaced/UserDeletedAndReplaced';
import RecipientsChangedEvent from './TimelineCards/RecipientsChangedEvent/RecipientsChangedEvent';
import SupportDeletedEvent from './TimelineCards/SupportDeletedEvent/SupportDeletedEvent';
import SignataireReminder from './TimelineCards/SignataireReminder/SignataireReminder'
import ContributeurReminder from './TimelineCards/ContributeurReminder/ContributeurReminder'

import SignatoriesChangedEvent from './TimelineCards/SignatoriesChangedEvent/SignatoriesChangedEvent'
import StatusRevertEvent from './TimelineCards/StatusRevertEvent/StatusRevertEvent'
import GrantDocumentRequestEvent from './TimelineCards/GrantDocumentRequestEvent/GrantDocumentRequestEvent'
import UserSignedOfflineReplacedEvent from './TimelineCards/UserSignedOfflineReplacedEvent/UserSignedOfflineReplacedEvent';


function TimeLine({ loading, timelineData, error, isCreator, getMandatTimelineEvents }) {
    let { uid } = useParams();
    const notyf = getNotyfObject();
    const { t } = useTranslation();

    useEffect(() => {
        getMandatTimelineEvents(uid)
    }, [uid])

    let timelineJsx = null;

    if (error) {
        notyf.error(t("mandat:generatedErrorMessage"));
    }

    if (loading)
        timelineJsx = (<Spinner />)
    else if (timelineData && timelineData?.length > 0) {
        timelineJsx = timelineData?.map((timelineEvent, index) => {
            var eventJsx = null;
            let isLast = index === 0
            switch (timelineEvent?.type) {
                case "STEP_CREATION":
                    eventJsx = (<CreationEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "STEP_SHARED":
                    eventJsx = (<SharedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_CHAT_EVENT":
                case "POST_SIGN_CHAT":
                    eventJsx = (<MessageEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isLast={isLast}
                        eventType={timelineEvent?.type}
                    />)
                    break;
                case "USER_CHAT_DOCUMENT":
                case "POST_SIGN_DOCUMENT":
                    eventJsx = (<DocumentEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_VALIDATION":
                    eventJsx = (<ApprovedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        uidDocument={uid}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "STEP_CHANGED":
                    eventJsx = (<ChangedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "STEP_VALIDATION":
                    eventJsx = (<ValidatedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_SIGNATURE":
                    eventJsx = (<UserSignedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_SIGNATURE_RESIGN":
                    eventJsx = (<UserSignedResignEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_SIGNATURE_OFFLINE_BEGAN":
                    eventJsx = (<UserSignedOfflineBeganEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_SIGNATURE_OFFLINE_CANCELLED":
                    eventJsx = (<UserSignedOfflineCancelledEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_SIGNATURE_OFFLINE_IMPORTED":
                    eventJsx = (<UserSignedOfflineImportedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "STEP_SIGNATURE":
                    eventJsx = (<SignatureEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "CREATOR_CHANGED":

                    eventJsx = (<CreatorChangedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;

                case "CREATOR_DELETED_CHANGED":
                    eventJsx = (<UserDeletedAndReplaced
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                        userActionKey={"creator"}
                    />)
                    break;
                case "VALIDATOR_DELETED_CHANGED":
                    eventJsx = (<UserDeletedAndReplaced
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                        userActionKey={"validator"}
                    />)
                    break;
                case "SIGNATORY_DELETED_CHANGED":
                    eventJsx = (<UserDeletedAndReplaced
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                        userActionKey={"signatory"}
                    />)
                    break;
                case "REPRESENTATIVE_DELETED_CHANGED":
                    eventJsx = (<UserDeletedAndReplaced
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                        userActionKey={"representative"}
                    />)
                    break;
                case "RECIPIENT_DELETED_CHANGED":
                    eventJsx = (<UserDeletedAndReplaced
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                        userActionKey={"recipient"}
                    />)
                    break;


                case "DOCUMENT_CANCELLED":
                    eventJsx = (<DocumentCancelledEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_DELETED":
                    eventJsx = (<UserDeletedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "SUPPORT_DELETED":
                    eventJsx = (<SupportDeletedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "RECIPIENTS_CHANGED":
                    eventJsx = (<RecipientsChangedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_SIGNATURE_REMINDER":
                    eventJsx = (<SignataireReminder
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;

                case "SIGNATORIES_CHANGED":
                    eventJsx = (<SignatoriesChangedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_CONTRIBUTOR_REMINDER":
                    eventJsx = (<ContributeurReminder
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "STATUS_REVERT":
                    eventJsx = (<StatusRevertEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "GRANT_DOCUMENT_REQUEST":
                    eventJsx = (<GrantDocumentRequestEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;
                case "USER_SIGNATURE_OFFLINE_REPLACED":
                    eventJsx = (<UserSignedOfflineReplacedEvent
                        key={timelineEvent.id}
                        eventData={timelineEvent}
                        isCreator={isCreator}
                        isLast={isLast}
                    />)
                    break;

                default:
                    break;
            }
            return eventJsx
        })
    }

    return (
        <div className="p-2">
            {timelineJsx}
        </div>
    )
}
const mapStateToProps = (state) => ({
    loading: state.mandat.loadingTimeline,
    timelineData: state.mandat.timelineData,
    error: state.mandat.errorTimeline,
    isCreator: state.auth?.userId === state.mandat?.admData?.creatorUid,

})
const mapDispatchToProps = dispatch => ({
    getMandatTimelineEvents: (mandatUid) => dispatch(actions.getMandatTimelineEvents(mandatUid)),
    updateMandatStatus: (mandatUid, status) => dispatch(actions.updateMandatStatus(mandatUid, status))
})
export default connect(mapStateToProps, mapDispatchToProps)(TimeLine)